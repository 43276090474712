.container,
.main,
.section {
    display: flex;
    justify-content: flex-start;
    height: 100%;
}

.container {
    flex-direction: row;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-32);
    max-width: 1620px;
    width: calc(100% - 200px);
    padding: var(--padding-5xl) 0 var(--padding-5xl);
}

.main,
.section {
    align-self: stretch;
    flex-direction: column;
    align-items: center;
    color: var(--gray-500);
}

.main {
    background-color: var(--gray-25);
    gap: var(--gap-32);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
    font-family: var(--text-md-regular);
}

.section {
    padding-top: var(--header-height);
}

@media screen and (max-width: 600px) {
    .container {
        flex-direction: column;
        width: 100%;
        gap: 10px;
        height: unset;
    }

}
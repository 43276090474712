.text,
.text1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.text1 {
    line-height: 18px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--error-700);
    width: 24px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--base-white);
}

.badge1,
.tabButtonBase {
    justify-content: flex-start;
}

.badge1 {
    border-radius: var(--br-base);
    background-color: var(--error-700);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--base-white);
}

.badge1,
.badge2,
.tabButtonBase3 {
    flex-direction: row;
    align-items: center;
}

.badge2 {
    border-radius: var(--br-base);
    background-color: var(--error-700);
    display: flex;
    padding: var(--padding-11xs) var(--padding-5xs);
    justify-content: flex-start;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--base-white);
}

.tabButtonBase.selected {
    align-self: stretch;
    border-radius: var(--br-7xs);
    background-color: var(--gray-100);
    overflow: hidden;
    padding: var(--padding-5xs) var(--padding-xs);
    color: var(--gray-700);
}

.tabButtonBase3,
.tabButtonBase4,
.verticalTabs {
    display: flex;
    justify-content: flex-start;
}

.tabButtonBase {
    align-self: stretch;
    border-radius: var(--br-7xs);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    gap: var(--gap-5xs);
    display: flex;
    cursor: pointer;
}

.tabButtonBase:hover {}

.verticalTabs {
    min-width: 150px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-500);
    font-family: var(--text-md-regular);
}

.dropdown {
    position: relative;
    margin-right: 10px;
    display: none;
}

.dropdownButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.dropdownButtonText {
    margin-right: 5px;
}

.dropdownIcon {
    margin-left: 5px;
}

.dropdownContent {
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: none;
}

.dropdownMenuItem {
    padding: 10px;
    cursor: pointer;
}

.dropdownMenuItem:hover {
    background-color: #ddd;
}

.dropdownContent.show {
    display: block;
}

@media screen and (max-width: 600px) {
    .dropdown {
        display: flex;
        width: 100%;
    }

    .verticalTabs {
        display: none;
    }

    .dropdownSelect {
        width: 100%;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 6px;
        border: 2px solid #D0D5DD;
        color: var(--primary-color);
        font-size: 18px;
    }

    .dropdownSelect:focus-visible {
        border: 2px solid var(--secondary-color);
    }

}
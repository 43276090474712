.puttingImageIcon {
  width: 980px;
  height: 652px;
  object-fit: cover;
}

.chooseAndCreateInner,
.puttingImageWrapper {
  border-radius: var(--br-5xs);
  width: 580px;
  height: 456px;
  overflow: hidden;
}

.puttingImageWrapper {
  top: 0;
  left: 0;
}

.chooseAndCreateInner {
  position: relative;
  flex-shrink: 0;
}

.basketIcon {
  position: relative;
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}

.text {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.supportingText {
  position: relative;
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  color: var(--gray-600);
  display: inline-block;
  width: 399px;
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text1 {
  position: relative;
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--text-md-regular);
  color: var(--base-white);
  text-align: left;
}

.arrowNarrowRightIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.button {
  cursor: pointer;
  border: 1px solid var(--error-700);
  padding: var(--padding-3xs) var(--padding-lg);
  background-color: var(--error-700);
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.button:hover,
.button1:hover {
  background-color: #b42218de;
}

.chooseAndCreate,
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-2xl);
}

.chooseAndCreate {
  align-self: stretch;
  background-color: var(--base-white);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-76xl) var(--padding-5xl) var(--padding-61xl) var(--padding-178xl);
  align-items: center;
  gap: var(--gap-57xl);
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--gray-900);
  font-family: var(--text-md-regular);
  justify-content: center;
}

.puttingImageWrapper img {
  width: 100%;
  border-radius: var(--br-5xs);
}

@media screen and (max-width: 600px) {
  .chooseAndCreate {
    padding: 20px;
  }

  .chooseAndCreateInner,
  .puttingImageWrapper {
    width: 100%;
    height: auto;
  }

  .content {
    order: -1;
  }

  .supportingText {
    width: 100%;
  }

  /* background-position: left -50px top 50px; */
}
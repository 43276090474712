.label,
.text {
  position: relative;
}

.label {
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.text {
  align-self: stretch;
  flex: 1;
  line-height: 24px;
}

.input,
.inputWithLabel {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.input {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-sm);
  font-size: var(--text-md-semibold-size);
  color: var(--gray-500);
  gap: 5px;
}

.inputWithLabel {
  flex-direction: column;
  gap: var(--gap-6);
}

.hintText {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--gray-600);
  display: none;
}

.textareaInputField {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6);
}

.faceSmileIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.button,
.icons {
  display: flex;
  flex-direction: row;
}

.button {
  border-radius: var(--br-5xs);
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}

.icons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  display: none;
}

.text1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.actions,
.button2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.button2 {
  border-radius: var(--br-5xs);
  background-color: var(--primary-color);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-800);
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  cursor: pointer;
}

.actions {
  color: var(--base-white);
  align-self: flex-end;
}

.messageInputWrap {
  align-self: stretch;
  position: relative;
}

.messageInput {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-32xl) var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-700);
  font-family: var(--text-md-semibold);
}

.messageInputField {
  width: 100%;
  border: none;
  resize: none;
  overflow: hidden;
  min-height: 40px;
}

.messageInputField:focus-visible {
  border: none;
  outline: none;
}

@media screen and (max-width: 600px) {
  .messageInput {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0px 20px 0px 20px;
    background-color: white;
    align-items: center;
  }

}
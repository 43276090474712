:root {
  --search-height: 50px;
  --search-width: 500px;
}

.heading {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 74px;
  font-weight: 600;
}

.headingAndSubheading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.supportingText {
  position: relative;
  font-size: var(--text-xl-semibold-size);
  line-height: 30px;
  font-weight: 600;
  color: var(--gray-200);
  display: inline-block;
  width: 645px;
}

.headingAndSupportingText {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-24);
}

.label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.searchLgIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.text {
  position: relative;
  line-height: 24px;
  width: 100%;
  border: none;
}

.text:focus-visible {
  outline: none;
}

.searchInner,
.input,
.inputWithLabel {
  display: flex;
  justify-content: flex-start;
}

.searchInner {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.input,
.inputWithLabel {
  align-self: stretch;
}

.input {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: center;
  font-size: var(--text-md-regular-size);
  color: var(--gray-500);
  height: 100%;
}

.inputWithLabel {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6);
  height: var(--search-height);
  width: var(--search-width);
}

.hintText {
  position: relative;
  line-height: 20px;
  color: var(--gray-600);
  display: none;
  width: 320px;
}

.inputDropdown {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.search {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button {
  border-radius: var(--br-5xs);
  background-color: var(--primary-color);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--primary-color);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
  color: var(--base-white);
  cursor: pointer;
}

.button:hover {
  background-color: #274368e3;
}

.container,
.content,
.content1 {
  display: flex;
  justify-content: flex-start;
}

.content1 {
  width: 600px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--text-sm-semibold-size);
  color: var(--gray-700);
  position: relative;
}

.container,
.content {
  flex-direction: column;
  align-items: center;
}

.content {
  align-self: stretch;
  gap: var(--gap-29xl);
}

.container {
  top: 96px;
  left: 80px;
  padding: 0 var(--padding-32xl);
  box-sizing: border-box;
}

.sectionChild {
  position: absolute;
  top: -33.53px;
  left: 0;
  height: 574.53px;
  object-fit: cover;
}

.section {
  align-self: stretch;
  position: relative;
  /* max-height: 574px;
  min-height: 400px; */
  flex-shrink: 0;
  z-index: 0;
  text-align: center;
  font-size: var(--font-size-53xl);
  color: var(--base-white);
  font-family: var(--text-md-regular);
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  display: grid;
}


.dropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
  position: absolute;
  z-index: 1000;
  top: var(--search-height);
  width: 100%;
  max-width: 500px;
}

.dropdownRow {
  border-color: #ccc;
  border-image: none;
  border-style: solid;
  border-width: 0 1px;
  display: block;
  font-weight: 400;
  padding: 6px 8px;
  width: 100%;
  cursor: pointer;
  border-top: 1px solid rgb(240, 240, 240);
  border-bottom: 1px solid rgb(240, 240, 240);
  font-size: 1.2rem;
  line-height: 1;
}

.dropdownRow:hover {
  background-color: var(--secondary-color);
  color: white;
}


.searchInner input {
  width: 100%;
  border: none;
  font-size: 18px;
}

.searchInner input:focus-visible {
  border: none;
  outline: none;
}


@media screen and (max-width: 600px) {

  .content1 {
    width: 100%;
    flex-direction: column;
  }

  .section {
    display: flex;
    font-size: 36px;
  }

  .heading {
    line-height: 50px;
    word-wrap: normal;
    text-align: center;
    align-items: center;
  }

  .supportingText {
    max-width: 100%;
  }

  .container {
    max-width: 100%;
  }

  .inputWithLabel {
    width: 100%;
  }

  .headingAndSupportingText {
    gap: var(--gap-36);
  }

  .content1 {
    gap: 20px;
  }

  .button {
    width: 100%;
  }
}
.heading {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button,
.footerLink,
.footerLink6 {
  flex-direction: row;
  align-items: center;
}

.button {
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: var(--gap-5xs);
}

.footerLink,
.footerLink6 {
  justify-content: flex-start;
}

.footerLink {
  display: flex;
}

.footerLink6 {
  display: none;
}

.footerLinks,
.footerLinksColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footerLinks {
  align-self: stretch;
  gap: var(--gap-xs);
  font-size: var(--text-md-regular-size);
  color: var(--gray-200);
}

.footerLinksColumn {
  flex: 1;
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--text-sm-semibold-size);
  color: var(--gray-300);
  font-family: var(--text-md-regular);
}

@media screen and (max-width: 600px) {

  .container {
    width: 100%;
  }

  .content {
    flex-direction: column;
    gap: 24px;
  }

  .heading {
    font-size: 18px;
  }
}
.modalOverlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    display: block;
    background: white;
    border-radius: 4px;
    max-height: 90vh;
}

@media screen and (max-width: 600px) {
    .modalContent {
        max-width: 90%;
    }
}
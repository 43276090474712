.avatarOnlineIndicator,
.dot {
  border-radius: var(--br-8xs);
  overflow: hidden;
}

.dot {
  position: relative;
  background-color: var(--error-700);
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  display: none;
}

.avatarOnlineIndicator {
  position: absolute;
  right: -1.5px;
  bottom: -1.5px;
  background-color: var(--success-500);
  border: 1.5px solid var(--base-white);
  box-sizing: border-box;
  width: 13px;
  height: 13px;
}

.avatar {
  position: relative;
  border-radius: var(--br-181xl);
  height: 50px;
  width: 50px;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: var(--br-181xl);
  object-fit: cover;
}

.supportingText,
.text {
  position: relative;
  line-height: 20px;
}

.text {
  font-weight: 600;
}

.supportingText {
  color: var(--gray-600);
}

.avatarAndDot,
.avatarLabelGroup,
.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.avatarAndDot,
.avatarLabelGroup {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-xs);
}

.avatarAndDot {
  flex: 1;
}

.text1 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.avatarAndTime,
.badge {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.badge {
  border-radius: var(--br-base);
  background-color: var(--error-50);
  border: 1px solid var(--error-700);
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  mix-blend-mode: normal;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--error-700);
}

.avatarAndTime {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-base);
  justify-content: space-between;
}

.messagePreview {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.message,
.messagePreviewWrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gray-600);
}

.message {
  border-bottom: 1px solid var(--gray-200);
  padding: var(--padding-base);
  gap: var(--gap-base);
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-700);
  font-family: var(--text-md-semibold);
  width: 100%;
}

.wrapTimeUnread {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.unread {
  color: white;
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.groupTitle {
  font-size: 16px;
  font-weight: 600;
}

.avatarGroupChat {
  max-width: 45px;
}

.avatarGroupChat img {
  width: 100%;
}



@media screen and (max-width: 600px) {

  .message {
    width: 100%;
  }

  .groupTitle {
    font-size: 18px;
  }
}
/* @import url('..https://fonts.googleapis.com/css2?family=Forum&display=swap');
@import url('..https://fonts.googleapis.com/css2?family=Forum&family=Nunito+Sans:wght@400;800&display=swap'); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;

    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */

    cursor: pointer;
}

html {
    scroll-behavior: smooth;
}

.becomeAGolfer .container {
    max-width: 1270px;
    margin: auto;
    height: 100%;
    padding: 0 20px;
    position: relative;
}

/* hero section*/
.becomeAGolfer .header {
    width: 100%;
    height: 550px;
    background-image: url('../Assets/Images/moneyball.jpg');
    background-position: 0% 25%;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 140px;
    box-shadow: inset 0px -19px 39px rgba(0, 0, 0, 0.7);
}

.becomeAGolfer .header h1 {
    font-size: 3em;
    font-weight: 400;
    padding-bottom: 15px;
}

.becomeAGolfer .wrap-headers {
    animation: fadeIn 1.5s;
    text-shadow: 0 1px 0 black;
}

.becomeAGolfer .headerBtns {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 67px;
    justify-content: center;
}

.becomeAGolfer .headerBtn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 350px;
    background: var(--primary-color);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: white;
    padding: 18px 40px;
    cursor: pointer;
    animation: fadeInDelay 2s;
}

.becomeAGolfer .headerBtn1:hover {
    transition: 1s;
    gap: 40px;
}

.becomeAGolfer .HowItWorks .container {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.becomeAGolfer .HowItWorks h1 {

    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 2.7rem;
    text-align: center;
}

.becomeAGolfer .HowItWorksInnerStatistic {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    margin-bottom: 20px;
}

.becomeAGolfer .HowItWorksInnerStatistic .box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 9px 20px;
    gap: 8px;
    width: 100%;
    min-height: 120px;
    background: #F3F3F3;
    border-radius: 8px;
}

.becomeAGolfer .HowItWorksInnerStatistic .box p {
    font-size: 18px;
}

.becomeAGolfer .HowItWorksInnerStatistic .box h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
}

.becomeAGolfer .HowItWorksInner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;

}

.becomeAGolfer .HowItWorksInner .box {
    width: 100%;
    min-height: 230px;
    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 1%;
    gap: 20px;
}

.becomeAGolfer .HowItWorksInner .box p {
    font-size: 1.2em;
}


/* Testimonials */

.becomeAGolfer .clientStoriesSection {
    width: 100%;
    height: fit-content;
    animation: paralex 1s;
    text-align: center;
}

.becomeAGolfer .wrap-client-stories {
    width: 1240px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding-top: 30px !important;
    padding-bottom: 40px !important;
    display: table;
}

.becomeAGolfer .wrap-client-stories h2 {
    text-align: center;
    text-transform: initial;
    padding-bottom: 30px;
    color: #222325;
    font-size: 32px;
    font-weight: 400;
}

.becomeAGolfer .wrap-client-stories ul {
    width: 100%;
    list-style: none;
    list-style-image: none;
    display: block;
}

.becomeAGolfer .background-client-stories div.buyer1,
.becomeAGolfer .background-client-stories div.buyer2 {
    padding-left: 25%;
}

.becomeAGolfer .background-client-stories div.buyer3,
.becomeAGolfer .background-client-stories div.buyer4 {
    padding-right: 25%;
}

.becomeAGolfer .background-client-stories div.buyer1:before,
.becomeAGolfer .background-client-stories div.buyer2:before {
    border-right: 25px #f7f7f7 solid;
    -webkit-transform: translate3d(-24px, -50%, 0);
    transform: translate3d(-24px, -50%, 0);
}

.becomeAGolfer .background-client-stories div.buyer3:before,
.becomeAGolfer .background-client-stories div.buyer4:before {
    border-left: 25px #f7f7f7 solid;
    -webkit-transform: translate3d(-1px, -50%, 0);
    transform: translate3d(-1px, -50%, 0);
}

.becomeAGolfer .background-client-stories div {
    background-color: #f7f7f7 !important;
    width: 50%;
    height: 289px;
    position: relative;
    float: left;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: 0 0;
}

.becomeAGolfer .background-client-stories div:before {
    content: '';
    display: inline-block;
    border-top: 19px transparent solid;
    border-bottom: 19px transparent solid;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
}

.becomeAGolfer .background-client-stories div blockquote {
    display: block;
    position: absolute;
    top: 50%;
    z-index: 2;
    padding: 0 25px;
    width: 50%;
    box-sizing: border-box;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.becomeAGolfer .background-client-stories div blockquote cite {
    font-size: 18px;
}


.becomeAGolfer .background-client-stories div.buyer1:after {
    background-image: url("../Assets/Images/story1.png");
    background-size: cover;
}

.becomeAGolfer .background-client-stories div.buyer2:after {
    background-image: url("../Assets/Images/story2.jpg");
    background-size: cover;
}

.becomeAGolfer .background-client-stories div.buyer3:after {
    background-image: url("../Assets/Images/story3.jpg");
    background-size: cover;
}

.becomeAGolfer .background-client-stories div.buyer4:after {
    background-image: url("../Assets/Images/story4.jpg");
    background-size: cover;
}

.becomeAGolfer .background-client-stories div:after {
    content: '';
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: top center;
    float: right;
    width: 50%;
    height: 289px;
    position: absolute;
    z-index: 1;
    top: 0;
}

.becomeAGolfer .background-client-stories div.buyer1:after,
.becomeAGolfer .background-client-stories div.buyer2:after {
    left: 0;
}

.becomeAGolfer .background-client-stories div.buyer3:after,
.becomeAGolfer .background-client-stories div.buyer4:after {
    right: 0;
}

.becomeAGolfer .clientStories h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    color: #FFFFFF;
    margin: 10px 0px 10px 0px;
}

.becomeAGolfer .clientStories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    background: #000000;
    border-radius: 20px;
}

.becomeAGolfer .clientStoriesWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 20px;
    width: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.becomeAGolfer .clientStoryBox {
    min-height: 196px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    width: 100%;

}

.becomeAGolfer .clientStoryBoxImage {
    height: 100%;
    background-image: url('../Assets/Images/Profile2.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    width: 50%;
}

.becomeAGolfer .clientStoryBoxImage.third {
    background-image: url('../Assets/Images/Profile3.png');

}

.becomeAGolfer .clientStoryBoxImage.forth {
    background-image: url('../Assets/Images/Profile4.png');
    justify-content: flex-start;
}

.becomeAGolfer .clientStoryBoxImage.fifth {
    background-image: url('../Assets/Images/Profile5.png');
    justify-content: flex-start;
}

.becomeAGolfer .clientStoryBoxText {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    text-align: left;
    padding: 10px;

    height: 100%;
    color: #000000;
    background: #FFFFFF;
}

.becomeAGolfer .clientStoryBoxText h3 {

    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 153.9%;
    /* or 38px */


    color: #000000;
}

/* Footer */

.becomeAGolfer .footerSection {
    width: 100%;
    background-image: url('../Assets/Images/footerBackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.becomeAGolfer .poweredBy {
    width: auto;
    height: 100px;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    right: 0;
}

.becomeAGolfer .footer {
    padding: 10px 0;
    position: relative;
}

.becomeAGolfer .footer img {
    margin: auto;
    margin-bottom: 50px;
    margin-top: 20px;
}

.becomeAGolfer .footer p {
    margin-top: 50px;
}


.becomeAGolfer .footer ul a li {
    color: black;
    border: none;
}

/* FAQs Section*/

.becomeAGolfer .faqsSection {
    width: 100%;
    height: fit-content;
    color: black;
    padding: 50px 0;
    animation: paralex 1s;
}

.becomeAGolfer .faq_wrapper {
    width: 100%;
}

.becomeAGolfer .faqs h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 53px;
    text-align: center;
    margin-bottom: 50px;
}

.becomeAGolfer .faqbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 28px;
    gap: 10px;
    background: #fff;
    border: 1px solid #D9D9D9;
    height: fit-content;
}

.becomeAGolfer .faqHeading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.becomeAGolfer .faqHeading h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.becomeAGolfer .faqHeading img {
    height: 10px;
    width: 15px
}

.becomeAGolfer .faqbox p {

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: black;
}

.becomeAGolfer .faqbox .inVisibleAns {
    display: none;
}

.becomeAGolfer .faqbox .visibleAns {
    display: block;
}

.becomeAGolfer .faq_wrapper {
    display: grid;
    --auto-grid-min-size: 400px;
    grid-gap: 20px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

/* Banner Section*/

.becomeAGolfer .bannerSection {
    background: var(--secondary-color);
    height: 150px;
}

.becomeAGolfer .bannerSection .bannerInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.becomeAGolfer .bannerSection .bannerInfo h2 {

    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: #FFFFFF;
}

.becomeAGolfer .whiteBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 221px;
    height: 59px;
    background: #FFFFFF;
    cursor: pointer;
    font-size: 22px;
}

.becomeAGolfer .whiteBtn:hover {
    background: #dedede;
}

.becomeAGolfer .red {
    color: var(--secondary-color);
}



/* Responsive */

@keyframes paralex {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 100;
    }
}

@media only screen and (min-width: 1174px) {
    .becomeAGolfer .burger {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1074px) {
    .becomeAGolfer .HowItWorksInnerStatistic .box {
        min-height: 80px;
    }

    .becomeAGolfer .HowItWorksInnerStatistic .box {
        gap: 4px;
    }

    .becomeAGolfer .HowItWorksInnerStatistic .box p {
        font-size: 15px;
        font-weight: 500;
    }

    .becomeAGolfer .HowItWorksInnerStatistic .box h2 {
        font-size: 20px;
        line-height: 24px;
    }

    .becomeAGolfer #box-tablet {
        display: none;
        visibility: hidden;
    }

    .becomeAGolfer .header {
        height: 320px;
        padding-top: 100px;
    }

    .becomeAGolfer .headerBtns {
        margin-top: 35px;
    }

    .becomeAGolfer .clientStoryBoxText h3 {
        font-size: 12px;
    }

    .becomeAGolfer .clientStoryBoxText p {
        font-size: 12px;
    }

    .becomeAGolfer .clientStoryBoxImage {
        height: 120px
    }

    .becomeAGolfer .clientStoryBox {
        min-height: 165px;
    }

    .becomeAGolfer .faqs h3 {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .becomeAGolfer .faqHeading h2 {
        font-size: 14px;
    }

    .becomeAGolfer .faqbox p {
        font-size: 14px;
    }

    .becomeAGolfer .bannerSection .bannerInfo {
        gap: 10px;
        padding: 10px 0 10px 0;
    }

    .becomeAGolfer .bannerSection {
        height: 110px;
    }

    .becomeAGolfer .bannerSection .bannerInfo h2 {
        font-size: 22px;
    }

    .becomeAGolfer .whiteBtn {
        width: 180px;
        font-size: 20px;
    }

    .becomeAGolfer .HowItWorks .container {
        padding: 20px;
    }

    .becomeAGolfer .HowItWorks h1 {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}

@media only screen and (max-width: 723px) {
    .becomeAGolfer .productHeader {
        display: block;
        align-items: center;
    }

    .becomeAGolfer .header h1 {
        font-size: 2em;
    }

    .becomeAGolfer .headerBtn1 {
        width: 200px;
        padding: 15px 20px;
    }

    .becomeAGolfer #box-mobile {
        display: none;
        visibility: hidden;
    }

    .becomeAGolfer .poweredBy {
        width: auto;
        height: 100px;
        z-index: 1000;
        position: static;
    }

    .becomeAGolfer .HowItWorksInner .box {
        gap: 4px;
        min-height: 100px;
    }

    .becomeAGolfer .HowItWorksInner .box p {
        padding: 0 10% 0 10%;
    }
}

@media only screen and (max-width: 481px) {
    .becomeAGolfer .testimonialBottom {
        display: block;
    }

    .becomeAGolfer .footer ul {
        display: flex;
        flex-direction: column;

    }

    .becomeAGolfer .testimonialBottom .stars {
        margin-top: 20px;
    }

    .becomeAGolfer .headerBtns {
        flex-direction: column;
        margin-top: 10px;
    }

    .becomeAGolfer .faq_wrapper {
        display: grid;
        --auto-grid-min-size: 200px;
        grid-gap: 20px;
        grid-template-columns:
            repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }
}

@media only screen and (max-width: 621px) {
    .becomeAGolfer .HowItWorksInnerStatistic {
        display: flex;
        flex-direction: column;
    }

    .becomeAGolfer .clientStoriesWrapper {

        flex-direction: column;
    }

    .becomeAGolfer .clientStoriesSection {
        padding: 0;
    }

    .becomeAGolfer .HowItWorksInner {
        display: flex;
        flex-direction: column;
    }

    .becomeAGolfer .HowItWorksInner .box {
        height: fit-content;
    }
}

@media only screen and (max-width: 1174px) {

    .becomeAGolfer .header {
        height: 320px;
        background-position: 20%;
        background-image: url('../Assets/Images/golfmoney.jpg');
    }

    .wrap-headers {
        color: white;
        text-shadow: 0 1px 0 black;
    }

    .becomeAGolfer .burger {
        display: block;
        visibility: visible;
    }

    .becomeAGolfer .on-scroll li {
        cursor: pointer;
        transition: 1s;
        color: black;

    }

    .becomeAGolfer .footer ul {
        visibility: visible;
        display: flex;
        gap: 40px;
    }

    .becomeAGolfer .navBtns2 {
        display: none;
        visibility: hidden;
    }

    .becomeAGolfer .navBtns1 {
        display: none;
        visibility: hidden;
    }

    .becomeAGolfer .wrap-client-stories {
        width: 90vw;
        justify-content: center;
        align-items: center;
    }

    .becomeAGolfer .background-client-stories div {
        width: 90%;
        padding: 0 !important;
        max-height: 175px;
        margin: 0 auto;
        height: auto;
        float: unset;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .becomeAGolfer .background-client-stories div blockquote {
        font-size: 14px;
        width: 100%;
        position: relative;
        transform: unset;
        padding: 15px;
    }

    .becomeAGolfer .background-client-stories div blockquote cite {
        font-size: 16px;
        font-weight: 500;
    }

    .becomeAGolfer .background-client-stories div:before,
    .becomeAGolfer .background-client-stories div.buyer1:after,
    .becomeAGolfer .background-client-stories div.buyer2:after,
    .becomeAGolfer .background-client-stories div.buyer3:after,
    .becomeAGolfer .background-client-stories div.buyer4:after {
        display: none;
    }

    .background-client-stories {
        display: table;
        margin: 0 auto;
        width: 90%;
        background-color: var(--primary-color);
        border-radius: 8px;
    }

    .becomeAGolfer .faqsSection {
        padding: 20px 0;
    }
}
.barChart01Icon,
.dotIcon,
.logoIcon {
  position: relative;
  width: 154px;
  height: 41.91px;
  flex-shrink: 0;
}

.bgNone .logoIcon {
  background: url("/public/logo.svg");
  background-size: contain;
}

.logoIcon {
  background: url("/public/logo-blue.svg");
  background-size: contain;
}

.onScroll .logoIcon {
  background: url("/public/logo-blue.svg");
  background-size: contain;
}

.navItemButton {
  position: relative;
}

.unread {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

.barChart01Icon,
.dotIcon {
  width: 10px;
  height: 10px;
  overflow: hidden;
  display: none;
}

.barChart01Icon {
  width: 24px;
  height: 24px;
}

.text,
.text1 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: var(--primary-color);
}

.bgNone .text {
  color: white;
}

.register {
  color: white !important;
}

.textBlack {
  color: black;
}

.text1 {
  line-height: 18px;
  font-weight: 500;
}

.badge {
  border-radius: var(--br-base);
  background-color: var(--gray-100);
  display: none;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-5xs);
  align-items: center;
  justify-content: flex-start;
  mix-blend-mode: multiply;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--gray-700);
}

.chevronDownIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.button,
.button1,
.content,
.navItemBase,
.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.button:hover,
.button1:hover {
  background-color: #b42218de;
}

.navItemBase {
  border-radius: var(--br-7xs);
  padding: var(--padding-5xs) var(--padding-5xs);
  gap: var(--gap-5xs);
  cursor: pointer;
}

.navItemBase.active {
  border-radius: var(--br-7xs);
  background-color: #cfcfcf;
}

.button,
.button1,
.content,
.navigation {
  gap: var(--gap-xs);
}

.button,
.button1,
.content {
  gap: var(--gap-40);
}

.button,
.button1 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-lg);
  justify-content: center;
  gap: var(--gap-5xs);
}

.button1 {
  background-color: var(--secondary-color);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--secondary-color);
}

.navigationActions {
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.menu,
.header,
.navigationActions {
  display: flex;
  align-items: center;
}

.bgNone .navigationActions:hover {
  border-bottom: 2px solid white;
}

.navigationActions:hover {
  border-bottom: 2px solid var(--primary-color);
}

.menu {
  flex-direction: row;
  padding: 0 var(--padding-32xl);
  box-sizing: border-box;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 70px;
}

.header {
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  height: var(--header-height);
  flex-direction: column;
  justify-content: center;
}

.dropdownHeaderNavigation {
  align-self: stretch;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--text-md-regular-size);
  color: var(--base-white);
  font-family: var(--text-md-regular);
  top: 0;
  right: 0;
}

.actions,
.navItemButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.navItemButton {
  border-radius: var(--br-7xs);
  overflow: hidden;
  padding: var(--padding-3xs);
}

.actions {
  gap: var(--gap-9xs);
}

.avatarIcon {
  border-radius: var(--br-181xl);
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  object-fit: cover;
  border: 1px solid #dfdfdf;
}

.bgNone {
  background: unset;
  background-color: unset;
}

.bgNone.onScroll {
  /* background-color: var(--primary-color); */
  background-color: white;
}

.rightNav,
.dropdown {
  justify-content: flex-start;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.rightNav {
  flex-direction: row;
}

.menu,
.rightNav,
.headerNavigation {
  display: flex;
  align-items: center;
}

.navHeader {
  border-bottom: 3px solid var(--gray-100);
}

.navHeader.bgNone {
  border-bottom: unset;
}

.onScroll.navHeader {
  border-bottom: 3px solid var(--primary-color);
}

/* Nav Bar */
.navHeader {
  padding-bottom: 0px;
  width: 100vw;
  position: fixed;
  left: 0;
  z-index: 100;
  height: auto;
  margin: 0 auto;
  background-color: white;
  height: var(--header-height);
}

.onScroll .text {
  color: var(--primary-color);
}

.bgNone {
  background: unset;
  background-color: unset;
}

.bgNone.onScroll {
  /* background-color: var(--primary-color); */
  background-color: white;
}

.navbar {
  margin: auto;
  display: flex;
  align-items: center;
  color: white !important;
  justify-content: center;
}

.navbar.isActive {
  color: black;

}

.navbar .onScroll {
  color: black;
}

.onScroll {
  background-color: white;
}

.onScroll.navHeader {
  border-bottom: 3px solid var(--primary-color);
}

.placeholderNavLine1 {
  height: 3px;
  width: 100%;
  background-color: var(--secondary-color);
}

.placeholderNavLine2 {
  height: 3px;
  width: 100%;
  background-color: white;
}

.placeholderNavLine1,
.placeholderNavLine2 {
  display: none;
}

.onScroll .placeholderNavLine1,
.onScroll .placeholderNavLine2 {
  display: block;
}

.searchLgIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.messageChatSquareIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.settings {
  background: url("/public/settings-white.svg");
}

.onScroll .settings {
  background: url("/public/settings.svg");
}

.bgNone .bell {
  background: url("/public/bell-white.svg");
}

.bell,
.onScroll .bell {
  background: url("/public/bell.svg");
}

.bgNone .chat {
  background: url("/public/messagechatsquarewhite.svg");
}

.chat,
.onScroll .chat {
  background: url("/public/messagechatsquare.svg");
}

.rightLink {
  color: var(--secondary-color) !important;
}

.dropdownContent {
  position: absolute;
  top: 100%;
  background-color: #fff;
  min-width: 160px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  margin-top: 5px;
}

.dropdownContent span {
  color: var(--primary-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent span:hover {
  background-color: #f5f5f5;
}

.dropdownContent span.signOut {
  color: var(--secondary-color);
}

.wrapBurger {
  display: none;
}

.mobileMenu {
  display: none;
}

.wrapMobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .wrapBurger {
    display: flex;
    top: 0;
    align-self: flex-start;
    margin-top: 10px;
    margin-left: 10px;
  }

  .navHeader {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    /* height: unset; */
  }

  .menu {
    width: 100%;
    gap: 20px;
    height: auto;
  }

  .wrapMobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
  }

  /* Hide the menu by default */
  .menu {
    display: none;
  }

  .navHeader.isActive {
    background-color: White;
    height: 100%;
    position: fixed;
  }

  .navHeader.isActive .menu {
    display: flex;
  }

  .navHeader.isActive .mobileMenu {
    display: none;
  }

  .wrapBurger {
    height: 50px;
    position: relative;
    margin: 0 auto;
    align-items: center;
  }

  .navHeader.isActive .wrapBurger {
    display: none;
  }

  .text,
  .bgNone .text {
    color: var(--primary-color);
  }

  .burgerLogo {
    width: 30vw;
    max-width: 120px;
    height: 100%;
    background: url("/public/logo-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bgNone .burgerLogo {
    width: 30vw;
    max-width: 120px;
    height: 100%;
    background: url("/public/logo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .logoIcon,
  .bgNone .logoIcon {
    background: url("/public/logo-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .content,
  .navigation,
  .menu {
    flex-direction: column;
  }

  /* Styles for the hamburger icon */
  .burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    /* top: 20px;
    right: 15px; */
  }

  .mobileMenu .button1 {
    padding: 1vw 2vw;
  }

  .navHeader {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .dropdownContent {
    right: calc(100% - 40px);
  }

  .bgNone .burger span {
    width: 100%;
    height: 2px;
    background-color: white;
    transition: transform 0.3s ease;
    border-radius: 2px;
  }

  .burger span {
    width: 100%;
    height: 2px;
    background-color: var(--secondary-color);
    transition: transform 0.3s ease;
    border-radius: 2px;
  }

  .burger.isActive span {
    background-color: var(--primary-color);
  }

  .burger.isActive span:nth-child(1) {
    transform: rotate(45deg) translate(2px, 3px);
  }

  .burger.isActive span:nth-child(2) {
    opacity: 0;
  }

  .burger.isActive span:nth-child(3) {
    transform: rotate(-45deg) translate(1px, -1px);
  }

  .navHeader {
    border-bottom: 1px solid var(--gray-100);
  }

  .bgNone .chat {
    background: url("/public/messagechatsquare.svg");
  }

  .bgNone .mobileMenu .chat {
    background: url("/public/messagechatsquarewhite.svg");
  }

  .mobileMenu {
    display: flex;
    position: absolute;
    right: 10px;
    align-items: center;
    height: 100%;
  }

  .text {
    font-size: 3.5vw;
  }
}
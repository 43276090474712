.text {
  position: relative;
  line-height: 20px;
}

.mail01Icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  overflow: hidden;
}

.footer,
.row {
  display: flex;
  flex-direction: row;
}

.row {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.footer {
  align-self: stretch;
  height: 96px;
  flex-shrink: 0;
  padding: var(--padding-32xl);
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: space-between;
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-600);
  font-family: var(--text-sm-regular);
}

@media screen and (max-width: 600px) {
  .footer {
    display: none;
  }

}
.featuredIcon {
    position: relative;
    border-radius: var(--br-3xs);
    width: 52px;
    height: 52px;
}

.supportingText,
.text {
    align-self: stretch;
    position: relative;
}

.text {
    line-height: 28px;
    font-weight: 600;
}

.supportingText {
    font-size: var(--text-sm-regular-size);
    line-height: 20px;
    color: var(--gray-600);
}

.content,
.textAndSupportingText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-9xs);
}

.content {
    align-self: stretch;
    background-color: var(--base-white);
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-5xl) 0;
    gap: var(--gap-base);
    z-index: 0;
}

.xCloseIcon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.buttonCloseX {
    margin: 0 !important;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.paddingBottom {
    align-self: stretch;
    height: 20px;
    z-index: 2;
}

.dividerIcon,
.modalHeader {
    align-self: stretch;
    position: relative;
}

.dividerIcon {
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
    z-index: 3;
}

.modalHeader {
    background-color: var(--base-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.trophyIcon {

    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.award05,
.trophyIcon,
.trophyWrapper {
    overflow: hidden;
}

.trophyWrapper {
    height: 98.35%;
    width: 98.35%;
    top: 0;
    right: 1.65%;
    bottom: 1.65%;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--base-white);
}

.award05 {
    height: 72.62%;
    width: 72.62%;
    top: 14.29%;
    right: 13.09%;
    bottom: 13.09%;
    left: 14.29%;
}

.event,
.text1 {
    position: relative;
}

.event {
    border-radius: var(--br-3xs);
    border: 2px solid var(--secondary-color);
    width: 56px;
    height: 56px;
}

.text1 {
    line-height: 28px;
    font-weight: 600;
    font-size: 20px;
}

.textAndCategory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-lg-semibold-size);
}

.text2 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    font-size: 18px;
}

.buttonGroupBase,
.buttonGroupBase1,
.buttonGroupBase2 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
}

.buttonGroupBase {
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    z-index: 8;
}

.buttonGroupBase1,
.buttonGroupBase2 {
    display: none;
}

.buttonGroupBase1 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    z-index: 7;
}

.buttonGroupBase2 {
    z-index: 6;
}

.buttonGroupBase2,
.buttonGroupBase3,
.buttonGroupBase4 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase3 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
    display: none;
    z-index: 5;
}

.buttonGroupBase4 {
    z-index: 4;
}

.buttonGroupBase4,
.buttonGroupBase5,
.buttonGroupBase6 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
    display: none;
}

.buttonGroupBase5 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.buttonGroupBase6 {
    z-index: 2;
}

.buttonGroupBase6,
.buttonGroupBase7,
.buttonGroupBase8 {
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase7 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
    display: none;
    z-index: 1;
}

.buttonGroupBase.selected {
    background-color: var(--error-700);
    border-right: 1px solid var(--error-700);
    display: flex;
    z-index: 0;
    color: var(--base-white);
}

.buttonGroup {
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--gray-700);
}

.p1 {
    flex: 1;
    position: relative;
    line-height: 20px;
}

.p1Wrapper {
    align-self: stretch;
    flex-direction: row;
    color: var(--base-black);
}

.eventParent,
.p1Wrapper,
.textContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textContainer {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-5xs);
}

.eventParent {
    width: 592px;
    flex-direction: row;
    gap: var(--gap-base);
}

.checkboxBaseIcon {
    position: relative;
    border-radius: var(--br-5xs);
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.topBar {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.content2,
.listingSearchResult {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content2 {
    flex: 1;
    flex-direction: column;
}

.listingTypeCard.selected {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--error-25);
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border: 1px solid var(--error-700);
    flex-direction: row;
    padding: var(--padding-base);
    font-size: var(--text-sm-regular-size);
}

.groupChild,
.medalIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.groupChild {
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26.01%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.5%;
    bottom: 45.79%;
    left: 27.95%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.88%;
}

.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.groupWrapper,
.medalParent {
    position: absolute;
    height: 48.28%;
    width: 82.87%;
    top: 31.03%;
    right: 9.43%;
    bottom: 20.69%;
    left: 7.7%;
}

.medalParent {
    height: 71.43%;
    width: 71.43%;
    top: 14.29%;
    right: 14.29%;
    bottom: 14.29%;
    left: 14.29%;
    border-radius: var(--br-29xl);
    background-color: var(--base-white);
    overflow: hidden;
}

.p1Container,
.textAndCategory1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.p1Container {
    align-self: stretch;
    flex-direction: row;
    font-size: var(--text-sm-regular-size);
    color: var(--base-black);
}

.checkboxBase,
.listingTypeCard {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
}

.checkboxBase {
    position: relative;
    border: 1px solid var(--gray-300);
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.listingTypeCard {
    align-self: stretch;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border: 1px solid var(--gray-200);
    display: flex;
    flex-direction: row;
    padding: var(--padding-base);
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    transition: 200ms;
}

.listingTypeCard:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.badgeIcon,
.golfBallIcon {
    position: absolute;
    height: 117.24%;
    width: 117.24%;
    top: 0;
    right: -17.24%;
    bottom: -17.24%;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.golfBallIcon {
    height: 134.48%;
    width: 134.48%;
    right: -34.48%;
    bottom: -34.48%;
}

.listingRow {
    gap: var(--gap-xs);
}

.content1,
.grid,
.listingRow {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content1 {
    padding: var(--padding-xl) var(--padding-5xl) 0;
}

.content1 {
    flex-grow: 1;
    overflow-y: auto;
}

/* Styling the scrollbar for Chrome, Safari and Opera */
.content1::-webkit-scrollbar {
    width: 10px;
}

.content1::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content1::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    outline: 1px solid slategrey;
}

.dividerIcon1 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.dividerWrap {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 0 var(--padding-5xl);
    align-items: flex-start;
    justify-content: flex-start;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text14 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.cancelButton,
.nextButton {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    cursor: pointer;
    transition: 200ms;
}

.nextButton {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--base-white);
}

.nextButton:hover {
    opacity: 0.8;
    box-shadow: var(--shadow-xs);
}

.cancelButton:hover {
    border: 1px solid var(--gray-600);
}

.content6,
.modal,
.modalActions,
.chooseListingModal {
    display: flex;
    justify-content: flex-start;
}

.content6 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-5xl) var(--padding-5xl);
    align-items: flex-start;
    gap: var(--gap-xs);
}

.modal,
.modalActions,
.chooseListingModal {
    flex-direction: column;
}

.chooseListingModal {
    position: relative;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xl);
    width: 100%;
    overflow: hidden;
    text-align: left;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-700);
    font-family: var(--text-md-semibold);
    padding-bottom: 100px;
    max-height: 80vh;
}

.modalActions {
    align-self: stretch;
    padding: var(--padding-32xl) 0 0;
    align-items: flex-start;
    font-size: var(--text-md-semibold-size);
    color: var(--gray-700);
}

.modalActions {
    position: absolute;
    bottom: 0;
    padding: 0;
    background-color: white;
    align-items: center;
    border-top: 1px solid #e1e1e1;
}

.modal {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xl);
    max-width: 750px;
    overflow: hidden;
    align-items: center;
    text-align: left;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-900);
    font-family: var(--text-md-semibold);
}

.p1 {
    font-size: 16px;
}

@media screen and (max-width: 600px) {
    .content1 {
        padding: 10px;
    }

    .event {
        width: 50px;
        height: 50px;
    }

    .eventParent {
        width: 100%;
    }

    .text2 {
        font-size: 0.9rem;
    }

    .text1 {
        font-size: 1rem;

    }

    .p1 {
        font-size: 0.8rem;
    }

    .buttonGroupBase {
        padding: var(--padding-7xs) var(--padding-3xs)
    }

    .modal {
        overflow-y: scroll;
        max-height: 85svh;
        padding-bottom: env(safe-area-inset-bottom);
    }

    .featuredIcon {
        display: none;
    }

    .content {
        padding: 10px;
        max-width: 90%;
    }

    .buttonCloseX {
        right: 0px;
        top: 0px;
    }
}
.barChart01Icon,
.contentChild,
.dotIcon {
    position: relative;
    width: 154px;
    height: 41.91px;
    flex-shrink: 0;
}

.barChart01Icon,
.dotIcon {
    width: 10px;
    height: 10px;
    overflow: hidden;
    display: none;
}

.barChart01Icon {
    width: 24px;
    height: 24px;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.content1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.text1 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
}

.chevronDownIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.badge1,
.navItemBase {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.navItemBase {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
}

.badge1 {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--gray-700);
}

.navItemBase1 {
    border-radius: var(--br-7xs);
    background-color: var(--gray-200);
    overflow: hidden;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
    color: var(--gray-900);
}

.content,
.navItemBase1,
.navItemBase5,
.navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.navItemBase5 {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
    color: var(--error-800);
}

.content,
.navigation {
    gap: var(--gap-9xs);
}

.content {
    gap: var(--gap-40);
}

.searchLgIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.actions,
.navItemButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.navItemButton {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    padding: var(--padding-3xs);
}

.actions {
    gap: var(--gap-9xs);
}

.avatarIcon {
    border-radius: var(--br-181xl);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    object-fit: cover;
}

.content7,
.dropdown {
    justify-content: flex-start;
}

.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content7 {
    flex-direction: row;
    gap: var(--gap-base);
}

.container,
.content7,
.headerNavigation {
    display: flex;
    align-items: center;
}

.container {
    width: 1280px;
    height: 72px;
    flex-shrink: 0;
    flex-direction: row;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    justify-content: space-between;
}

.headerNavigation {
    align-self: stretch;
    background-color: var(--base-white);
    border-bottom: 1px solid var(--gray-200);
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--gray-700);
}

.breadcrumbButtonBase {
    border-radius: var(--br-7xs);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.chevronRightIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    overflow: hidden;
}

.text12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.breadcrumbButtonBase1,
.breadcrumbButtonBase3 {
    border-radius: var(--br-7xs);
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
}

.breadcrumbButtonBase1 {
    display: flex;
}

.breadcrumbButtonBase3 {
    display: none;
}

.chevronRightIcon3 {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.text15 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.breadcrumbButtonBase4 {
    border-radius: var(--br-7xs);
    background-color: var(--gray-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    color: var(--gray-700);
}

.breadcrumbs,
.tabs {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.tabs {
    display: flex;
    gap: var(--gap-5xs);
}

.breadcrumbs {
    display: none;
}

.supportingText,
.text16 {
    align-self: stretch;
    position: relative;
}

.text16 {
    line-height: 38px;
    font-weight: 600;
    font-size: var(--display-xs-medium-size);
}

.supportingText {
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-600);
}

.textAndSupportingText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--display-sm-semibold-size);
    color: var(--gray-900);
}

.button {
    display: none;
}

.button,
.button1,
.button2 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button1 {
    background-color: var(--primary-50);
    border: 1px solid var(--primary-50);
    display: none;
    color: var(--primary-700);
}

.button2 {
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    color: var(--gray-700);
    cursor: pointer;
}

.text20 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    font-family: var(--text-md-regular);
    color: var(--base-white);
    text-align: left;
}

.button3 {
    cursor: pointer;
    border: 1px solid var(--primary-color);
    padding: var(--padding-3xs) var(--padding-base);
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    background-color: var(--primary-color);
}

.inputWithLabel {
    border: 0;
    font-family: var(--text-md-regular);
    font-size: var(--text-md-regular-size);
    background-color: transparent;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.hintText {
    position: relative;
    line-height: 20px;
    display: none;
    width: 320px;
}

.listingSummary,
.inputDropdown {
    align-items: flex-start;
    justify-content: flex-start;
}

.inputDropdown {
    width: 320px;
    flex-shrink: 0;
    display: none;
    flex-direction: column;
    gap: var(--gap-5xs);
}

.listingSummary {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: var(--gap-base);
}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.container1,
.pageHeader,
.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pageHeader {
    align-self: stretch;
    align-items: flex-start;
    gap: var(--gap-xl);
}


.container1 {
    box-sizing: border-box;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%
}

.section {
    align-items: center;
    color: var(--gray-600);
    justify-content: center;
    align-self: center;
}

.section span {
    width: 100%;
}

.sectionBottom {
    padding: 64px 0 var(--padding-29xl);
}

.categoryIcon {
    object-fit: contain;
    width: 45px;
    height: 45px;
}

.categoryImgWrapper {
    position: relative;
    border-radius: var(--br-29xl);
    background-color: #eeeeee;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

}

.categoryText {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    font-size: 16px;
}

.category.active {
    color: var(--secondary-color);
}

.category.active .categoryImgWrapper {
    border: 2px solid var(--secondary-color);
}

.golfBallIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 39px;
    height: 39px;
    object-fit: cover;
}

.categoryRow {
    align-self: stretch;
    background-color: var(--base-white);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--gap-base);
    text-align: center;
    font-size: var(--text-xs-medium-size);
    min-width: 500px;
}

.category {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    cursor: pointer;
    transition: 200ms;
    color: #565656;
    padding: 10px;
}

.category:hover .categoryImgWrapper {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.badgeIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 34px;
    height: 34px;
    object-fit: cover;
}

.basketIcon,
.groupChild,
.teeIcon,
.trophyIcon {
    position: absolute;
    top: 4px;
    left: 0;
    width: 33px;
    height: 33px;
    object-fit: cover;
}

.basketIcon,
.groupChild,
.teeIcon {
    top: 5px;
    left: 1px;
    width: 28px;
    height: 28px;
}

.groupChild,
.teeIcon {
    top: 0;
    left: 0;
    width: 29px;
    height: 29px;
}

.groupChild {
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.56%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26.01%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.56%;
    object-fit: cover;
}

.layer2Copy,
.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.5%;
    bottom: 45.79%;
    left: 27.95%;
}

.layer2Copy,
.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.88%;
}

.layer2Copy {
    height: 36.82%;
    width: 100%;
    top: 36.17%;
    right: 0;
    bottom: 27.01%;
    left: 0;
}

.groupWrapper,
.rectangleParent {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.groupWrapper {
    height: 48.28%;
    width: 82.87%;
    top: 31.03%;
    right: 9.43%;
    bottom: 20.69%;
    left: 7.7%;
}


.label,
.supportingText1,
.text29 {
    position: relative;
    font-family: var(--text-md-regular);
    text-align: left;
}

.label {
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 500;
    color: var(--gray-700);
    display: none;
}

.supportingText1,
.text29 {
    font-size: var(--text-md-regular-size);
    line-height: 24px;
}

.text29 {
    font-weight: 500;
    color: var(--gray-900);
}

.supportingText1 {
    color: var(--gray-600);
    display: none;
}

.content10,
.input,
.inputWithLabel1 {
    display: flex;
    justify-content: flex-start;
}

.content10 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.input,
.inputWithLabel1 {
    align-self: stretch;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
}

.inputWithLabel1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText1 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-family: var(--text-md-regular);
    color: var(--gray-600);
    text-align: left;
    display: none;
    width: 320px;
}

.inputDropdown1 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    width: 200px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.label1,
.text30 {
    position: relative;
    font-weight: 500;
}

.label1 {
    line-height: 20px;
    display: none;
}

.text30 {
    line-height: 24px;
}

.input1,
.inputWithLabel2 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input1 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.inputWithLabel2 {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText2 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
    width: 320px;
}

.inputDropdown2 {
    width: 180px;
    flex-shrink: 0;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.text31 {
    position: relative;
    line-height: 24px;
}

.input2,
.inputDropdown3 {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.input2 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.inputDropdown3 {
    width: 168px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
}

.button4,
.dropdowns,
.input3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.input3 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--primary-color);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--primary-color);
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--base-white);
}

.button4,
.dropdowns {
    align-items: flex-start;
    gap: var(--gap-xs);
}

.button4 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.buttonParent,
.content9 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.buttonParent {
    justify-content: flex-start;
    gap: var(--gap-base);
}

.content9 {
    align-self: stretch;
    justify-content: space-between;
}

.input4,
.inputDropdown5 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input4 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.inputDropdown5 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--text-sm-semibold-size);
}

.button6,
.button7 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button7 {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--base-white);
}

.content14,
.filtersBar {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content14 {
    flex-direction: row;
    gap: var(--gap-xs);
    font-size: var(--text-md-regular-size);
}

.filtersBar {
    border-radius: var(--br-5xs);
    flex-direction: column;
    gap: var(--gap-base);
}

.mapIcon {
    position: absolute;
    height: 184%;
    width: 127.78%;
    top: -42%;
    right: -13.89%;
    bottom: -42%;
    left: -13.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.googleLogoIcon,
.rectangle {
    position: absolute;
    bottom: 2px;
    left: 6px;
    width: 66px;
    height: 26px;
}

.rectangle {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-11xs);
    background-color: var(--base-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.pegmanOffscreen2xIcon {
    position: absolute;
    height: 71.43%;
    width: 42.86%;
    top: 14.29%;
    right: 28.57%;
    bottom: 14.29%;
    left: 28.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.streetViewButton,
.zoomControlButtons {
    position: absolute;
    top: 69px;
    right: 8px;
    width: 28px;
    height: 28px;
}

.zoomControlButtons {
    top: 8px;
    height: 53px;
}

.satellite {
    top: 27.59%;
    left: 48.94%;
}

.line,
.map1,
.satellite {
    position: absolute;
}

.line {
    height: 103.45%;
    width: 1.06%;
    top: -1.72%;
    right: 59.04%;
    bottom: -1.72%;
    left: 39.89%;
    border-right: 1px solid #f2f2f2;
    box-sizing: border-box;
}

.map1 {
    top: 27.59%;
    left: 8.51%;
    font-weight: 500;
    color: var(--base-black);
}

.mapTypeButtonGroup {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 94px;
    height: 29px;
}

.pinMarkerIcon {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 12px);
    width: 24px;
    height: 32px;
    overflow: hidden;
    display: none;
}

.googleMapsMockup {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    overflow: hidden;
}

.outerCircle {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.1;
}

.innerCircle,
.middleCircle,
.outerCircle {
    position: absolute;
    border-radius: var(--br-9xl);
    background-color: var(--secondary-color);
}

.middleCircle {
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    opacity: 0.2;
}

.innerCircle {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
}

.mapLocationMarker,
.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    position: absolute;
    top: calc(50% - 71px);
    left: calc(50% - 46px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% - 87px);
    left: calc(50% - 116px);
}

.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% + 7px);
    left: calc(50% - 38px);
}

.mapLocationMarker3 {
    top: calc(50% - 97px);
    left: calc(50% + 38px);
}

.mapLocationMarker4,
.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    position: absolute;
    top: calc(50% - 133px);
    left: calc(50% + 14px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 23px);
    left: calc(50% - 112px);
}

.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 51px);
    left: calc(50% - 2px);
}

.mapLocationMarker7 {
    top: calc(50% - 178px);
    left: calc(50% - 42px);
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker8,
.mapLocationMarker9 {
    position: absolute;
    top: calc(50% - 145px);
    left: calc(50% + 62px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker9 {
    top: calc(50% - 139px);
    left: calc(50% + 156px);
}

.mapLocationMarker10,
.mapLocationMarker11 {
    top: calc(50% - 39px);
    left: calc(50% - 26px);
}

.mapLocationMarker11 {
    top: calc(50% + 71px);
    left: calc(50% - 34px);
}

.map {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-5xs);
    height: 360px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    text-align: center;
    font-size: var(--font-size-2xs);
    color: #565656;
    font-family: var(--font-roboto);
}

.container2,
.section1 {
    width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container2 {
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-24);
}

.section1 {
    align-items: center;
    color: var(--gray-700);
}

.trophyIcon1 {
    position: relative;
    width: 36px;
    height: 45px;
    flex-shrink: 0;
    object-fit: cover;
}

.heading {
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.flag05Icon1 {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.badge6,
.headingAndSubheading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.badge6 {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    gap: var(--gap-9xs);
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--primary-color);
}

.headingAndSubheading {
    align-self: stretch;
    gap: var(--gap-xl);
}

.headingAndSupportingText {
    width: 768px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.arrowLeftWrapper,
.frameParent,
.subheadingParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.arrowLeftWrapper {
    border-radius: var(--br-29xl);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    padding: var(--padding-3xs);
}

.frameParent,
.subheadingParent {
    gap: var(--gap-3xs);
}

.subheadingParent {
    align-items: center;
    gap: var(--gap-24);
    font-size: var(--text-md-regular-size);
    color: var(--error-700);
}

.content16,
.title {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.title {
    flex-direction: column;
    padding: 0 var(--padding-81xl);
    align-items: flex-start;
    justify-content: flex-start;
}

.image5Icon,
.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
}

.image5Icon {
    width: 739px;
    height: 258px;
    object-fit: cover;
}

.imageOverlay {
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
}

.award02Icon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.badge7,
.button8 {
    position: absolute;
    bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badge7 {
    left: 8px;
    border-radius: var(--br-base);
    background-color: var(--white-color);
    padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
}

.button8 {
    right: 8px;
    border-radius: var(--br-5xs);
    background-color: var(--color-gray-400);
    backdrop-filter: var(--background-blur-md);
    border: 1px solid var(--color-gray-300);
    overflow: hidden;
    padding: var(--padding-xs);
    justify-content: center;
}

.image,
.text40 {
    align-self: stretch;
    position: relative;
}

.image {
    height: 208px;
    flex-shrink: 0;
    overflow: hidden;
}

.text40 {
    line-height: 30px;
    font-weight: 600;
}

.textAndButton,
.textAndCategory {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndCategory {
    flex: 1;
    flex-direction: column;
}

.textAndButton {
    align-self: stretch;
    flex-direction: row;
    font-size: var(--text-xl-semibold-size);
}

.groupItem {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5,
.vectorIcon1 {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon4,
.rectangleIcon5 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.51%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon5 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.87%;
}

.avatarInner {
    position: absolute;
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.avatar {
    position: relative;
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    /* background-image: url(/public/avatar1@3x.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.textAndSubtext {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.supportingText2 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.content18 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
}

.dotIcon6 {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 2;
}

.wrapAvatar {
    align-items: center;
    position: relative;
    gap: var(--gap-xs);
    color: var(--gray-700);
}

.badge8,
.badges,
.wrapAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.badge8 {
    border-radius: var(--br-base);
    border: 1.5px solid var(--primary-color);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    gap: var(--gap-9xs);
}

.badges {
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.text44 {
    position: relative;
    line-height: 30px;
    font-weight: 600;
}

.badgesParent,
.textAndSupportingText2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.badgesParent {
    align-self: stretch;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--primary-color);
}

.detailsWrap {
    align-items: flex-start;
    gap: var(--gap-24);
}

.details,
.detailsWrap,
.detailsWrap1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.detailsWrap1 {
    flex: 1;
    align-items: flex-start;
}

.details {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-24);
    color: var(--gray-600);
}

.spotsTargetChart,
.slotsIndicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 228px;
    height: 8px;
}

.slotsIndicator {
    background-color: var(--secondary-color);
    width: 136.8px;
}

.chart {
    flex: 1;
    position: relative;
    height: 8px;
}

.spotsTarget,
.spotsFilled {
    position: relative;
    line-height: 140%;
}

.spotsFilled {
    font-weight: 600;
}

.spotsTarget {
    font-family: var(--text-md-regular);
    color: var(--gray-600);
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.container,
.detail,
.spots {
    display: flex;
    flex-direction: row;
}

.detail {
    width: 117px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.container {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
}

.content17,
.listingSearchResult,
.textAndButton1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndButton1 {
    flex-direction: row;
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content17,
.listingSearchResult {
    flex-direction: column;
}

.content17 {
    padding: var(--padding-xl) var(--padding-base) var(--padding-base);
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.listingSearchResult {
    flex: 1;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    width: 400px;
}

.outingImage {
    width: 100%;
}

.eventsNearYou,
.featuredRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-32);
}

.featuredRow {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-81xl);
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--error-700);
}

.eventsNearYou {
    overflow: hidden;
    flex-direction: column;
}

.golfBallIcon1 {
    position: relative;
    width: 53.85px;
    height: 40px;
    flex-shrink: 0;
    object-fit: cover;
}

.button10,
.details2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button10 {
    border-radius: var(--br-5xs);
    border: 2px solid var(--gray-200);
    overflow: hidden;
    padding: var(--padding-7xs);
    justify-content: center;
}

.details2 {
    align-self: stretch;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.text59 {
    flex: 1;
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.textAndButton4 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--font-size-3xl);
    color: var(--gray-900);
}

.avatar2 {
    position: relative;
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    /* background-image: url(/public/avatar2@3x.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.supportingText4 {
    position: relative;
    line-height: 24px;
    color: var(--gray-600);
}

.wrapAvatar2,
.rating {
    flex-direction: row;
    align-items: center;
}

.rating {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    color: var(--gray-900);
}

.wrapAvatar2 {
    position: relative;
    gap: var(--gap-xs);
}

.wrapAvatar2,
.textAndSupportingText7,
.textWrap {
    display: flex;
    justify-content: flex-start;
}

.textWrap {
    flex-direction: column;
    padding: 0 0 var(--padding-11xs);
    align-items: flex-start;
    font-size: var(--text-md-regular-size);
    color: var(--gray-600);
}

.textAndSupportingText7 {
    flex-direction: row;
    align-items: flex-end;
    gap: 2px;
}

.details4,
.textAndCategory4 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.details4 {
    flex: 1;
    justify-content: flex-end;
    font-size: var(--display-xs-medium-size);
    color: var(--gray-900);
}

.textAndCategory4 {
    justify-content: flex-start;
    gap: var(--gap-9xs);
    color: var(--gray-700);
}

.spotsTargetChart2,
.slotsIndicator2 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 429px;
    height: 8px;
}

.slotsIndicator2 {
    background-color: var(--secondary-color);
    width: 257.4px;
}

.container2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content22 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.listingSearchResultTourna {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 609px;
    flex-shrink: 0;
    flex-direction: row;
    padding: var(--padding-5xl);
    align-items: flex-start;
}

.listingSearchResultTourna,
.textAndSupportingText10,
.textWrap1 {
    display: flex;
    justify-content: flex-start;
}

.textWrap1 {
    flex-direction: column;
    padding: 0 0 var(--padding-11xs);
    align-items: flex-start;
}

.textAndSupportingText10 {
    flex-direction: row;
    align-items: flex-end;
}

.details7,
.listingRow {
    align-self: stretch;
    display: flex;
    flex-direction: row;
}

.details7 {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    color: var(--gray-600);
}

.listingRow {
    justify-content: flex-start;
    gap: var(--gap-24);
}

.container3,
.listingRow,
.results {
    align-items: flex-start;
}

.results {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container3 {
    width: 1242px;
}

.allCampaign,
.container3,
.eventsNearYou1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.allCampaign {
    background-color: var(--base-white);
    width: 1440px;
    overflow: hidden;
    padding: 0 0 0 var(--padding-81xl);
    box-sizing: border-box;
    align-items: flex-start;
    font-size: var(--text-md-regular-size);
    color: var(--gray-600);
}

.eventsNearYou1 {
    align-items: center;
    gap: var(--gap-32);
}

.basketIcon1 {
    position: relative;
    width: 41px;
    height: 46px;
    flex-shrink: 0;
    object-fit: cover;
}

.imageIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.badge15 {
    position: absolute;
    top: 167px;
    left: 222px;
    border-radius: var(--br-base);
    background-color: var(--error-700);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
}

.imageWrap {
    position: relative;
    border-radius: var(--br-5xs);
    background-color: var(--gray-100);
    width: 352px;
    height: 208px;
    flex-shrink: 0;
    overflow: hidden;
}

.content27 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-5xl);
    align-items: center;
    justify-content: flex-start;
}

.featuredIcon {
    position: relative;
    border-radius: var(--br-3xs);
    width: 66px;
    height: 66px;
    flex-shrink: 0;
}

.text73 {
    align-self: stretch;
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.button12 {
    border-radius: var(--br-5xs);
    border: 2px solid var(--gray-200);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs);
    align-items: center;
    justify-content: center;
}

.textAndButton6 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-24);
    font-size: var(--font-size-3xl);
}

.text74 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
}

.textAndSubtextInner {
    position: relative;
    width: 24.03px;
    height: 14px;
    flex-shrink: 0;
}

.textAndSubtext4 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-8xs);
}

.content29,
.rating2,
.textAndSupportingText11 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rating2 {
    flex-direction: row;
    gap: var(--gap-9xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.content29,
.textAndSupportingText11 {
    flex-direction: column;
}

.textAndSupportingText11 {
    align-self: stretch;
    gap: var(--gap-8xs);
}

.content29 {
    width: 242px;
    flex-shrink: 0;
    z-index: 0;
}

.dotIcon10 {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 1;
}

.wrapAvatar4,
.wrapAvatarWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
}

.wrapAvatar4 {
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-xs);
}

.wrapAvatarWrapper {
    align-items: flex-start;
    justify-content: center;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-700);
}

.details8,
.textAndSupportingText13 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.details8 {
    align-self: stretch;
    justify-content: flex-start;
    color: var(--gray-700);
}

.textAndSupportingText13 {
    justify-content: flex-end;
    gap: var(--gap-9xs);
}

.text78 {
    position: relative;
    font-size: var(--text-xl-semibold-size);
    line-height: 30px;
    font-weight: 600;
    color: var(--gray-900);
}

.content28,
.details9,
.textAndSupportingText14 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textAndSupportingText14 {
    justify-content: flex-end;
    gap: var(--gap-5xs);
    color: var(--gray-600);
}

.content28,
.details9 {
    align-self: stretch;
    justify-content: space-between;
}

.content28 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-smi);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.allCampaign1,
.listingSearchResultTourna1 {
    background-color: var(--base-white);
    align-items: flex-start;
}

.listingSearchResultTourna1 {
    flex: 1;
    border-radius: var(--br-xs);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    padding: var(--padding-5xl);
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.allCampaign1 {
    width: 1440px;
    overflow: hidden;
    padding: 0 0 0 var(--padding-81xl);
    box-sizing: border-box;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--base-white);
}

.allCampaign1,
.eventContainer,
.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.eventContainer {
    align-items: flex-start;
    gap: 45px;
    font-size: var(--display-xs-medium-size);
    margin-top: 30px;
}

.main {
    width: 100%;
}

.sections {
    align-self: stretch;
    background-color: var(--base-white);
    padding: var(--padding-29xl) 0 var(--padding-77xl);
    align-items: flex-start;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-900);
    padding-top: 100px;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.subHeading {
    position: relative;
    line-height: 32px;
    font-weight: 500;
    display: inline-block;
    width: 665px;
}

.interestedIn {
    margin: 0;
}

.text93 {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-27xl);
    line-height: 50px;
    font-weight: 600;
}

.heading3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.p13 {
    position: relative;
    font-size: var(--text-lg-semibold-size);
    line-height: 28px;
    font-weight: 500;
    display: inline-block;
    width: 580px;
}

.headingContent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-lgi);
}

.button2xlprimarydotLeading {
    border-radius: var(--br-5xs);
    background-color: var(--gray-50);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-50);
    overflow: hidden;
    padding: var(--padding-base) var(--padding-9xl);
}

.button2xlprimarydotLeading,
.button2xlprimarydotLeading1,
.buttonGroups {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
}

.button2xlprimarydotLeading1 {
    border-radius: var(--br-5xs);
    background-color: var(--error-700);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--error-700);
    overflow: hidden;
    padding: var(--padding-base) var(--padding-9xl);
    color: var(--base-white);
}

.buttonGroups {
    position: absolute;
    top: 354px;
    left: 0;
    width: 360px;
    font-size: var(--text-lg-semibold-size);
    color: var(--base-black);
}

.contentContainer {
    position: relative;
    width: 665px;
    height: 414px;
    flex-shrink: 0;
}

.cta {
    align-self: stretch;
    height: 640px;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 var(--padding-81xl);
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    background-image: url(/public/cta@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    font-size: var(--display-xs-medium-size);
}

.supportingText17 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-200);
}

.content37,
.textAndSupportingText23 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.textAndSupportingText23 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.content37 {
    flex-direction: row;
    align-items: center;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: none;
}

.input5 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.hintText6 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-6);
}

.button15 {
    border-radius: var(--br-5xs);
    background-color: var(--error-700);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--error-700);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--base-white);
}

.content36,
.emailCapture,
.section4 {
    display: flex;
    justify-content: flex-start;
}

.emailCapture {
    width: 400px;
    flex-shrink: 0;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-base);
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-900);
}

.content36,
.section4 {
    align-self: stretch;
}

.content36 {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-32);
}

.section4 {
    background-color: var(--gray-900);
    flex-direction: column;
    padding: var(--padding-29xl) 0;
    align-items: center;
}

.logoIcon {
    position: relative;
    width: 320px;
    height: 87.08px;
    flex-shrink: 0;
}

.supportingText18 {
    align-self: stretch;
    position: relative;
    line-height: 24px;
}

.logoAndSupportingText {
    width: 320px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-32);
}

.heading4 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.noListings {
    padding-top: 14px;
    font-size: 28px;
}

.badge18,
.button16,
.footerLink {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button16 {
    overflow: hidden;
    justify-content: center;
    gap: var(--gap-5xs);
}

.badge18,
.footerLink {
    justify-content: flex-start;
}

.badge18 {
    border-radius: var(--br-base);
    background-color: var(--color-gray-200);
    border: 1px solid var(--color-gray-100);
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: normal;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--base-white);
}

.footerSection {
    align-items: center;
    align-self: stretch;
    padding: 64px 0 var(--padding-29xl);
    align-items: center;
    gap: var(--gap-45xl);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footerLinks {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-200);
}

.content38,
.footerLinks,
.footerLinksColumn,
.links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.footerLinksColumn {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-base);
}

.content38,
.links {
    flex-direction: row;
}

.links {
    flex: 1;
    gap: var(--gap-32);
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-300);
}

.content38 {
    align-self: stretch;
    gap: var(--gap-45xl);
}

.footerText {
    flex: 1;
    position: relative;
    line-height: 24px;
}

.socialIcon {
    overflow: hidden;
}

.socialIcon,
.socialIcon5 {
    position: relative;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

.content39,
.socialIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.content39 {
    align-self: stretch;
    border-top: 1px solid var(--gray-600);
    padding: var(--padding-32xl) 0 0;
    gap: var(--gap-32);
}

.container7 {
    padding: 0 var(--padding-32xl);
    align-items: flex-start;
    color: var(--gray-500);
}

.container7,
.footer,
.listingPageViewAll,
.section5 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.section5 {
    align-self: stretch;
    padding: var(--padding-45xl) 0 var(--padding-29xl);
    align-items: center;
    gap: var(--gap-45xl);
    font-size: var(--text-md-regular-size);
    color: var(--gray-200);
}

.footer,
.listingPageViewAll {
    background-color: var(--primary-color);
    width: 100%;
}

.listingPageViewAll {
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--base-white);
    font-family: var(--text-md-regular);
}

.noListings {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    padding: 30px 20px;
    color: white;
    border-radius: 1rem;
    gap: 40px;
}

.noListings span {
    text-align: center;
}

.promoteGrowth {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.promoteGrowth button {
    cursor: pointer;
    border: 1px solid var(--error-700);
    padding: var(--padding-base) var(--padding-9xl);
    background-color: var(--error-700);
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    color: white;
    width: 200px;
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .sections {
        width: 100%;
        padding: var(--padding-11xs) 0 var(--padding-11xs);
        padding-top: var(--header-height);
    }

    .section {
        padding: 0 10px;
        width: 100%;
    }

    .text16 {
        font-size: 16px;
        line-height: 30px;
    }

    .supportingText {
        font-size: 12px;
    }

    .pageHeader {
        gap: var(--gap-6)
    }

    .container1 {
        border-bottom: 1px solid #e7e7e7;
    }

    .categoryRow {
        padding: 0;
        min-width: unset;
    }

    .category {
        padding: 5px;
    }

    .categoryImgWrapper {
        width: 60px;
        height: 60px;
    }

    .categoryIcon {
        width: 40px;
        height: 40px;
    }

    .categoryText {
        font-size: 12px;
    }

    .eventContainer {
        width: 100%;
        margin-top: unset;
        padding: 0 10px;
        gap: 60px;
        padding-bottom: 30px;
    }

    .sections {
        gap: 6px;
    }

    .sections,
    .categoryRow {
        background-color: #f1f1f1;
    }

    .categoryImgWrapper {
        background-color: white;
    }

    .noListings {
        font-size: 18px;
        padding: 20px 30px 20px 30px;
        margin: 20px 0 20px 0;
        gap: 20px;
        width: 90%
    }

    .promoteGrowth {
        gap: 20px;
    }

    .promoteGrowth button {
        font-size: 18px;
        width: auto;
    }

    .noListings .footerSection {
        gap: 10px;
        padding: 32px 0 32px;
    }
}
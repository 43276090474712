.featuredIcon {
    position: relative;
    border-radius: var(--br-3xs);
    width: 48px;
    height: 48px;
    display: none;
}

.supportingText,
.text {
    align-self: stretch;
    position: relative;
    display: none;
}

.text {
    line-height: 28px;
    font-weight: 600;
}

.supportingText {
    font-size: var(--text-sm-regular-size);
    line-height: 20px;
    color: var(--gray-600);
}

.content,
.textAndSupportingText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-9xs);
}

.content {
    align-self: stretch;
    background-color: var(--base-white);
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-5xl) 0;
    gap: var(--gap-base);
    z-index: 0;
}

.xCloseIcon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.buttonCloseX {
    margin: 0 !important;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}

.dividerIcon,
.paddingBottom {
    align-self: stretch;
    height: 20px;
    z-index: 2;
}

.dividerIcon {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
    display: none;
    z-index: 3;
}

.modalHeader,
.rectangle {
    background-color: var(--base-white);
}

.modalHeader {
    align-self: stretch;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.rectangle {
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-5xs);
}

.send01Icon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text1 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button,
.socialButton {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    display: flex;
    padding: var(--padding-3xs) var(--padding-lg);
    gap: var(--gap-5xs);
    transition: 200ms;
}

.button:hover {
    background-color: var(--gray-300);
}

.socialButton {
    flex: 1;
    display: none;
    padding: var(--padding-3xs) var(--padding-base);
    gap: var(--gap-xs);
}

.cancel,
.socialShare {
    display: flex;
    align-items: center;
}

.cancel {
    flex-direction: row;
    justify-content: center;
    gap: var(--gap-xs);
}

.socialShare {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    color: var(--gray-700);
    cursor: pointer;
}

.success {
    height: 5.56%;
    width: 43.98%;
    top: 50.56%;
    left: 28.01%;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
}

.listingCreated {
    font-size: var(--display-xs-semibold-size);
    font-weight: 600;
}

.yourListingIs {
    font-size: var(--text-sm-regular-size);
    font-family: var(--font-poppins);
}

.whiteBoxContent {
    display: flex;
    gap: 30px;
    flex-direction: column;
    height: 101.98%;
    width: 100%;
    align-items: center;
}

.illust1 {
    width: 156px;
    height: 104px;
    overflow: hidden;
}

.star1Icon {
    width: 96px;
    height: 96px;
    object-fit: cover;
}

.whiteBoxContentParent {
    position: relative;
    width: 432px;
    text-align: center;
    font-size: var(--text-md-semibold-size);
    color: var(--base-black);
    padding-bottom: 20px;
}

.modal {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xl);
    width: 688px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-900);
    font-family: var(--text-md-semibold);
}


@media screen and (max-width: 600px) {
    .modal {
        width: 100%;
    }

    .content1 {
        padding: 0px 10px 10px 10px;
        margin-top: 10px;
    }

    .text {
        font-size: 16px;
    }

    .supportingText {
        font-size: 12px;
    }

    .row {
        flex-direction: column;
    }

    .inputText {
        font-size: 12px;
    }

    .inputField {
        width: 100%;
    }

    .searchResultItem {
        line-height: 30px;
        font-size: 12px;
    }

    .textareaInputField {
        width: 100%;
    }

    .textarea {
        font-size: 12px;
    }

    .whiteBoxContentParent {
        width: 100%;
    }

    .whiteBoxContent {
        gap: 10px;
    }

    .star1Icon {
        width: 50px;
        height: 50px;
    }

    .modalHeader {
        height: 30px;
    }

    .wrapCard {
        width: 100%;
        padding: 0 10px 0 10px
    }

    .whiteBoxContentParent,
    .modal {
        max-height: 100%;
        overflow: auto;
    }

}
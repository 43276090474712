.divider,
.text {
  position: relative;
}

.divider {
  flex: 1;
  background-color: var(--gray-200);
  height: 1px;
}

.text {
  line-height: 20px;
  font-weight: 500;
}

.contentDivider {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.avatarOnlineIndicator {
  position: absolute;
  right: -1.5px;
  bottom: -1.5px;
  border-radius: var(--br-8xs);
  background-color: var(--success-500);
  border: 1.5px solid var(--base-white);
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  overflow: hidden;
}

.avatar {
  position: relative;
  border-radius: var(--br-9xl);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: var(--br-181xl);
  object-fit: cover;
}

.text1 {
  flex: 1;
  line-height: 20px;
  font-weight: 500;
}

.subText,
.text1,
.text2 {
  position: relative;
}

.subText {
  font-size: var(--text-xs-medium-size);
  line-height: 18px;
  color: var(--gray-600);
}

.text2 {
  flex: 1;
  line-height: 24px;
}

.content,
.input,
.message,
.row {
  display: flex;
  justify-content: flex-start;
}

.input {
  align-self: stretch;
  border-radius: 0 var(--br-5xs) var(--br-5xs) var(--br-5xs);
  background-color: var(--gray-100);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: center;
  font-size: var(--text-md-semibold-size);
  color: var(--gray-900);
}

.content,
.message,
.myRow,
.row {
  align-items: flex-start;
}

.content {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-6);
}

.message,
.myRow,
.row {
  flex-direction: row;
}

.message {
  gap: var(--gap-xs);
}

.row {
  align-self: stretch;
}

.myRow {
  align-self: flex-end;
}

.myRow .input {
  border-radius: var(--br-5xs) 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--primary-color);
  color: white;
}

.fileUploadIcon {
  position: relative;
  width: 36px;
  height: 36px;
}

.supportingText,
.text6 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.text6 {
  font-weight: 500;
}

.supportingText {
  color: var(--gray-600);
}

.textAndSupportingText {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.content3,
.content4,
.input2,
.input3,
.textAndSupportingText {
  display: flex;
  justify-content: flex-start;
}

.content4 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.content3,
.input2,
.input3 {
  flex-direction: row;
}

.content3 {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-base);
}

.input2,
.input3 {
  align-self: stretch;
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: center;
}

.input2 {
  border-radius: 0 var(--br-5xs) var(--br-5xs) var(--br-5xs);
  background-color: var(--base-white);
  border: 1px solid var(--gray-200);
}

.input3 {
  border-radius: var(--br-5xs) 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--gray-800);
  font-size: var(--text-md-semibold-size);
  color: var(--base-white);
}

.message3,
.row3,
.section {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.message3 {
  width: 330px;
}

.row3,
.section {
  align-self: stretch;
}

.section {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: left;
  color: var(--gray-700);
  overflow-y: auto;
  padding: var(--padding-32xl) var(--padding-32xl) 0 var(--padding-32xl);
}

.emoji,
.emojiIcon {
  width: 16px;
  height: 16px;
}

.emojiIcon {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.emoji {
  position: relative;
}

.messageReaction {
  border-radius: var(--br-base);
  background-color: var(--gray-50);
  height: 24px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-11xs) var(--padding-5xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  mix-blend-mode: multiply;
}

.message6,
.row7 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.row7 {
  align-self: stretch;
  align-items: center;
  gap: var(--gap-9xs);
}

.message6 {
  width: 442px;
  align-items: flex-start;
}

.loadingDotIcon {
  position: relative;
  width: 4px;
  height: 4px;
}

.loadingDotFrame {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.loadingDotFrame1 {
  height: 8px;
  align-items: flex-start;
  justify-content: center;
}

.input7,
.loadingDotFrame1,
.loadingDotFrame2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.loadingDotFrame2 {
  height: 8px;
  align-items: flex-end;
  justify-content: center;
}

.input7 {
  border-radius: 0 var(--br-5xs) var(--br-5xs) var(--br-5xs);
  background-color: var(--gray-100);
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.container,
.message7 {
  display: flex;
  align-items: flex-start;
}

.message7 {
  width: 560px;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.container {
  overflow: hidden;
  align-self: stretch;
  flex: 1;
  flex-direction: column;
  padding: 0 0 var(--padding-32xl) var(--padding-32xl);
  justify-content: flex-end;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-600);
  font-family: var(--text-md-semibold);
  width: 100%;
}

@media screen and (max-width: 600px) {
  .text2 {
    font-size: 12px;
  }

  .container {
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px var(--padding-16) 0 var(--padding-16);
  }

  .section {
    padding: 0;
  }

  .input {
    padding: 6px 10px;
  }

}
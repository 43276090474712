.chevronLeftIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.button {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs);
    align-items: center;
    justify-content: center;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.month {
    width: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--text-md-regular-size);
}

.label,
.text1 {
    position: relative;
}

.label {
    line-height: 20px;
    font-weight: 500;
    display: none;
}

.text1 {
    flex: 1;
    line-height: 24px;
}

.content1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: none;
}

.input,
.inputWithLabel {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField {
    width: 128px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-6);
}

.text2 {
    position: relative;
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-500);
}

.inputFields {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    text-align: left;
}

.number {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 12px);
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: 24px;
}

.calendarCell {
    position: relative;
    border-radius: var(--br-xl);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.day {
    width: 280px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.number7 {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 12px);
    line-height: 20px;
    display: inline-block;
    width: 24px;
}

.dotIcon {
    position: absolute;
    bottom: 4px;
    left: calc(50% - 2.5px);
    width: 5px;
    height: 5px;
}

.calendarCell13 {
    position: relative;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: var(--gray-700);
}

.calendarCell13,
.calendarCell18,
.row {
    border-radius: 50%;
}

.row {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--gray-500);
}

.calendarCell18 {
    position: relative;
    background-color: var(--error-700);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    color: var(--base-white);
}

.connectionIcon {
    position: absolute;
    top: 0;
    left: -20px;
    width: 80px;
    height: 40px;
}

.calendarCell19 {
    position: relative;
    background-color: var(--gray-50);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.row1 {
    border-radius: var(--br-xl);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.connectionIcon2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 40px;
}

.calendarCell36 {
    position: relative;
    border-radius: var(--br-xl);
    background-color: var(--gray-100);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.cursorIcon {
    position: absolute;
    height: 50%;
    width: 50.94%;
    top: 50%;
    right: -10%;
    bottom: 0;
    left: 59.06%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.calendarCell40 {
    position: relative;
    border-radius: var(--br-xl);
    background-color: var(--gray-50);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}

.calendar,
.content,
.dates {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dates {
    gap: var(--gap-9xs);
}

.calendar,
.content {
    align-self: stretch;
}

.calendar {
    gap: var(--gap-xs);
}

.content {
    padding: var(--padding-xl) var(--padding-5xl);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.text4 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.button2,
.button3 {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button3 {
    background-color: var(--error-700);
    border: 1px solid var(--error-700);
    color: var(--base-white);
}

.actions,
.bottomPanel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.actions {
    flex: 1;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.bottomPanel {
    align-self: stretch;
    border-top: 1px solid var(--gray-200);
    padding: var(--padding-base);
    justify-content: flex-end;
    text-align: left;
}

.datePicker,
.datePickerMenu {
    display: flex;
    justify-content: flex-start;
}

.datePicker {
    width: 328px;
    flex-shrink: 0;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 1000;
    left: var(--padding-32xl) !important;
    top: 50px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
}

.datePickerMenu {
    position: absolute;
    top: 328px;
    right: 1000px;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xl);
    border: 1px solid var(--gray-100);
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-700);
    font-family: var(--text-md-regular);
    z-index: 1000;
}
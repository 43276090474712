.password-reset {
    min-height: 920px;
}

.password-reset .standalone {
    display: flex;
    justify-content: center;
}

.password-reset .standalone .entry {
    background-color: #fff;
}

.password-reset .user-session-package.entry {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.password-reset .email-reset .signing-entry {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1;
}

.password-reset .email-reset form {
    width: 100%;
}

.password-reset .user-session-package.entry .reset-password-form {
    padding: 0 36px 24px;
}

.password-reset .email-reset .form-header {
    padding: 32px 0;
    text-align: center;
}

.password-reset .email-reset .form-header h4 {
    color: #404145;
}

.password-reset h4,
.password-reset .text-display-4 {
    font-size: 24px;
    line-height: 130%;
}

.password-reset .field {
    padding-bottom: 16px;
    position: relative;
}

.password-reset .field .field-input-wrapper {
    display: block;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}

.password-reset input.pw-reset-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #c5c6c9;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font: inherit;
    color: #404145;
    -webkit-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.password-reset .email-reset .field .hint {
    padding-top: 8px;
}

.password-reset .email-reset .submit-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.password-reset .pw-reset-btn {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 12px 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    -o-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    transition: 70ms cubic-bezier(.75, 0, .25, 1);
}

/* reset password styling */

.password-reset .wrap-reset {
    display: block;
    padding-top: 120px;
    width: 100%;
}

.password-reset .wrap-form {
    max-width: 500px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 0 8px #95979d;
}

.email-reset.wrap {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    border-radius: 4px;
    max-height: 90vh;
    max-width: 500px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    margin: 0 auto;
    align-items: center;

}

.email-reset .body {
    width: 400px;
    padding: 0 36px 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    padding-top: 0;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
}

.email-reset .close-button {
    width: 20px;
    height: 20px;
    right: 24px;
    top: 24px;
    position: absolute;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    fill: #74767e;
}

.email-reset form {
    width: 100%;
}

.email-reset .form-header {
    padding: 22px 0;
    padding-bottom: 12px;
    text-align: center;
}

.email-reset .form-header h4 {
    color: #404145;
    font-size: 24px;
    line-height: 130%;
}

.email-reset .form-header p {
    color: #74767e;
    padding-top: 8px;
}

.email-reset .field {
    padding-bottom: 16px;
    position: relative;
}

.email-reset .field .field-input-wrapper {
    display: block;
    font-size: 16px;
    line-height: 24px;
    position: relative;
}

.email-reset input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #c5c6c9;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font: inherit;
    color: #404145;
    -webkit-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.email-reset .submit-email {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 12px 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    -o-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    transition: 70ms cubic-bezier(.75, 0, .25, 1);
    justify-content: center;
    width: 100%;
    display: flex;
}

.email-reset .modal-content-footer {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #fff;
    border-top: 1px solid #dadbdd;
    padding: 15px;
}

.email-reset .signing-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1;
}

@media screen and (max-width: 600px) {
    .email-reset .body {
        max-width: 100%;
    }

    .password-reset .wrap-form {
        box-shadow: unset;
    }
}
.supportingText,
.text {
  align-self: stretch;
  position: relative;
}

.text {
  line-height: 28px;
  font-weight: 600;
}

.supportingText {
  font-size: var(--text-sm-regular-size);
  line-height: 20px;
  color: var(--gray-600);
}

.textAndSupportingText {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.text1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.button,
.button1,
.button2,
.button3 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.button {
  display: flex;
  color: var(--gray-600);
}

.button1,
.button2,
.button3 {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-50);
  display: none;
}

.button2,
.button3 {
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
}

.button3 {
  background-color: var(--primary-600);
  border: 1px solid var(--primary-600);
  color: var(--base-white);
}

.actions,
.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.actions {
  align-items: center;
  gap: var(--gap-xs);
  font-size: var(--text-sm-regular-size);
  color: var(--gray-700);
}

.content {
  align-self: stretch;
  border-top: 8px solid var(--blue-900);
  padding: var(--padding-9xl) var(--padding-5xl) 0;
  align-items: flex-start;
  gap: var(--gap-base);
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.cardHeader,
.form,
.table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.cardHeader {
  background-color: var(--base-white);
  width: 100%;
  gap: var(--gap-xl);
  font-size: var(--text-lg-semibold-size);
}

.form,
.table {
  align-self: stretch;
}

.table {
  border-radius: var(--br-xs);
  background-color: var(--base-white);
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border: 1px solid var(--gray-200);
  overflow: hidden;
}

.form {
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-900);
  font-family: var(--text-md-semibold);
  gap: 20px;
}

.noListings {
  font-size: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .content {
    padding: 10px;
  }

  .cardHeader {
    max-width: 100vw;
    gap: 10px;
  }

  .divider {
    align-self: stretch;
    flex-shrink: 0;
  }


  .divider {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 2px;
    background-color: var(--gray-200);
  }

  .form,
  .table {
    max-width: 100%;
  }

}
.categoryIcon {
  position: relative;
  width: 98px;
  height: 98px;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
}

.playWithOther {
  margin: 0;
}

.text {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.supportingText {
  position: relative;
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  color: var(--gray-600);
  display: inline-block;
  width: 399px;
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text1 {
  position: relative;
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  font-weight: 600;
  font-family: var(--text-md-regular);
  color: var(--base-white);
  text-align: left;
}

.arrowNarrowRightIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.button,
.content {
  display: flex;
  justify-content: center;
}

.button {
  cursor: pointer;
  border: 1px solid var(--error-700);
  padding: var(--padding-3xs) var(--padding-lg);
  background-color: var(--error-700);
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.button:hover,
.button1:hover {
  background-color: #b42218de;
}

.content {
  top: 162px;
  left: 197px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-2xl);
}

.contentWrapper {
  top: 52px;
  left: 0;
  background-color: var(--color-seashell);
  height: 558px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.cardHeroParent {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.cardHeroChild,
.cardHeroChild2 {
  border-radius: 11.63px;
  background-color: var(--error-700);
  width: 310.98px;
  height: 362.94px;
  transform: rotate(-15deg);
  transform-origin: 0 0;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.cardHeroChild2 {
  background-color: var(--primary-color);
  transform: rotate(15deg);
  transform-origin: 0 0;
  margin-top: 0;
}

.backgroundIcon {
  position: absolute;
  top: -80.49px;
  left: 0;
  width: 394.32px;
  height: 431.06px;
}

.frameChild {
  position: relative;
  width: 36.01px;
  height: 36.01px;
  flex-shrink: 0;
  object-fit: cover;
}

.markDeleway,
.scratchmaker {
  position: relative;
  font-size: var(--text-tiny-very-small-bold-desktop-size);
  line-height: 14px;
  font-family: var(--text-md-regular);
  color: var(--nutral-10);
  text-align: left;
}

.markDeleway {
  color: var(--base-white);
}

.ellipseParent,
.scratchmakerParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.ellipseParent {
  flex-direction: row;
  align-items: center;
  gap: 12.41px;
}

.frameParent,
.memberSinceParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.frameParent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-46xl);
}

.frameBottom {
  align-items: flex-end;
}

.frameGroup {
  top: 229.27px;
  left: 91.21px;
  width: 260.51px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 75.23px;
}

.saving {
  top: 95.15px;
  left: 57px;
  font-size: var(--heading-h1-desktop-size);
  line-height: 84px;
  font-weight: 600;
  font-family: var(--text-md-regular);
  text-align: left;
  opacity: 0.6;
}

.cardHero1,
.cardHeroItem {
  top: 209.04px;
  left: 105.29px;
  width: 250.4px;
  height: 67.79px;
}

.cardHero1 {
  top: 0;
  left: 0;
  width: 310.98px;
  height: 362.94px;
}

.groupChild {
  position: absolute;
  height: 122.35%;
  width: 71.28%;
  top: -35.39%;
  right: 7.88%;
  bottom: 13.04%;
  left: 20.84%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
  position: absolute;
  height: 80.19%;
  width: 46.67%;
  top: -14.4%;
  right: 20.2%;
  bottom: 34.21%;
  left: 33.13%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
  height: 122.45%;
  width: 71.29%;
  top: -35.44%;
  right: 7.88%;
  bottom: 12.99%;
  left: 20.83%;
  object-fit: cover;
}

.layer2Copy,
.rectangleIcon1,
.rectangleIcon2 {
  height: 14.89%;
  width: 15.37%;
  top: 25.06%;
  right: 50.78%;
  bottom: 60.05%;
  left: 33.85%;
}

.layer2Copy,
.rectangleIcon2 {
  height: 72.16%;
  width: 97.58%;
  top: -5.6%;
  right: -5.33%;
  bottom: 33.44%;
  left: 7.74%;
}

.layer2Copy {
  height: 79.99%;
  width: 102.15%;
  top: -9.49%;
  right: -7.6%;
  bottom: 29.49%;
  left: 5.46%;
}

.rectangleParent {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cardHeroInner {
  height: 9.15%;
  width: 18.33%;
  top: -12.88%;
  right: 4.5%;
  bottom: 103.73%;
  left: 77.17%;
}

.cardHero,
.rectangleDiv {
  width: 310.98px;
  height: 362.94px;
}

.rectangleDiv {
  top: 0;
  border-radius: 11.63px;
  background-color: var(--gray-cool-800);
  transform: rotate(15deg);
}

.backgroundIcon1,
.groupItem {
  top: 0;
  left: -93.94px;
  width: 394.32px;
  height: 431.06px;
}

.groupItem {
  height: 99.9%;
  width: 58.2%;
  top: 0.05%;
  right: 20.23%;
  bottom: 0.05%;
  left: 21.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.layer2Copy1,
.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5,
.vectorIcon1 {
  height: 80.18%;
  width: 46.65%;
  top: 9.81%;
  right: 26.01%;
  bottom: 10.01%;
  left: 27.34%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.layer2Copy1,
.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5 {
  height: 100%;
  width: 58.2%;
  top: 0;
  right: 20.23%;
  bottom: 0;
  left: 21.57%;
  object-fit: cover;
}

.layer2Copy1,
.rectangleIcon4,
.rectangleIcon5 {
  height: 8.72%;
  width: 14.55%;
  top: 45.49%;
  right: 57.51%;
  bottom: 45.79%;
  left: 27.94%;
}

.layer2Copy1,
.rectangleIcon5 {
  height: 30.43%;
  width: 96.28%;
  top: 39.35%;
  right: 1.85%;
  bottom: 30.23%;
  left: 1.87%;
}

.layer2Copy1 {
  height: 36.82%;
  width: 100%;
  top: 36.17%;
  right: 0;
  bottom: 27.01%;
  left: 0;
}

.rectangleGroup {
  height: 9.15%;
  width: 18.33%;
  top: 22.87%;
  right: 8.01%;
  bottom: 67.98%;
  left: 73.66%;
}

.frameItem {
  position: relative;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  object-fit: cover;
}

.frameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-46xl);
}

.educate,
.frameContainer,
.frameDiv {
  transform: rotate(15deg);
  transform-origin: 0 0;
}

.frameDiv {
  width: 259.31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-44xl);
}

.lineIcon {
  position: absolute;
  top: 291.73px;
  left: -47.06px;
  width: 250.4px;
  height: 67.79px;
}

.cardHero2,
.cardHero3 {
  width: 310.98px;
  height: 362.94px;
  display: flex;
  flex-direction: column;
}

.cardHero2 {
  left: 334.94px;
}

.chooseAndCreateInner {
  width: 636px;
  height: 501px;
}

.chooseAndCreate {
  position: relative;
  background-color: var(--base-white);
  height: 610px;
  flex-shrink: 0;
  text-align: left;
  font-size: var(--font-size-17xl);
  color: var(--gray-900);
  font-family: var(--text-md-regular);
  justify-content: center;
  align-self: stretch;
  display: flex;
  background-color: var(--color-seashell);
}

/* Cards */
.indexCardWrapper {
  position: static;
  left: 0px;
  display: flex;
  overflow: visible;
  margin-left: 0px;
  padding-left: 0px;
  border-radius: 8px;
  transform: translate(0px, 0px);
}

.indexCardWrapper.scratchmaker-cards {
  transform: translate(0px, -60px);
}

.header20_image {
  width: 100%;
  height: 100%;
}

.sectionModule2 {
  overflow: visible;
}

.sectionModule2.backgroundColorFFF0ED {
  background-color: #fff0ed;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.topContent {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex: 0 auto;
  grid-column-gap: 63px;
  grid-row-gap: 63px;
}

.userDetails {
  display: flex;
  width: 132.42401123046875px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.scratchCardUserDetails {
  font-family: Inter, sans-serif;
  color: #e8e8e9;
  font-size: 0.8rem;
  line-height: 14px;
  font-weight: 400;
}

.scratchCardUserDetails.bold {
  font-weight: 700;
}

.scratchmakerIcon {
  display: flex;
  padding: 7px 0px;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.scratchmakerLogoIcon {
  display: flex;
  width: 100%;
  height: 32.5992546081543px;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -o-object-fit: cover;
  object-fit: cover;
}

.divider {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
}

.cardDivider {
  display: flex;
  width: 100%;
  height: 1px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #98a2b3;
}

.cardDivider.white {
  background-color: #f9fafb;
}

.bottomContent {
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  grid-column-gap: 65px;
  grid-row-gap: 65px;
}

.avatarContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.avatar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.textContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.membershipDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 0 0 auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.cardImageContainer {
  display: flex;
  width: 100%;
  height: 197px;
  padding: 0px;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-radius: 16px;
  -o-object-fit: cover;
  object-fit: cover;
}

.blueCard {
  position: relative;
  left: -70px;
  display: flex;
  width: 100%;
  height: 380px;
  max-width: 337px;
  padding: 26px 26px 18px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 16px;
  background-color: #30374f;
}

.blueCard.rotation15 {
  left: -30px;
  flex: 0 0 auto;
  transform: rotate(15deg) translate(0px, -50px);
}

.redCard {
  position: relative;
  left: 70px;
  display: flex;
  width: 100%;
  height: 380px;
  max-width: 337px;
  padding: 26px 26px 18px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-radius: 16px;
  background-color: #b42318;
}

.redCard.rotationNeg15 {
  flex: 0 0 auto;
  transform: rotate(-15deg);
}

.paddingSectionMedium {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.indexImageWrapper {
  position: static;
  left: 0px;
  display: flex;
  overflow: hidden;
  margin-left: 0px;
  padding-left: 0px;
  border-radius: 8px;
  transform: translate(0px, 0px);
}

@media screen and (max-width: 600px) {
  .contentWrapper {
    flex-direction: column;
    height: unset;
    overflow: unset;
    padding: 20px;
    gap: var(--gap-36);
  }

  .chooseAndCreate {
    height: unset;
  }

  .content,
  .supportingText {
    width: 100%;
  }

  .indexCardWrapper {
    width: 100%;
    height: 330px;
  }

  .redCard,
  .blueCard {
    left: 0;
    width: 50%;
    padding: 15px 15px 18px;
    grid-row-gap: 8px;
    height: 270px;
  }

  .scratchCardUserDetails {
    font-size: 0.7rem;
  }

  .cardImageContainer {
    height: 120px;
  }

  .blueCard.rotation15 {
    left: -50px;
    top: 60px;
  }

  .redCard.rotationNeg15 {
    left: 20px;
    top: 0px;
  }
  .membershipDetails {
    display:none;
  }
}
:root {
    --label-width: 200px;
}

.trophyIcon {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}


.award05 {
    height: 72.62%;
    width: 72.62%;
    top: 14.29%;
    right: 13.09%;
    bottom: 13.09%;
    left: 14.29%;
}

.event {
    position: relative;
    border-radius: var(--br-3xs);
    border: 2px solid var(--secondary-color);
    width: 65px;
    height: 65px;
    padding: 2px;
}

.deleteIcon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: var(--secondary-color);
    font-size: 20px;
    transition: opacity 0.3s;
}

.deleteIcon:hover {
    opacity: 0.7;
}

.supportingText,
.text {
    align-self: stretch;
    position: relative;
}

.text {
    line-height: 28px;
    font-weight: 600;
}

.fileInput {
    display: none;
}

.supportingText {
    font-size: var(--text-sm-regular-size);
    line-height: 20px;
    color: var(--gray-600);
}

.content,
.textAndSupportingText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-9xs);
}

.content {
    align-self: stretch;
    background-color: var(--base-white);
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-5xl) 0;
    gap: var(--gap-base);
    z-index: 0;
}

.xCloseIcon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.buttonCloseX {
    margin: 0 !important;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}

.paddingBottom {
    align-self: stretch;
    height: 20px;
    z-index: 2;
}

.dividerIcon,
.modalHeader {
    align-self: stretch;
    position: relative;
}

.dividerIcon {
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
    z-index: 3;
}

.modalHeader {
    background-color: var(--base-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: var(--gray-900);
}

.text1 {
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: var(--label-width);
    flex-shrink: 0;
}

.label,
.text1,
.inputText {
    position: relative;
}

.label {
    line-height: 20px;
    font-weight: 500;
    display: none;
}

.inputText {
    flex: 1;
    line-height: 24px;
}

.inputText {
    width: 100%;
    border: none;
    font-size: 18px;
}

.inputText {
    color: var(--primary-color);
}

option {
    color: var(--primary-color);
}

.inputText:focus-visible {
    border: none;
    outline: none;
}

.textarea {
    width: 100%;
    border: none;
    font-size: 14px;
    resize: none;
}

.textarea:focus-visible {
    border: none;
    outline: none;
}

.content2 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    display: none;
}

.input,
.inputWithLabel {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-semibold-size);
    color: var(--gray-500);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
    width: 100%;
}

.hintText {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField,
.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputField {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-6);
}

.row {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-32xl);
}

.searchLgIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text4 {
    position: relative;
    line-height: 24px;
}

.content3,
.courseSearch {
    flex: 1;
    align-items: center;
}

.content3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.courseSearch {
    align-self: stretch;
    border-radius: var(--br-5xs) var(--br-5xs) 0 0;
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    padding: var(--padding-3xs) var(--padding-sm);
    font-size: var(--text-md-semibold-size);
    color: var(--gray-500);
    position: relative;
}

.searchResults {
    position: absolute;
    top: calc(100%);
    left: -1px;
    width: calc(100% + 2px);
    background: white;
    z-index: 1000;
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    /* border-radius: 0 0 var(--br-5xs) var(--br-5xs); */
    border-radius: 0 0 var(--br-5xs) var(--br-5xs);
}

.searchResultItem {
    line-height: 30px;
    box-shadow: var(--shadow-xs);
    cursor: pointer;
    padding-left: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
}

.searchResultItem:hover {
    color: white;
    background-color: var(--secondary-color);
}

.errorText {
    color: red;
    font-size: 14px;
    margin-top: 4px;
}

.manualAlert {
    background-color: var(--gray-300);
    font-size: var(--text-sm-semibold-size);
    line-height: 30px;
    border-radius: 0 0 var(--br-5xs) var(--br-5xs);
}

.manualAlert span {
    color: var(--secondary-color);
    font-weight: 600;
    cursor: pointer;
}

.addOn,
.courseSearch,
.input2,
.textInput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.addOn {
    border-radius: var(--br-5xs) 0 0 var(--br-5xs);
    padding: var(--padding-3xs) var(--padding-xs) var(--padding-3xs) var(--padding-sm);
    align-items: center;
    border-right: 2px solid var(--gray-300);
}

.input2,
.textInput {
    align-self: stretch;
    background-color: var(--base-white);
}

.input2 {
    border: 1px solid var(--gray-300);

}

.textInput {
    flex: 1;
    border-radius: 0 var(--br-5xs) var(--br-5xs) 0;
    overflow: hidden;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
}

.input2 {
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    font-size: var(--text-md-semibold-size);
    color: var(--gray-500);
}

.dividerIcon1,
.text10 {
    align-self: stretch;
    position: relative;
}

.dividerIcon1 {
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.text10 {
    flex: 1;
    line-height: 24px;
}

.uploadText {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    color: var(--secondary-color)
}

.input4,
.inputWithLabel3,
.textareaInputField {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.input4 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-sm);
    font-size: var(--text-md-semibold-size);
    color: var(--gray-500);
}

.inputWithLabel3,
.textareaInputField {
    flex-direction: column;
    gap: var(--gap-6);
}

.inputWithLabel3 {
    align-self: stretch;
}

.text11 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    width: var(--label-width);
}

.fileUploadContent,
.textAndSupportingText1 {
    align-self: stretch;
    align-items: center;
}

.textAndSupportingText1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.fileUploadContent {
    gap: var(--gap-xs);
    cursor: pointer;
}

.uploadSubtext {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.supportingText1 {
    position: relative;
    font-size: var(--text-xs-medium-size);
    line-height: 18px;
    color: var(--gray-600);
}

.textParent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.form {
    align-self: stretch;
    gap: var(--gap-base);
}

.content1,
.dividerWrap,
.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content1 {
    padding: var(--padding-xl) var(--padding-5xl) 0;
    box-sizing: border-box;
    font-size: var(--text-sm-regular-size);
    margin-bottom: 10px;
}

.dividerWrap {
    align-self: stretch;
    padding: 0 0 var(--padding-5xl);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text17 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.button,
.button1 {
    flex: 1;
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button {
    background-color: var(--base-white);
    border: 1px solid var(--gray-300);
}

.button1 {
    background-color: var(--gray-200);
    color: var(--gray-400);
}

.content4,
.modalActions,
.outingModal {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}


.avatarAndFileUpload,
.fileUploadContent,
.fileUpload,
.fileUploadBase {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.fileUploadBase {
    align-self: stretch;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    border: 1px solid var(--gray-200);
    padding: var(--padding-base) var(--padding-5xl);
    align-items: center;
    position: relative;
}

.uploadedImage {
    max-height: 250px;
    max-width: 100%;
}

.avatarAndFileUpload,
.fileUpload {
    flex: 1;
    align-items: flex-start;
    width: 100%;
}

.avatarAndFileUpload {
    flex-direction: column;
    color: var(--error-800);
}

.action {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
}

.content4 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-5xl) var(--padding-5xl);
    gap: var(--gap-xs);
}

.uploadButton {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.modalActions,
.outingModal {
    flex-direction: column;
}

.modalActions {
    padding: var(--padding-32xl) 0 0;
    box-sizing: border-box;
    font-size: var(--text-md-semibold-size);
}

.outingModal {
    position: relative;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xl);
    width: 100%;
    overflow: hidden;
    text-align: left;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-700);
    font-family: var(--text-md-semibold);
    padding-bottom: 100px;
    max-height: 80vh;
}


.cancelButton,
.nextButton {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    cursor: pointer;
    transition: 200ms;
}

.nextButton {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--base-white);
}

.nextButton:hover {
    opacity: 0.8;
    box-shadow: var(--shadow-xs);
}

.nextButton.disabled {
    background-color: #dfdfdf;
    color: black;
    border: 1px solid #b4b4b4;
    cursor: unset;
}

.nextButton.disabled:hover {
    opacity: 1;
    box-shadow: unset;
}

.cancelButton:hover {
    border: 1px solid var(--gray-600);
}

.textarea {
    height: 100px;
    width: 100%;
}

.markerPin02Icon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.wrapCourse {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-self: stretch;
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.toggleBase {
    border-radius: var(--br-xs);
    background-color: var(--error-700);
    width: 44px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
}

.toggleBase label {
    /* Toggle switch appearance */
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggleBase label::after {
    /* Handle appearance */
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #fff;
    top: 2px;
    left: 2px;
    transition: left 0.3s;
}

.toggleBase input[type="checkbox"]:checked+label {
    /* Styling when the toggle is checked */
    background-color: var(--secondary-color);
}

.toggleBase input[type="checkbox"]:checked+label::after {
    /* Handle position when the toggle is checked */
    left: 22px;
}

.toggleBase input {
    display: none;
}

.content1 {
    flex-grow: 1;
    overflow-y: auto;
}

.modalActions {
    position: absolute;
    bottom: 0;
    padding: 0;
    background-color: white;
    align-items: center;
    border-top: 1px solid #e1e1e1;
}

/* Styling the scrollbar for Chrome, Safari and Opera */
.content1::-webkit-scrollbar {
    width: 10px;
}

.content1::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content1::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    outline: 1px solid slategrey;
}


@media screen and (max-width: 600px) {
    .content1 {
        padding: 0px 10px 10px 10px;
        margin-top: 10px;
    }

    .text {
        font-size: 16px;
        line-height: 20px;
    }

    .supportingText {
        font-size: 12px;
    }

    .row {
        flex-direction: column;
    }

    .inputText {
        font-size: 12px;
    }

    .inputField {
        width: 100%;
    }

    .searchResultItem {
        line-height: 24px;
        font-size: 12px;
    }

    .textareaInputField {
        width: 100%;
    }

    .textarea {
        font-size: 12px;
    }

    .textParent {
        max-width: unset;
        font-size: 12px;
    }

    .row {
        gap: 10px;
    }

    .inputWithLabel {
        font-size: 12px;
    }

    .text11 {
        font-size: 14px;
    }

    .content {
        padding: 10px;
        max-width: 90%;
    }

    .buttonCloseX {
        right: 0px;
        top: 0px;
    }

    .outingModal {
        max-height: unset;
    }
}
.supportingText,
.text {
  align-self: stretch;
  position: relative;
}

.text {
  line-height: 30px;
  font-weight: 600;
  font-size: 30px;
}

.supportingText {
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  color: var(--gray-200);
}

.textAndSupportingText {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.label,
.text1 {
  position: relative;
}

.label {
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.text1 {
  flex: 1;
  line-height: 24px;
  width: 100%;
  border: none;
}

.text1:focus-visible {
  outline: none;
}

.content1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.helpIcon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: none;
}

.input,
.inputWithLabel {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.input {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
  color: var(--gray-500);
}

.inputWithLabel {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6);
}

.hintText {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--gray-600);
  display: none;
}

.inputField {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
  font-size: 24px;
}

.button {
  border-radius: var(--br-5xs);
  background-color: var(--error-700);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--error-700);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-sm) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  font-size: var(--text-md-regular-size);
  color: var(--base-white);
}

.emailCapture {
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-base);
  font-size: var(--text-sm-semibold-size);
  color: var(--gray-900);
}

.container,
.content,
.section {
  display: flex;
  justify-content: center;
}

.content {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-32);

}

.container,
.section {
  flex-direction: column;
}

.container {
  width: 1440px;
  padding: 0 var(--padding-32xl);
  box-sizing: border-box;
  align-items: center;
}

.section {
  align-self: stretch;
  background-color: var(--gray-900);
  padding: var(--padding-29xl) 0 var(--padding-29xl) 0;
  align-items: center;
  text-align: left;
  font-size: var(--text-xl-semibold-size);
  color: var(--base-white);
  font-family: var(--text-md-regular);
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%
  }

  .content {
    flex-direction: column;
  }

  .emailCapture {
    width: 50%;
    flex-direction: column;
  }

  .inputField {
    width: 100%;
  }

  .text1 {
    font-size: 18px;
  }

  .button {
    width: 100%;
  }
}
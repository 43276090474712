* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;

    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */

    cursor: pointer;
}

html {
    font-family: 'Nunito Sans', sans-serif;
}

.checkout {
    /* height: 100vh; */
    min-height: 1230px;
}

.checkout .container {
    max-width: 1170px;
    margin: auto;
    height: 100%;
    padding: 0 20px;
}

.personalHead {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.personalHead h3 {
    padding-bottom: 15px;
}

.personalHead.total {
    padding-top: 10px;
    font-size: 20px;
}

.inputSection {
    display: flex;
    gap: 15px;
}


/* hero section*/
.checkout .header {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 70px;
    box-shadow: inset 0px -19px 39px rgba(0, 0, 0, 0.7);
}

.checkout .header h1 {
    font-family: 'Nunito Sans', cursive;
    font-size: 4em;
    font-weight: 400;
    animation: paralex 1s;
}

.checkout .headerBtns {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 67px;
    justify-content: center;
    animation: paralex 1s;
}

.checkout .headerBtn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    background: #FFFFFF;
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 15px;
    color: black;
    padding: 18px 40px;
    cursor: pointer;
    transition: 1s;
}

.checkout .headerBtn1:hover {
    transition: 1s;
    gap: 40px;
}



/* Card Detials Popup*/
.checkout .popupCardDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 15px;
    max-width: 450px;
    height: fit-content;
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #eceff1;
    border-right: 1px solid #eceff1;
    border-left: 1px solid #eceff1;
    padding: 15px;
}


.checkout .popBasicCardInfo {
    display: flex;
    flex-direction: row;
    padding: 0px;
    gap: 20px;
    width: 100%;
}

.checkout .popBasicCardInfo img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

.checkout .checkoutSection {
    border-bottom: 1px solid #eceff1;
    border-right: 1px solid #eceff1;
    border-left: 1px solid #eceff1;
}

.checkout .productDetailCardHeading1 {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-style: normal;
    font-weight: 800;
    font-size: 1.5em;
    line-height: 41px;
    color: #000000;
    flex-direction: column;
    gap: 10px;
}

.checkout .productDetailCardHeading1 h3 {
    font-size: 32px;
}

.checkout .productHeading4 {
    display: flex;
    gap: 8px;
    align-items: center;
}

.checkout .productHeading4 i {
    font-size: 1.1em;
    color: #09B051;
}

.checkout .productHeading4 p {
    font-weight: 500;
    font-size: 0.9em;
}

.checkout .productDetailCardHeading1 h3:nth-child(2) {
    color: var(--primary-color);
}

.checkout .golferCardstarsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
}

.checkout .productCardRating {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
}

.checkout .popCardBasicTags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    width: 100%;
}

.checkout .popupcardbasicinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 17px;
    height: 131.97px;
}

.checkout .popupCardAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
}

.checkout .popupCardAbout h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 1.3em;
    line-height: 34px;
}

.checkout .popupCardAbout p {

    font-style: normal;
    font-weight: 400;
    font-size: 0.9em;
    line-height: -1em;
    text-align: left;
}

.checkout .popupCardTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 10px;
    margin-top: 0px;
}

.checkout .hrLine {
    width: 0px;
    height: 160px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 10px;
}

.checkout .popupCardBigTags {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    margin-top: 20px;
}

.checkout .tag {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 35px;
    background: rgba(152, 152, 152, 0.2);
    border-radius: 300px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
}


.checkout .popupCardBigTags h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 1.3em;
    line-height: 34px;
    text-align: center;
    margin: 0 auto;
}

.checkout .BigTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    width: 90%;
    height: 40px;
    border-radius: 300px;
    margin: 0 auto;
    text-align: center;
    padding: 0.4rem;
    font-size: 0.9rem;
}



/* checkout section*/
.checkout .checkOutSection .container {
    display: flex;
    flex-direction: row;
    padding: 0px 2% 20px 2%;
    justify-content: space-between;
    margin-top: 20px;
}

.checkout .v-separator {
    height: 50vh;
    border-top: 1px solid #dadbdd;
    /* padding-top: 20px; */
    margin-top: 3%;
}

.checkout .checkOutSection .head {
    max-width: 1170px;
    padding: 10px 10px 10px 5px;
    left: 0;
    background-color: var(--primary-color);
    width: 100%;
}

.checkout .checkOutSection .head h1 {
    font-size: 1.5rem;
    color: white;
}

.checkout .stars i {
    color: #FFC700;
    font-size: 1.3em;
}

.checkout .calendarImg {
    height: 150px;
}

.checkout .checkOutInfoSection {
    width: 550px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.checkout table {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.checkout table th {
    padding: 0.4rem !important;
    height: 1rem;
    align-items: left;
    text-align: left;
    width: fit-content;
    color: white;
    background: #09B051;
    border: 1px solid rgba(255, 255, 255, 0.4);

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #FFFFFF;
}

.checkout table td {
    align-items: left;
    text-align: left;
    width: fit-content;
    padding: 0.4rem !important;
    height: 1rem;
    background: #F2F2F2;
    border: 1px solid rgba(255, 255, 255, 0.4);

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.checkout table tr:nth-child(3) td {
    padding: 10px 20px;
    align-items: left;
    text-align: left;
    width: fit-content;
    height: 31px;

    background: #F2F2F2;
    border: 1px solid rgba(255, 255, 255, 0.4);

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.line-item {
    margin-bottom: 0.6rem;
}

.checkout-button {
    width: auto !important;
    max-width: 60%;
    cursor: pointer;
    margin-top: 20px !important;
}

.changeStep {
    text-decoration: underline;
    cursor: pointer;
}

.personalInputFields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.personalInputFields input {
    padding: 0.7rem 1.2rem;
}

.checkout .h-separator {
    display: none;
}

.checkout input[type="checkbox"] {

    height: 24px;
    width: 24px;
    background: red;
    border: 2px solid #09B051;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;

}

.hideInfo label {
    display: flex;
    gap: 15px;
    pointer-events: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.personalInputFields label {
    display: flex;
    gap: 15px;
}

.checkout input[type="checkbox"]:after {
    width: 16px;
    height: 16px;

}

.checkout input[type="checkbox"]:checked {
    background-color: var(#09B051, #09B051);
}

.checkout .checkOutInfo {
    width: 100%;
    background: #FFFFFF;
    border-bottom: 1px solid #CDCDCD;
    padding: 20px 10px 20px 10px;
}

.checkout .checkOutInfo .tabDetails {
    width: 100%;
    padding-left: 20px;
}

.checkout .checkOutInfo.checkoutActiveTab {
    background: #FFFFFF;
    border: 1px solid #CDCDCD;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}

.checkout .checkOutInfo.checkoutActiveTab .tabDetails {
    display: block;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.checkout .matchDate {
    width: 25rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: 700;
    line-height: 1.5;
    outline: none;
    padding: 0.75rem 1rem;
}

.checkout .searchDiv {
    display: flex;
    flex-direction: column;
}

.checkout input {
    font-size: 16px;
}

.checkOutInfo .checkout-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 70%;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.checkout input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);

}

.checkout input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);
}

.checkout input::-ms-input-placeholder {
    /* Microsoft Edge */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);
}

.checkout .matchSearch {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 150.58px;
    height: 40px !important;
    background: #09B051;
    border-radius: 0px 10px 10px 0px !important;
    color: white;
}

.checkout .matchSearch:hover {
    cursor: pointer;
}

.checkout .match-information-summary {
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.checkout .match-information-summary p {
    max-width: 80%;
}

.checkout .greenBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 40%;
    align-items: center;
    margin: auto;
    height: 50px;
    background: var(--primary-color);
    border-radius: 10px;
    color: white;
}

.checkout .continue {
    cursor: pointer;
    margin-top: 1rem;
}

.tabDetails table td {
    font-size: 1rem;
}

.checkout .collapse-button,
.checkout .cover {
    display: none;
}

/* Footer */

.checkout .footerSection {
    width: 100%;
    background-image: url('../Assets/Images/footerBackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    position: fixed;
    left: 0px;
    bottom: 0px;
}

.checkout .poweredBy {
    width: auto;
    height: 100px;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    right: 0;
}

.checkout .footer {
    padding: 10px 0;
    position: relative;
}

.checkout .footer img {
    margin: auto;
    margin-bottom: 50px;
    margin-top: 20px;
}

.checkout .footer p {
    margin-top: 50px;
}


.checkout .footer ul a li {
    color: black;
    border: none;
}

.searchDiv {
    margin-bottom: 1rem;
}

.checkout #payment-message {
    color: red;
    text-align: center;
    font-size: 18px;
    padding-top: 10px;
}

.checkout #fourthStepMain .continue {
    font-size: 24px;
    border: 0;
}

.checkout #fourthStepMain .continue:hover {
    background-color: var(--secondary-color);
    transition: 500ms;
}

.checkout .google-search-div {
    margin-top: 15px;
}

/* DATE TIME PICKER */
.checkout .dt-picker {
    margin-top: 15px;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: var(--primary-color) !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}

.css-rjqbug-MuiButtonBase-root-MuiIconButton-root-MuiClock-pmButton,
.css-h2z9v1-MuiButtonBase-root-MuiIconButton-root-MuiClock-amButton,
.MuiClock-pin,
.MuiClockPointer-thumb,
.MuiClockNumber-root.Mui-selected,
.css-rdq5h4-MuiClockPointer-root,
.Mui-selected {
    background-color: var(--primary-color) !important;
}

.MuiClockPointer-thumb {
    border: 16px solid var(--primary-color) !important;
}

.MuiClock-pin,
.MuiClockPointer-thumb {
    background-color: black !important;
    z-index: 1000;
}

.MuiClockPointer-root {
    background-color: rgb(88, 88, 88) !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color) !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: rgb(0, 0, 0, 0.87) !important;
}

.Mui-error.MuiFormLabel-root {
    color: rgb(88, 88, 88) !important;
}

/* Golfer Section */
.checkoutSection .popBasicCardInfo {
    padding: 20px 10px 20px 10px;
}

.checkoutSection .about-wrapper {
    padding: 0px 10px 20px 10px;
}


/* Terms */
.checkoutSection .wrap-terms-acceptance {
    padding-top: 20px;
    display: flex;
    cursor: pointer;
}

.checkoutSection .wrap-terms-acceptance .cb-div {
    margin-right: 10px;
}

.checkoutSection .wrap-terms-acceptance .terms-label {
    font-size: 14px;
    align-items: center;
    display: flex;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.show {
    opacity: .5;
}

.modal-lg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
}

.modal-dialog {
    margin: 0 auto !important;
    pointer-events: auto !important;
    height: auto !important;
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .3rem;
    outline: 0;
    margin: 0 auto;
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}

.modal-lg,
.modal-xl {
    max-width: 800px;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.modal-header .btn-close {
    padding: .5rem;
    margin: -.5rem -.5rem -.5rem auto;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") 50%/1em auto no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
}

/* Responsive */

@keyframes paralex {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 100;
    }
}

@media only screen and (min-width: 1174px) {
    .checkout .burger {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1074px) {
    .checkout body {
        overflow-x: hidden;
    }

    .checkout .checkOutSection .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
    }

    .checkout #showcarddetailspopup {
        order: 2;
    }

    .checkout #b {
        order: 2;
    }
}

@media only screen and (max-width: 723px) {
    .checkout .productHeader {
        display: block;
        align-items: center;
    }

    .checkout .clientStories {
        height: 750px;
    }

    .checkout .header h1 {
        font-size: 5em;
    }

    .checkout #box-mobile {
        display: none;
        visibility: hidden;
    }

    .checkout .poweredBy {
        width: auto;
        height: 100px;
        z-index: 1000;
        position: static;
    }

    .checkout .header {
        padding-bottom: 10px;
    }

    .checkout .checkOutSection .container {
        padding-top: 80px;
    }

    .checkout .popBasicCardInfo {
        flex-direction: column;
        gap: 10px;
    }

    .checkout .popBasicCardInfo img {
        width: 150px;
        height: 150px;
    }

    .checkout .popupcardbasicinfo {
        gap: 5px;
        max-width: 80%
    }

    .checkout .stars {
        font-size: 1em;
    }

    .checkout .v-separator {
        display: none;
    }

    .checkout .popupCardDetails {
        gap: 5px;
    }

    .checkout .checkOutSection .head {
        max-width: 1170px;
        padding: 10px 10px 10px 5px;
        left: 0;
    }

    .google-search-div input {
        width: 300px;
        padding: 0.75rem 1rem;
    }

    .google-search-div ul {
        visibility: visible !important;
        display: block !important;
    }

    .google-search-div ul>li {
        cursor: pointer;
        padding: 0.75rem 1rem;
        width: 298px;
        border-bottom: 1px solid #dfdfdf
    }

    .checkout .popupCardAbout {
        max-height: 140px;
        overflow: hidden;
        padding-bottom: 10px;
    }

    .checkout .popupCardAbout.show {
        max-height: unset;
        overflow: hidden;
    }

    .checkout .about-wrapper {
        position: relative;
    }

    .checkout .cardDetailsPopup .cover {
        background: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .1)), color-stop(50%, #fff), to(#fff));
        background: -o-linear-gradient(hsla(0, 0%, 100%, .1) 0, #fff 50%, #fff 100%);
        background: linear-gradient(hsla(0, 0%, 100%, .1), #fff 50%, #fff);
        height: 40px;
        position: absolute;
        top: 100px;
        width: 100%;
        z-index: 1;
        display: block;
    }

    .checkout .cardDetailsPopup .popupCardAbout.show+.cover {
        display: none;
    }

    .checkout .collapse-button {
        background: #fff;
        border: 0;
        color: #446ee7;
        display: block;
        font-size: inherit;
    }

    .checkout .h-separator {
        width: 90vw;
        border-top: 1px solid #dadbdd;
        /* padding-top: 20px; */
        margin-top: 3%;
        display: flex;
        order: 2;
    }

    .personalHead {
        padding-bottom: 10px;
    }

    .changeStep {
        color: #446ee7;
    }

    .checkout-button {
        max-width: 80%;
    }

    #fourthStepMain {
        border-bottom: 0px;
    }

    .checkout .checkOutInfoSection {
        gap: 5px;
    }

    .checkout .productDetailCardHeading1 {
        text-align: center;
        align-items: center;
    }

    .checkout .productHeading4 {
        text-align: center;
    }

    .checkout .productHeading4 span {
        text-align: center;
        font-size: 24px;
    }

    .checkout .productHeading4 p {
        font-size: 24px;
    }
}

@media only screen and (max-width: 481px) {
    .checkout .testimonialBottom {
        display: block;
    }

    .checkout .stars {
        font-size: 1em;
    }

    .productCardRating p {
        font-size: 1.3em;
        color: #FFC700;
        font-weight: 500;
    }

    .checkout .popupCardAbout p {
        font-size: 1em;
    }

    .checkout .popupCardBigTags h4 {
        font-size: 1em;
    }

    .checkout .productCardRating {
        gap: 7px;
    }

    .checkout .productHeading4 i {
        font-size: 1.4em;
    }

    .checkout .productHeading4 p {
        font-size: 28px;
    }

    .checkout .tag {
        font-size: 14px;
    }

    .checkout .footer ul {
        display: flex;
        flex-direction: column;

    }

    .checkout .testimonialBottom .stars {
        margin-top: 20px;
    }

    .checkout .header h1 {
        font-size: 3em;
    }

    .checkout .headerBtns {
        flex-direction: column;
    }

    .checkout .faq_wrapper {
        display: grid;
        --auto-grid-min-size: 200px;
        grid-gap: 20px;
        grid-template-columns:
            repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }
}

@media only screen and (max-width: 621px) {
    .checkout .HowItWorksInnerStatistic {
        display: flex;
        flex-direction: column;
    }

    .checkout .clientStoriesWrapper {

        flex-direction: column;
    }

    .checkout .clientStories {
        height: 1200px;
    }

    .checkout .clientStoriesSection {
        padding: 0;
    }

    .checkout .HowItWorksInner {
        display: flex;
        flex-direction: column;
    }

    .checkout .HowItWorksInner .box {
        height: fit-content;
    }

    .checkout .checkOutInfoSection {
        max-width: 100%
    }
}

@media only screen and (max-width: 1174px) {
    .checkout .burger {
        display: block;
        visibility: visible;
    }

    .checkout .on-scroll li {
        cursor: pointer;
        transition: 1s;
        color: black;

    }

    .checkout .navbar.is-active {
        background-color: white;

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding-left: 20px;
        padding-right: 40px;
    }

    .checkout .navbar.is-active .navBtns2 {
        display: flex;
        visibility: visible;
    }

    .checkout .navbar.is-active .navBtns1 {
        display: flex;
        visibility: visible;
    }

    .checkout .footer ul {
        visibility: visible;
        display: flex;
        gap: 40px;
    }

    .checkout .navbar.is-active ul {
        display: flex;
        flex-direction: column;
        visibility: visible;
        text-align: center;
        margin: auto;
        width: 100vw;
    }

    .checkout .navbar.is-active .logo {
        position: absolute;
        top: 10px;
        left: 20px;
    }

    .checkout .navbar.is-active .burger {
        position: absolute;
        top: 53px;
        right: 40px;
    }

    .checkout .navbar.is-active .navbar {
        display: flex;
    }

    .checkout .navbar.is-active .navBtns1 {
        display: none;
    }

    .checkout .navbar.is-active .navBtns2 {
        display: none;
    }

    .checkout .navBtns2 {
        display: none;
        visibility: hidden;
    }

    .checkout .navBtns1 {
        display: none;
        visibility: hidden;
    }

    .checkout ul {
        display: none;
        visibility: hidden;
    }
}
.calendarIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.button {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-500);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.datePickerDropdown {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
}

.datePickerDropdown:hover .button {
    background-color: #dfdfdf;
    transition: 200ms;
}

.label,
.text1 {
    position: relative;
    font-weight: 500;
}

.label {
    line-height: 20px;
    display: none;
}

.text1 {
    line-height: 24px;
}

.content1,
.input,
.inputWithLabel {
    display: flex;
    justify-content: flex-start;
}

.content1 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.input,
.inputWithLabel {
    align-self: stretch;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
    width: 320px;
}

.dropdowns,
.inputDropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputDropdown {
    width: 158px;
    flex-shrink: 0;
    flex-direction: column;
    gap: var(--gap-5xs);
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-700);
}

.dropdowns {
    flex-direction: row;
    gap: var(--gap-xs);
}

.text2 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.xCloseIcon {
    position: relative;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    overflow: hidden;
}

.tagXClose {
    border-radius: var(--br-10xs);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: var(--padding-10xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.tag {
    border-radius: var(--br-27xl);
    background-color: var(--base-white);
    border: 1px solid var(--gray-300);
    display: flex;
    padding: var(--padding-9xs) var(--padding-9xs) var(--padding-9xs) var(--padding-3xs);
    justify-content: center;
    gap: var(--gap-10xs);
}

.content2,
.tag {
    flex-direction: row;
    align-items: center;
}

.content2,
.filterContainer,
.input1 {
    display: flex;
    justify-content: flex-start;
}

.input1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--gray-100);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
}

.filterContainer {
    flex-direction: column;
    align-items: flex-start;
}

.dropdowns1,
.dropdownsContainer {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.dropdowns1 {
    display: none;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-700);
}

.dropdownsContainer {
    display: flex;
    gap: var(--gap-base);
}

.text6 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.button1 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.content,
.mapModalButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.mapModalButton {
    justify-content: flex-start;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-700);
}

.content {
    align-self: stretch;
    justify-content: space-between;
}

.text7 {
    position: relative;
    line-height: 24px;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: none;
}

.textInput {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-5xs) 0 0 var(--br-5xs);
    background-color: var(--base-white);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    cursor: text;
}

.addOn,
.input2,
.textInput {
    display: flex;
    flex-direction: row;
}

.addOn {
    border-radius: var(--br-5xs) 0 0 var(--br-5xs);
    padding: var(--padding-3xs) var(--padding-xs) var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-mini);
    color: var(--gray-600);
    position: relative;
}

.input2 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
    position: relative;
}

.hintText1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-6);
    font-size: var(--text-sm-semibold-size);
    max-width: 1100px;
}

.button2,
.button3 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    cursor: pointer;
}

.button2:hover {
    border: 1px solid var(--gray-600);
}

.button3:hover {
    opacity: 0.8;
}

.button3 {
    background-color: var(--color-firebrick-100);
    border: 1px solid var(--color-firebrick-100);
    color: var(--base-white);
}

.searchBarSection,
.filtersBar {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.searchBarSection {
    flex-direction: row;
    gap: var(--gap-xs);
    color: var(--gray-700);
    align-items: center;
}

.filtersBar {
    border-radius: var(--br-5xs);
    flex-direction: column;
    gap: var(--gap-base);
}

.mapIcon {
    position: absolute;
    height: 184%;
    width: 127.78%;
    top: -42%;
    right: -13.89%;
    bottom: -42%;
    left: -13.89%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.googleLogoIcon,
.rectangle {
    position: absolute;
    bottom: 2px;
    left: 6px;
    width: 66px;
    height: 26px;
}

.rectangle {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-11xs);
    background-color: var(--base-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.pegmanOffscreen2xIcon {
    position: absolute;
    height: 71.43%;
    width: 42.86%;
    top: 14.29%;
    right: 28.57%;
    bottom: 14.29%;
    left: 28.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.streetViewButton,
.zoomControlButtons {
    position: absolute;
    top: 69px;
    right: 8px;
    width: 28px;
    height: 28px;
}

.zoomControlButtons {
    top: 8px;
    height: 53px;
}

.satellite {
    top: 27.59%;
    left: 48.94%;
}

.line,
.map1,
.satellite {
    position: absolute;
}

.line {
    height: 103.45%;
    width: 1.06%;
    top: -1.72%;
    right: 59.04%;
    bottom: -1.72%;
    left: 39.89%;
    border-right: 1px solid var(--color-whitesmoke-300);
    box-sizing: border-box;
}

.map1 {
    top: 27.59%;
    left: 8.51%;
    font-weight: 500;
    color: var(--base-black);
}

.mapTypeButtonGroup {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 94px;
    height: 29px;
}

.setLocation {
    font-weight: bold;
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
    width: 100%
}

.searchInner input {
    width: 100%;
    border: none;
    font-size: 18px;
}

.searchInner input:focus-visible {
    border: none;
    outline: none;
}

.dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
    position: absolute;
    z-index: 1000;
    top: 100%;
    width: 100%;
    max-width: 1100px;
}

.dropdownRow {
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-weight: 400;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    font-size: 1.3rem;
    line-height: 1.25;
}

.dropdownRow:hover {
    background-color: var(--secondary-color);
    color: white;
}

/* radius dropdown */
.radiusDropdown {
    position: relative;
    display: inline-block;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.radiusDropdownValue {
    background-color: #fff;
    border-radius: 4px;
    color: #555;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
}

.radiusDropdownList {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    display: block;
    list-style: none;
    margin: 0;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
    left: 0;
}

.radiusDropdownList li {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    padding: 0 10px;
    text-align: left;
}

.radiusDropdownList li:hover {
    background-color: #f6f6f6;
}

.pinMarkerIcon {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 12px);
    width: 24px;
    height: 32px;
    overflow: hidden;
    display: none;
}

.googleMapsMockup {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    overflow: hidden;
}

.outerCircle {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.1;
}

.innerCircle,
.middleCircle,
.outerCircle {
    position: absolute;
    border-radius: var(--br-9xl);
    background-color: var(--color-firebrick-100);
}

.middleCircle {
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    opacity: 0.2;
}

.innerCircle {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
}

.mapLocationMarker,
.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    position: absolute;
    top: calc(50% - 71px);
    left: calc(50% - 46px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% - 87px);
    left: calc(50% - 116px);
}

.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% + 7px);
    left: calc(50% - 38px);
}

.mapLocationMarker3 {
    top: calc(50% - 97px);
    left: calc(50% + 38px);
}

.mapLocationMarker4,
.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    position: absolute;
    top: calc(50% - 133px);
    left: calc(50% + 14px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 23px);
    left: calc(50% - 112px);
}

.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 51px);
    left: calc(50% - 2px);
}

.mapLocationMarker7 {
    top: calc(50% - 178px);
    left: calc(50% - 42px);
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker8,
.mapLocationMarker9 {
    position: absolute;
    top: calc(50% - 145px);
    left: calc(50% + 62px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker9 {
    top: calc(50% - 139px);
    left: calc(50% + 156px);
}

.mapLocationMarker10,
.mapLocationMarker11 {
    top: calc(50% - 39px);
    left: calc(50% - 26px);
}

.mapLocationMarker11 {
    top: calc(50% + 71px);
    left: calc(50% - 34px);
}

.map {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-5xs);
    height: 360px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    text-align: center;
    font-size: var(--font-size-2xs);
    color: var(--color-dimgray);
    font-family: var(--font-roboto);
}

.container,
.section {
    width: 1280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.container {
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-24);
}

.section {
    align-items: center;
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-800);
    font-family: var(--text-md-regular);
}

.mobileSearch {
    display: none;
}

@media screen and (max-width: 600px) {

    .section,
    .container {
        width: 100%;
    }

    .filtersBar {
        gap: var(--gap-6);
    }

    .searchBarSection {
        order: -1;
    }

    .mobileSearch {
        display: flex;
    }

    .wrapSearchButtons {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }

    .desktopSearch,
    .radiusLabel {
        display: none;
    }

    .textInput {
        padding: var(--padding-4xs);
    }

    .addOn {
        padding: var(--padding-4xs);
        align-self: center;
    }

    .radiusDropdownValue {
        font-size: 14px;
    }

    .searchInner input {
        font-size: 12px;
    }

    .button,
    .button3 {
        padding: var(--padding-5xs)
    }

    .content {
        justify-content: space-between;
    }

    .text {
        font-size: 12px;
    }

    .button3 .text {
        font-size: 12px;
    }

    .radiusDropdown li {
        padding: 0 5px;
    }

    .dropdownRow {
        padding: 4px 8px;
        font-size: 14px;
    }

    .container {
        padding: 0 var(--padding-5xs)
    }
}
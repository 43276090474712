.text {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--error-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: multiply;
}

.heading {
    align-self: stretch;
    position: relative;
    font-size: var(--display-md-semibold-size);
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: var(--gray-900);
}

.headingAndBadge {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
}

.supportingText {
    align-self: stretch;
    position: relative;
    font-size: var(--text-xl-semibold-size);
    line-height: 30px;
    color: var(--gray-600);
}

.headingAndSupportingText {
    width: 768px;
    align-items: center;
    gap: var(--gap-xl);
}

.container,
.content,
.headingAndSupportingText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.content {
    align-self: stretch;
    align-items: center;
}

.container {
    width: 1280px;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
    align-items: flex-start;
}

.contentIcon {
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 739px;
}

.wrapHighlightImage {
    height: 80px;
    display: flex;
}

.content1 {
    align-self: stretch;
    height: 558px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.featuredIcon {
    position: relative;
    border-radius: var(--br-9xl);
    height: 100%;
}

.supportingText1,
.text1 {
    align-self: stretch;
    position: relative;
}

.text1 {
    line-height: 30px;
    font-weight: 600;
}

.supportingText1 {
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    color: var(--gray-600);
}

.textAndSupportingText {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text2 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.arrowRightIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--error-700);
}

.content2,
.featureText {
    display: flex;
    justify-content: flex-start;
}

.featureText {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: var(--gap-xl);
}

.content2 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-13xl);
}

.container1,
.featuresSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.container1 {
    width: 1280px;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
    gap: var(--gap-77xl);
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.featuresSection {
    align-self: stretch;
    background-color: var(--base-white);
    overflow: hidden;
    padding: 0 0 var(--padding-45xl);
    gap: var(--gap-36);
    text-align: center;
    font-size: var(--text-sm-regular-size);
    color: var(--error-700);
    font-family: var(--text-md-semibold);
}

@media screen and (max-width: 600px) {
    .container {
        width: 100%;
        padding: 10px;
    }

    .headingAndSupportingText {
        width: 100%;
    }

    .heading {
        font-size: var(--display-sm-semibold-size);
    }

    .container1 {
        width: 100%;
        padding: 0;
        gap: 0px;
    }

    .content1,
    .contentIcon {
        height: unset;
    }

    .wrapHighlightImage {
        height: 50px;
    }

    .content2 {
        gap: 35px;
        flex-direction: column;
    }

    .featuresSection {
        padding: 0 0 var(--padding-32xl);
    }

    .supportingText1 {
        padding: 0px 10px 0px 10px;
    }
}
.imageWrapIcon {
  align-self: stretch;
  position: relative;
  max-height: 100%;
  width: 239px;
  overflow: hidden;
  flex-shrink: 0;
}

.text {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  width: 280px;
}

.supportingText,
.text1 {
  align-self: stretch;
  position: relative;
}

.text1 {
  font-size: var(--text-lg-semibold-size);
  line-height: 28px;
  font-weight: 600;
  color: var(--gray-900);
}

.supportingText {
  line-height: 20px;
  color: var(--gray-600);
}

.textAndSupportingText {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.text2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.actions,
.button {
  display: flex;
  flex-direction: row;
}

.button {
  border-radius: var(--br-5xs);
  background-color: var(--gray-blue-700);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-blue-700);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.actions {
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--base-white);
}

.content2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--gap-xl);
}

.container,
.content1,
.content2,
.inlineCta {
  align-items: flex-start;
  justify-content: flex-start;
}

.content1 {
  flex: 1;
  border-radius: 0 var(--br-xs) var(--br-xs) 0;
  border-top: 1px solid var(--gray-200);
  border-right: 1px solid var(--gray-200);
  border-bottom: 1px solid var(--gray-200);
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xl);
}

.container,
.inlineCta {
  align-self: stretch;
}

.inlineCta {
  border-radius: var(--br-xs);
  background-color: var(--base-white);
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  display: flex;
  flex-direction: row;
}

.container {
  display: none;
  flex-direction: column;
}

.infoCircleIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.text3 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.button1 {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.actions1,
.button1 {
  flex-direction: row;
}

.actions1,
.content3,
.textAndSupportingText1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.textAndSupportingText1 {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-9xs);
}

.content3 {
  flex: 1;
  flex-direction: column;
}

.buttonCloseX {
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  padding: var(--padding-5xs);
  align-items: center;
  justify-content: center;
}

.closeX {
  position: relative;
  width: 20px;
  height: 20px;
  display: none;
}

.alert,
.content,
.section {
  align-items: flex-start;
  justify-content: flex-start;
}

.alert {
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--gray-100);
  border: 1px solid var(--gray-300);
  display: none;
  flex-direction: row;
  padding: var(--padding-base);
  gap: var(--gap-xs);
  color: var(--gray-700);
}

.content,
.section {
  display: flex;
  flex-direction: column;
}

.section {
  align-self: stretch;
  gap: var(--gap-5xl);
  font-size: var(--text-sm-regular-size);
  color: var(--error-700);
}

.content {
  flex: 1;
  gap: var(--gap-32xl);
  text-align: left;
  font-size: var(--display-xs-semibold-size);
  color: var(--gray-900);
  font-family: var(--text-md-semibold);
}

@media screen and (max-width: 600px) {

  .content {
    overflow: hidden;
  }

  .divider {
    align-self: stretch;
    flex-shrink: 0;
  }


  .divider {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 5px;
    background-color: var(--primary-color);
  }

  .section {
    padding: 10px;
    max-width: 100%;
  }

}
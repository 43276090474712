.text {
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 280px;
    font-size: 20px;
    flex-shrink: 0;
}

.label,
.text,
.text1 {
    position: relative;
}

.label {
    line-height: 20px;
    font-weight: 500;
    display: none;
}

.text1 {
    flex: 1;
    line-height: 24px;
}

.content2 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    display: none;
}

.input,
.inputWithLabel {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.searchLgIcon {
    position: relative;
    width: 30px;
    height: 30px;
    overflow: hidden;
    flex-shrink: 0;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField,
.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputField {
    width: 100%;
    flex-direction: column;
    gap: var(--gap-6);
}

.row {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-32xl);
}

.divider,
.text3 {
    position: relative;
}

.divider {
    align-self: stretch;
    background-color: var(--gray-200);
    height: 1px;
}

.text3 {
    line-height: 24px;
}

.addOn {
    border-radius: var(--br-5xs) 0 0 var(--br-5xs);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-xs) var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
}

.input1,
.inputField1,
.textInput {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.textInput {
    flex: 1;
    border-radius: 0 var(--br-5xs) var(--br-5xs) 0;
    background-color: var(--base-white);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    color: var(--gray-900);
}

.input1,
.inputField1 {
    align-items: flex-start;
}

.input1 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    font-size: var(--text-md-regular-size);
    color: var(--gray-600);
}

.inputField1 {
    width: 100%;
    flex-direction: column;
    gap: var(--gap-6);
}

.contentIcon {
    position: relative;
    border-radius: var(--br-181xl);
    width: 92px;
    height: 92px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.featuredIcon {
    position: relative;
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
    display: none;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text6 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.text7 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.action {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
}

.supportingText {
    align-self: stretch;
    position: relative;
    font-size: var(--text-xs-medium-size);
    line-height: 18px;
    color: var(--gray-600);
    text-align: center;
}

.textAndSupportingText {
    gap: var(--gap-9xs);
}

.content4,
.fileUploadBase,
.textAndSupportingText {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.content4 {
    gap: var(--gap-xs);
}

.fileUploadBase {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    border: 1px solid var(--gray-200);
    padding: var(--padding-base) var(--padding-5xl);
}

.avatarAndFileUpload,
.fileUpload {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.fileUpload {
    flex: 1;
    flex-direction: column;
}

.avatarAndFileUpload {
    width: 100%;
    flex-direction: row;
    gap: var(--gap-xl);
    color: var(--error-700);
}

.avatarIcon {
    position: relative;
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
}

.supportingText2,
.text11 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
}

.text11 {
    font-weight: 500;
}

.supportingText2 {
    color: var(--gray-600);
}

.textAndSupportingText2 {
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.supportingText3,
.text12 {
    position: relative;
    line-height: 24px;
}

.text12 {
    font-weight: 500;
}

.supportingText3 {
    color: var(--gray-600);
    display: none;
}

.content7 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.chevronDownIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.input2 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.hintText2 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
    width: 320px;
}

.inputDropdown {
    width: 240px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.wysiwygEditorIcon,
.wysiwygEditorIcon2 {
    position: relative;
    border-radius: var(--br-7xs);
    width: 32px;
    height: 32px;
    overflow: hidden;
    flex-shrink: 0;
}

.wysiwygEditorIcon2 {
    display: none;
}

.icons,
.wysiwygToolbar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.wysiwygToolbar {
    align-self: stretch;
    align-items: center;
    gap: var(--gap-xs);
}

.addAShort {
    margin-block-start: 0;
    margin-block-end: 16px;
}

.text13 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 24px;
}

.input3,
.searchCity,
.inputWithLabel3,
.toolbarAndTextInput {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.input3 {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-xs) var(--padding-sm);
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.searchCity,
.inputWithLabel3,
.toolbarAndTextInput {
    flex-direction: column;
}

.inputWithLabel3 {
    align-self: stretch;
    flex: 1;
    gap: var(--gap-6);
}

.searchCity,
.toolbarAndTextInput {
    width: 100%;
}

.toolbarAndTextInput {
    align-self: stretch;
    gap: var(--gap-xs);
}

.searchCity {
    flex: 1;
    gap: var(--gap-6);
    position: relative;
    height: 50px;
}

.checkboxBaseIcon {
    position: relative;
    border-radius: var(--br-9xs);
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.checkbox,
.input5 {
    display: flex;
    flex-direction: row;
}

.input5 {
    padding: var(--padding-11xs) 0 0;
    align-items: center;
    justify-content: center;
}

.checkbox {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.inputFieldAndCheckbox {
    width: 100%;
    gap: var(--gap-base);
}

.content,
.content1,
.form,
.inputFieldAndCheckbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.form {
    width: 993px;
    gap: var(--gap-xl);
}

.content,
.content1 {
    color: var(--gray-700);
}

.content {
    flex: 1;
    gap: var(--gap-29xl);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
    font-family: var(--text-md-regular);
    padding: 0 0 var(--padding-5xl);
}


.inputText {
    flex: 1;
    line-height: 24px;
}

.inputText {
    width: 100%;
    border: none;
    font-size: 18px;
}

.inputText:focus-visible {
    border: none;
    outline: none;
}

.fileInput {
    display: none;
}

.fileUploadContent {
    cursor: pointer;
}

.textarea {
    width: 100%;
    border: none;
    font-size: 14px;
    resize: none;
    min-height: 150px;
}

.textarea:focus-visible {
    border: none;
    outline: none;
}

.dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
    position: absolute;
    z-index: 1000;
    top: 50px;
    width: 100%;
}

.dropdownRow {
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-weight: 400;
    padding: 6px 8px;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    font-size: 1.2rem;
    line-height: 1;
}

.dropdownRow:hover {
    background-color: var(--secondary-color);
    color: white;
}

.content1 {
    padding: 0 var(--padding-32xl);
}

@media screen and (max-width: 600px) {

    .content1,
    .form {
        width: 100%;
    }

    .content1 {
        padding: 20px;
    }

    .content {
        gap: 0px;
    }

    .row {
        flex-direction: column;
        gap: 10px;
    }

    .inputText {
        font-size: 14px;
    }

    .text3 {
        font-size: 12px;
    }

    .addOn {
        padding: 5px
    }

    .textInput {
        padding: 6px;
    }

    .input1 {
        align-items: center;
    }

    .avatarAndFileUpload {
        gap: 5px;
        justify-content: center;
        align-items: center;
    }

    .contentIcon {
        width: 60px;
        height: 60px;
    }

    .fileUploadBase {
        padding: 10px;
    }

    .dropdownRow {
        font-size: 1rem;
    }
}
.supportingText,
.text {
  align-self: stretch;
  position: relative;
}

.text {
  line-height: 32px;
  font-weight: 600;
}

.supportingText {
  font-size: var(--text-md-semibold-size);
  line-height: 24px;
  color: var(--gray-600);
}

.textAndSupportingText {
  align-self: stretch;
  width: 537px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}

.featuredIcon {
  position: relative;
  border-radius: var(--br-xl);
  width: 40px;
  height: 40px;
}

.supportingText1,
.text1 {
  align-self: stretch;
  position: relative;
}

.text1 {
  line-height: 24px;
  font-weight: 600;
}

.supportingText1 {
  font-size: var(--text-sm-regular-size);
  line-height: 20px;
  color: var(--gray-600);
}

.textAndSupportingText1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xs);
}

.cta {
  flex: 1;
  border-radius: var(--br-xs);
  background-color: var(--base-white);
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border: 1px solid var(--gray-200);
  padding: var(--padding-xl);
  align-items: flex-start;
  gap: var(--gap-xs);
  font-size: var(--text-md-semibold-size);
  color: var(--gray-700);
  cursor: pointer;
  transition: 200ms;
}

.cta:hover {
  background-color: #f0f0f0;
}

.content,
.cta,
.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.content {
  flex: 1;
  align-items: flex-start;
  gap: var(--gap-base);
}

.pageHeader {
  align-self: stretch;
  align-items: center;
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.container,
.headerSection {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.container {
  flex: 1;
  height: 106px;
  flex-direction: column;
  gap: var(--gap-5xl);
}

.headerSection {
  align-self: stretch;
  flex-direction: row;
  text-align: left;
  font-size: var(--display-xs-semibold-size);
  color: var(--gray-900);
  font-family: var(--text-md-semibold);
}

@media screen and (max-width: 600px) {

  .container,
  .content {
    width: 100%;
  }

  .content {
    flex-direction: column;
  }

  .container {
    height: unset;
    padding: 10px 10px 30px 10px;
  }

  .headerSection {
    max-width: 100vw;
  }
}
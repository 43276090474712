.text {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  font-size: 24px;
}

.supportingText {
  position: relative;
  font-size: var(--text-md-regular-size-size);
  line-height: 20px;
  color: var(--gray-600);
}

.textAndSupportingText {
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}

.text1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.button {
  flex-direction: row;
  align-items: center;
}

.button,
.button1,
.button2 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: flex;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
}

.button1 {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-50);
  flex-direction: row;
  align-items: center;
  color: var(--primary-700);
}

.button2 {
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  color: var(--gray-700);
}

.actions,
.button2,
.button3 {
  flex-direction: row;
  align-items: center;
}

.button3 {
  border-radius: var(--br-5xs);
  background-color: var(--primary-600);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--primary-600);
  overflow: hidden;
  display: flex;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--base-white);
}

.actions {
  display: none;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--text-md-regular-size-size);
  color: var(--gray-600);
}

.dotsVerticalIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.content,
.dropdown {
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown {
  display: none;
  flex-direction: column;
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: var(--gap-base);
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
  display: none;
}

.container1,
.sectionHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.sectionHeader {
  gap: var(--gap-xl);
}

.container1 {
  flex: 1;
  padding: 0 var(--padding-32xl);
  gap: var(--gap-24);
  max-width: 800px;
}

.dividerIcon1,
.section1 {
  align-self: stretch;
  flex-shrink: 0;
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dividerIcon1 {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 2px;
  background-color: #f1f1f1;
}

.dividerWrap {
  align-self: stretch;
  display: none;
  flex-direction: column;
  padding: 0 0 var(--padding-5xl);
  align-items: flex-start;
  justify-content: flex-start;
}

.text5 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.nextButton {
  border-radius: var(--br-5xs);
  background-color: var(--error-700);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--error-700);
  box-sizing: border-box;
  width: 290px;
  flex-shrink: 0;
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.nextButton,
.backButton,
.content1 {
  display: flex;
  flex-direction: row;
}

.backButton {
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-lg);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--gray-700);
}

.content1 {
  align-self: stretch;
  padding: 0 var(--padding-32xl) var(--padding-5xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}

.container,
.modalActions,
.section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.modalActions {
  padding: var(--padding-32xl) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--text-md-semibold-size);
  color: var(--base-white);
}

.container,
.section {
  align-items: center;
}

.container {
  padding: 0 var(--padding-32xl);
  justify-content: flex-start;
  gap: var(--gap-base);
}

.checkoutv2,
.section2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.section2 {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.checkoutv2 {
  position: relative;
  max-height: 100%;
  background-color: var(--base-white);
  width: 100%;
  overflow: hidden;
  justify-content: center;
  text-align: left;
  font-size: var(--text-lg-semibold-size);
  color: var(--gray-900);
  font-family: var(--text-md-regular-size);
}

.wrapCard {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.actionButton {
  cursor: pointer;
  max-width: 300px;
}

.nextButton:hover {
  opacity: 0.8;
  box-shadow: var(--shadow-xs);
}

.backButton:hover {
  border: 1px solid var(--gray-600);
}

.logoIcon {
  position: relative;
  width: 154px;
  height: 41.91px;
  flex-shrink: 0;
}

.headerNavigation {
  align-self: stretch;
  height: 96px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  padding: var(--padding-32xl);
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}


@media screen and (max-width: 600px) {
  .section {
    max-width: 100%;
    justify-content: flex-start;
  }

  .headerNavigation {
    padding: var(--padding-5xs);
    height: unset;
  }

  .container {
    overflow-y: scroll;
    padding: 0px;
  }

  .section1 {
    padding: 0 var(--padding-sm) 100px var(--padding-sm);

  }

  .container form {
    width: 100%;
    height: 100%;
  }

  .container1 {
    padding: 0;
    gap: var(--gap-base);
    width: 100%;
  }

  .supportingText {
    font-size: 11px;
  }

  .sectionHeader {
    gap: var(--gap-xs);
  }

  .content1 {
    padding: 0;
  }

  .actionButton {
    width: 50%;
  }

  .section2 {
    display: none;
  }

  .modalActions {
    position: absolute;
    bottom: 0;
    padding: 0;
    background-color: white;
    align-items: center;
    border-top: 1px solid #e1e1e1;
  }

  .modalActions {
    padding: var(--padding-16);
    box-sizing: border-box;
    font-size: var(--text-md-semibold-size);
    width: 100%;
  }

  .content1 {
    width: 100%;
  }

}
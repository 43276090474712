.logoIcon {
    position: relative;
    width: 154px;
    height: 41.91px;
}

.dotIcon {
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
}

.barChart01Icon,
.dotIcon,
.text {
    position: relative;
    display: none;
}

.barChart01Icon {
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.text {
    line-height: 24px;
    font-weight: 600;
}

.content1 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.text1 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--gray-700);
}

.chevronDownIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.navItemBase {
    border-radius: var(--br-7xs);
    overflow: hidden;
    display: none;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.text2 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0d0d0d;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content2 {
    display: flex;
    gap: var(--gap-xs);
}

.badge1,
.content,
.content2,
.navItemBase1,
.navigation {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.badge1 {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
}

.content,
.navItemBase1,
.navigation {
    display: flex;
}

.navItemBase1 {
    border-radius: var(--br-7xs);
    overflow: hidden;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
    color: var(--gray-700);
}

.content,
.navigation {
    gap: var(--gap-9xs);
}

.content {
    gap: var(--gap-21xl);
}

.text12 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.button,
.button1 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button1 {
    background-color: var(--error-700);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--error-700);
    color: var(--base-white);
}

.navigationActions {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-xs);
    color: var(--base-black);
}

.container,
.header,
.navigationActions {
    display: flex;
    align-items: center;
}

.container {
    width: 1280px;
    flex-direction: row;
    padding: 0 var(--padding-base);
    box-sizing: border-box;
    justify-content: space-between;
}

.header {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    height: 80px;
    flex-direction: column;
    justify-content: center;
}

.dropdownHeaderNavigation {
    align-self: stretch;
    position: relative;
    height: 80px;
    z-index: 1;
}

.fillYourTee {
    margin: 0;
    color: var(--primary-color);
}

.heading {
    align-self: stretch;
    position: relative;
    font-size: var(--display-md-semibold-size);
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: var(--gray-900);
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.headingAndBadge {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.supportingText {
    align-self: stretch;
    position: relative;
    font-size: var(--font-size-6xl);
    line-height: 30px;
    color: var(--gray-600);
}

.headingAndSupportingText {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xl);
}

.text14 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    font-size: 24px;
    padding: 10px 10px;
}

.button2 {
    border-radius: var(--br-5xs);
    background-color: var(--error-700);
    border: 1px solid var(--error-700);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-base) var(--padding-9xl);
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

}

.actions,
.buttonWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttonWrapper {
    flex-direction: column;
    cursor: pointer;
}

.actions {
    flex-direction: row;
    font-size: var(--text-lg-semibold-size);
    color: var(--base-white);
}

.content7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-29xl);
    max-width: 400px;
}

.backgroundIcon,
.cardHeroChild {
    position: absolute;
    top: -77.88px;
    left: 2.61px;
    width: 389.1px;
    height: 425.84px;
}

.backgroundIcon {
    top: -80.49px;
    left: 0;
    width: 394.32px;
    height: 431.06px;
}

.cardHeroItem,
.groupChild {
    position: absolute;
    overflow: hidden;
    object-fit: cover;
}

.cardHeroItem {
    top: -44.61px;
    left: 33.2px;
    border-radius: var(--br-5xs);
    width: 300.2px;
    height: 257.06px;
}

.groupChild {
    height: 136.47%;
    width: 79.5%;
    top: -68.42%;
    right: 1.81%;
    bottom: 31.95%;
    left: 18.69%;
    max-width: 100%;
    max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.17%;
    width: 46.68%;
    top: -40.35%;
    right: 18.25%;
    bottom: 60.19%;
    left: 35.07%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 136.55%;
    width: 79.53%;
    top: -68.46%;
    right: 1.8%;
    bottom: 31.91%;
    left: 18.67%;
    object-fit: cover;
}

.layer2Copy,
.rectangleIcon1,
.rectangleIcon2 {
    height: 20.04%;
    width: 15.14%;
    top: 2.92%;
    right: 47.41%;
    bottom: 77.03%;
    left: 37.45%;
}

.layer2Copy,
.rectangleIcon2 {
    height: 108.98%;
    width: 92.24%;
    top: -50.17%;
    right: -5.32%;
    bottom: 41.19%;
    left: 13.08%;
}

.layer2Copy {
    height: 117.71%;
    width: 97.33%;
    top: -54.5%;
    right: -7.87%;
    bottom: 36.79%;
    left: 10.54%;
}

.vectorParent {
    position: absolute;
    height: 9.15%;
    width: 18.33%;
    top: 69.9%;
    right: 7.93%;
    bottom: 20.95%;
    left: 73.74%;
    transform: rotate(-15deg);
    transform-origin: 0 0;
}

.frameChild {
    position: relative;
    width: 36.01px;
    height: 36.01px;
    object-fit: cover;
}

.markDelewayIcon,
.scratchmakerIcon {
    position: relative;
    width: 64.67px;
    height: 21.66px;
}

.markDelewayIcon {
    width: 68.26px;
    height: 22.94px;
}

.scratchmakerParent,
.vectorGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.vectorGroup {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-xs);
}

.icon,
.memberSinceIcon {
    position: relative;
    width: 66.59px;
    height: 22.05px;
}

.icon {
    width: 27.33px;
    height: 13.01px;
}

.frameParent,
.memberSinceParent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
}

.frameParent {
    position: absolute;
    top: 306.94px;
    left: 26.33px;
    width: 263px;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-46xl);
}

.instructorIcon,
.pgaProfessionalIcon {
    position: relative;
    width: 79.19px;
    height: 27.52px;
}

.instructorIcon {
    width: 47.95px;
    height: 17.7px;
}

.cardHeroInner {
    position: absolute;
    top: 247.94px;
    left: 26.33px;
    width: 103px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.line1Stroke {
    position: absolute;
    top: 209.26px;
    left: 101.57px;
    width: 263px;
    height: 68.82px;
}

.cardHero,
.cardHero1 {
    width: 310.98px;
    height: 362.94px;
}

.cardHero1 {
    position: absolute;
    top: 0;
    left: 0;
}

.cardHero {
    position: relative;
}

.redCard {
    position: absolute;
    top: -5%;
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transform: rotate(15deg);
    transform-origin: 0 0;
}

.cardHeroChild1 {
    height: 117.33%;
    width: 125.12%;
    top: 0.72%;
    right: 4.25%;
    bottom: -18.05%;
    left: -29.37%;
}

.backgroundIcon1,
.cardHeroChild1,
.frameIcon,
.groupItem {
    position: absolute;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.backgroundIcon1 {
    height: 118.77%;
    width: 126.8%;
    top: 0;
    right: 3.41%;
    bottom: -18.77%;
    left: -30.21%;
}

.frameIcon,
.groupItem {
    object-fit: cover;
}

.frameIcon {
    height: 70.83%;
    width: 96.53%;
    top: 9.86%;
    right: 14.18%;
    bottom: 19.31%;
    left: -10.71%;
    border-radius: var(--br-5xs);
}

.groupItem {
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
}

.layer2Copy1,
.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5,
.vectorIcon1 {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26.01%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.layer2Copy1,
.rectangleIcon3,
.rectangleIcon4,
.rectangleIcon5 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.layer2Copy1,
.rectangleIcon4,
.rectangleIcon5 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.5%;
    bottom: 45.79%;
    left: 27.95%;
}

.layer2Copy1,
.rectangleIcon5 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.84%;
    bottom: 30.23%;
    left: 1.88%;
}

.layer2Copy1 {
    height: 36.82%;
    width: 100%;
    top: 36.17%;
    right: 0;
    bottom: 27.01%;
    left: 0;
}

.scratchmakerIcon1 {
    position: relative;
    width: 64.67px;
    height: 22.79px;
}

.markDelewayIcon1 {
    position: relative;
    width: 68.26px;
    height: 25.69px;
}

.icon1,
.memberSinceIcon1 {
    position: relative;
    width: 66.59px;
    height: 23.89px;
}

.icon1 {
    width: 26.65px;
    height: 13.46px;
}

.frameGroup {
    position: absolute;
    height: 9.92%;
    width: 84.57%;
    top: 84.57%;
    right: 6.96%;
    bottom: 5.51%;
    left: 8.47%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-46xl);
}

.instructorIcon1 {
    position: relative;
    width: 47.95px;
    height: 18.97px;
}

.cardHeroInner1 {
    position: absolute;
    height: 4.17%;
    width: 33.12%;
    top: 68.31%;
    right: 58.41%;
    bottom: 27.52%;
    left: 8.47%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.line1Stroke1 {
    position: absolute;
    height: 18.96%;
    width: 84.57%;
    top: 80.17%;
    right: 31.8%;
    bottom: 0.87%;
    left: -16.37%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.cardHero3 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.blueCard {
    position: absolute;
    top: 50%;
    left: 0%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    transform: rotate(-15deg);
    transform-origin: 0 0;
}

.content8 {
    flex: 1;
    position: relative;
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    height: 560px;
    background: url("/public/grass.webp")
}

.container1 {
    width: 1280px;
    flex-direction: row;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    display: flex;
    align-items: flex-start;
}

.heroHeaderSection,
.section {
    display: flex;
    align-items: center;
}

.section {
    width: 1440px;
    flex-direction: row;
    padding: var(--padding-45xl) 0 var(--padding-77xl);
    box-sizing: border-box;
    justify-content: center;
    z-index: 0;
    font-size: var(--font-size-17xl);
    color: var(--gray-900);
}

.heroHeaderSection {
    margin-top: var(--header-height);
    align-self: stretch;
    background-color: var(--base-white);
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--base-white);
    font-family: var(--text-md-semibold);
}

.ctaText {
    font-weight: 500;
}

@media screen and (max-width: 600px) {
    .section {
        width: 100%;
        padding: 10px 10px 10px;
        font-size: var(--text-xl-semibold-size);
    }

    .supportingText {
        font-size: 16px;
        line-height: 18px;
        color: #6e6e6e;
        font-weight: 600;
    }

    .container1 {
        flex-direction: column;
        padding: 0px;
        width: 100%;
    }

    .content8 {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }

    .blueCard,
    .redCard {
        position: relative;
        transform: unset;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
    }

    .fillYourTee,
    .supportingText {
        text-align: center;
    }

    .content7 {
        gap: 20px;
    }

    .button2 {
        padding: 8px 12px;
    }

    .howItWorks {
        gap: 20px;
    }

    .heading {
        align-self: stretch;
        position: relative;
        font-size: 28px;
        letter-spacing: -0.02em;
        line-height: 30px;
        font-weight: 700;
        color: var(--gray-900);
        text-align: center;
    }
}
.howItWorks {
    background-color: var(--base-white);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}


@media screen and (max-width: 600px) {

    .howItWorks {
        gap: 20px;
    }
}
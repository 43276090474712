/* @import url('..https://fonts.googleapis.com/css2?family=Forum&display=swap'); */
/* @import url('..https://fonts.googleapis.com/css2?family=Forum&family=Nunito+Sans:wght@400;800&display=swap'); */

:root {
    /* --primary-color: #09B051; */
    /* --secondary-color: #018f3f; */
    --primary-color: #274368;
    --secondary-color: #BA3526;
    --main-color: #BA3526;
    --main-color-hover: rgba(161, 161, 161, 0.2);
    --track-color: #ddd;
    --mark-color: #aaaaaa;
}

@font-face {
    font-family: "Bebas";
    src: url("./BebasNeue-Regular.ttf");
}

.App {
    height: 100%;
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--primary-color);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    /* font-family: Bebas; */
}

a {
    text-decoration: none;

    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */

    cursor: pointer;
}

html {
    scroll-behavior: smooth;
}

.container {
    max-width: 1370px;
    margin: auto;
    height: 100%;
    padding: 0 20px;
    position: relative;
}

/* Nav Bar */
.navheader {
    padding-top: 10px;
    padding-bottom: 0px;
    width: 100vw;
    position: fixed;
    left: 0;
    z-index: 100;
    height: auto;
    margin: 0 auto;
    transition: all 0.35s ease;
}

.on-scroll.navheader {
    border-bottom: 3px solid var(--primary-color);
}

.bg-none {
    background: unset;
    background-color: unset;
}

.bg-none.on-scroll {
    /* background-color: var(--primary-color); */
    background-color: white;
}

.navbar {
    margin: auto;
    display: flex;
    align-items: center;
    color: white !important;
    justify-content: center;
}

.navbar.is-active {
    color: black;

}

.navbar .on-scroll {
    color: black;
}

.on-scroll {
    background-color: white;
}

.logo {
    background-image: url('../Assets/Images/Scratchmaker-logo-simple-svg.svg');
    width: 75px;
    height: 75px;
    background-size: 100% 100%;
}

.navbar.is-active .product-title-mobile {
    display: none;
}

ul {
    list-style: none;
    display: flex;
    justify-content: center;


}

.navbar ul {
    line-height: 50px;
    gap: 3rem;
}

.navbar img {
    width: 50px;
}

.home ul li {
    cursor: pointer;
    transition: 1s;

}

.on-scroll li {
    cursor: pointer;
    transition: 1s;
    color: white;

}


ul {
    gap: 100px;
}

.product-title-mobile {
    display: none;
    color: white;
    font-family: 'Brush Script MT', cursive;
    font-size: 1.8rem;
}

.burger {
    position: absolute;
    right: 40px;
    display: block;
    cursor: pointer;
    user-select: none;
    order: -1;
    z-index: 10;
    width: 1.75rem;
    height: 1rem;
    border: none;
    outline: none;
    background: none;
    visibility: visible;
    transform: rotate(0deg);
    transition: 0.35s ease;
    top: 30%;
}

.burger.is-active .burger-line:nth-child(1) {
    top: 0.5rem;
    transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
    right: -1.5rem;
    opacity: 0;
    visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
    top: 0.5rem;
    transform: rotate(-135deg);
}


.burger-line {
    position: absolute;
    display: block;
    right: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    transform: rotate(0deg);
    background-color: var(--primary-color);
    transition: 0.25s ease-in-out;
}

.navbar.is-active .burger-line {
    background-color: black;
}

.on-scroll .burger-line {
    position: absolute;
    display: block;
    right: 0;
    width: 100%;
    height: 2px;
    border: none;
    outline: none;
    opacity: 1;
    transform: rotate(0deg);
    background-color: var(--primary-color);
    transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
    top: 0px;
}

.burger-line:nth-child(2) {
    top: 0.5rem;
}

.burger-line:nth-child(3) {
    top: 1rem;
}

.navBtns {
    display: flex;
    padding: 0px;
    gap: 67px;
    margin-left: 50px;
    margin-bottom: 5px;
}

.navBtns a,
.nav-link {
    font-size: 1.2rem;
    cursor: pointer;
    color: white;
    /* border-bottom: 3px solid var(--primary-color); */
}

.on-scroll .navBtns a,
.nav-link {
    color: black;
}

.navBtns a:hover,
.nav-link:hover {
    color: var(--primary-color);
    /* border-bottom: 3px solid white; */
}

.navBtns1 {
    font-family: Nunito Sans;
    font-size: 1em;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    transition: 1s;
    height: 50px;


}

.navBtns2 .arrowInBtn {
    width: 30px;
    height: 8px;
    background-image: url('../Assets/Images/buttonArrowWhite.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.on-scroll .navBtns2 .arrowInBtn {
    width: 30px;
    height: 8px;
    background-image: url('../Assets/Images/buttonArrowWhite.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.on-scroll .navBtns1 {
    color: white;
}

.navBtns1:hover {

    letter-spacing: 2px;
}

.navBtns2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;

    font-weight: 400;
    color: #FFFFFF;
    height: 50px;
    padding: 0px 20px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    transition: all 0.35s ease;
    cursor: pointer;
}

.on-scroll .navBtns2 {
    color: white;
    border: 1px solid white;
    background-color: var(--secondary-color);
}

.navBtns2:hover {
    transition: 0.5s;
    border: 1px solid var(--primary-color);
}

/* Modal */

.modal-overlay {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    display: block;
    background: white;
    border-radius: 4px;
    max-height: 90vh;
    max-width: 600px;
}

.sign-up-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1;
    padding: 0 36px 24px;
    min-width: 500px;
    max-width: 100%;
}

.scratch-font {
    font-family: 'Brush Script MT', cursive;
}

.product-title-login-modal {
    font-size: 30px;
}

.form-header {
    text-align: center;
}

.form-header h4 {
    color: #404145;
    font-size: 24px;
    line-height: 130%;
}

.form-header img {
    width: 200px;
    padding: 10px;
}

.social-signing-button.facebook-signing-button {
    background: #4267b2;
    border: 1px solid #4267b2;
}

.icon-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px 30px 12px 16px;
    margin: 12px 0;
    background: #fff;
    border: 1px solid #e4e5e7;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
}

.facebook-signing-button:hover {
    background: #517acc !important;
}

.icon-button:hover {
    background: #ececec
}

.provider-icon {
    display: inline-block;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    padding-right: 10px;
}

.social-signing-button.facebook-signing-button p {
    color: #fff;
}

.icon-button p {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    color: #62646a;
}

.form-separator {
    height: 12px;
    margin: 20px 0 32px;
    text-align: center;
    border-bottom: 1px solid #e4e5e7;
}

.form-separator span {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    padding: 0 8px;
    background-color: #fff;
    color: #62646a;
}

.modal-content .field {
    padding-bottom: 16px;
    position: relative;
}

.field .field-input-wrapper {
    display: block;
    font-size: 16px;
    line-height: 24px;
}

.wrap-email {
    position: relative;
}

.emailpass-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #c5c6c9;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font: inherit;
    color: #404145;
    -webkit-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.submit-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.submit-button:hover {
    background: var(--secondary-color);
    color: white;
}

.submit-email {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    padding: 12px 24px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    -webkit-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    -o-transition: 70ms cubic-bezier(.75, 0, .25, 1);
    transition: 70ms cubic-bezier(.75, 0, .25, 1);
    color: #fff;
    background-color: var(--primary-color);
}

.sign-up-email-form {
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.sign-up-email-form>p {
    text-align: center;
    padding-top: 20px;
    font-size: 14px;
    line-height: 21px;
    color: #62646a;
    width: 100%;
}

.forgot-password {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 14px 0 0;
}

.forgot-password button,
.signing-footer button {
    cursor: pointer;
}

.forgot-password button:hover,
.signing-footer button:hover {
    text-decoration: underline;
}

.login-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background-color: #fff;
    border-top: 1px solid #dadbdd;
    padding: 15px;
    border-radius: 0 0 4px 4px;
}

.login-footer .signing-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1 1;
    color: var(--primary-color) !important;
}

.login-footer .signing-footer button {
    padding-left: 5px;
}

.terms-of-services {
    text-align: center;
    padding-top: 20px;
    line-height: 18px;
    color: #b5b6ba;
}

.terms-of-services a {
    color: #446ee7;
}

.terms-of-services a:hover {
    text-decoration: underline;
}

.text-body-3,
.tbody-7 {
    font-size: 12px;
    line-height: 20px;
}

.tbody-6,
.text-body-2 {
    font-size: 14px;
    line-height: 21px;
}

.sign-out {
    cursor: pointer;
    font-size: 1.2rem;
    color: white;
}

.on-scroll .sign-out {
    color: black;
}

.cb-remember-wrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #c5c6c9;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 18px;
    -webkit-justify-content: center;
    justify-content: center;
    margin-right: 8px;
    min-width: 18px;
    position: relative;
    width: 18px;
}

.cb-remember {
    fill: #62646a;
    display: inline-block;
    font-style: inherit;
    font-weight: inherit;
    opacity: 0;
    pointer-events: none;
    -webkit-transform: scale(.5);
    transform: scale(.5);
    -webkit-transform-origin: 5;
    fill: #74767e;
    background: none;
    border: none;
    display: inline-block;
    margin: 0;
    padding: 0;
}

.remember-me input:checked+.cb-remember-wrap .cb-remember {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

.remember-me {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #404145;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}

.remember-me input[type=checkbox] {
    height: 0;
    opacity: 0;
    width: 0;
}

.status-message {
    color: #f74040;
    padding-bottom: 8px;
    max-width: 500px;
}

.link-button.green {
    color: var(--secondary-color);
}

.link-button {
    background: none;
    border: none;
}

.money {
    color: #008000;
}

/* hero section*/
.home .header {
    width: 100%;
    height: 100vh;
    background-image: url('../Assets/Images/home-image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 30vh;
    box-shadow: inset 0px -19px 39px rgba(0, 0, 0, 0.7);
    font-family: "Macan,Helvetica Neue,Helvetica,Arial,sans-serif";
}

.home .header-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.home .header-section .wrap-top-header {
    display: flex;
    justify-content: center;
}

.home .header h1 {
    font-family: 'Brush Script MT', cursive;
    font-size: 12em;
    font-weight: 400;
    animation: paralex 1s;
}

.home .header h2 {
    font-family: Nunito Sans;
    font-size: 2em;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
    animation: paralex 1s;
}

.home .header .carousel h2 {
    font-size: 1.4em;
}

.home .wrap-hire-statements {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    padding-left: 10px;
}

.home .wrap-hire-statements .hire-statement.one {
    display: flex;
    justify-content: flex-end;
}

.home .wrap-hire-statements .hire-statement {
    display: flex;
    justify-content: flex-end;
}

.home .headerBtns {
    margin-top: 7vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 67px;
    justify-content: center;
    animation: paralex 1s;
}

.home .headerBtn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background: #FFFFFF;
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: black;
    padding: 18px 40px;
    cursor: pointer;
    transition: 1s;

}

.home .headerBtn1:hover {
    transition: 1s;
    gap: 40px;
}

.home .headerBtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding: 18px 0px;
    cursor: pointer;
    transition: 1s;

}

.home .headerBtn2:hover {
    transition: 1s;
    letter-spacing: 2px;
}

.home .downBtn {
    margin: auto;
    width: 97px;
    height: 97px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 40px 0;
    margin-top: 10vh;
    cursor: pointer;
    animation: paralex 1s;
}

.home .downBtn:hover {
    transition: 1s;

    background-color: rgba(255, 255, 255, 0.093);
}

.home .HowItWorksInner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    padding: 40px 0px;
    animation: paralex 1s;
}

.home .HowItWorksInner .box {
    width: 100%;
    min-height: 230px;
    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 1%;
    gap: 20px;

}

.home .HowItWorksInner .box p {
    font-size: 1.2em;
}


.home .HowItWorksInner .box img {
    height: 6vw;
    max-height: 80px;
    max-width: 80px;
    width: 6vw;
}

.home .productSection {
    width: 100%;
    animation: paralex 1s;
}

#info-tooltip {
    background-color: #585757;
    color: white;
    border-radius: 4px;
    padding: 0.6rem;
}

.home .productHeader {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    align-items: center;
}

.home .productHeader .leftsideText {
    height: 70px;
}

.home .productHeader .leftsideText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home .sideText h2 {
    opacity: 0;
    cursor: default;
}

.home .productHeader .sideText {
    height: 70px;
}

.home .sideText .sortby {
    display: flex;
    align-items: flex-end;
    height: 100%;
    gap: 12px;
}

.home .sideText .sortby p {
    font-weight: bold;

}

.home .sideText .sortby div {
    font-weight: bold;
    justify-content: center;
    color: var(--secondary-color);
}

.home .wrap-sort-by {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 75px;
    background-color: #fff;
    border: 1px solid #a8a8a8;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(28, 28, 28, 0.2);
    z-index: 1000;
}

.home .wrap-sort-by span {
    border-bottom: 1px solid #a8a8a8;
    padding: 5px;
    font-size: 18px;
    font-weight: 300;
}

.home .wrap-sort-by span:hover {
    background-color: #f0f0f0;
}


.home .sideText .sortby div:hover {
    cursor: pointer;
}

.home .sideText .sortby img {
    height: 15px;
    padding-left: 5px;
}

.close-golfer-info {
    width: 10px;
}

.profilePic-golfer-info {
    max-width: 80% !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.home .productHeader a {
    font-weight: bold;
    text-decoration: none;
    color: var(--primary-color);
}

.golferCards .wrap-golfer-info img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.home .golferCards {
    padding-top: 30px;
    display: grid;
    --auto-grid-min-size: 264px;
    grid-gap: 20px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

.home .golferCards .box {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: auto;
    border: 1px solid #aaaaaa;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 12px;
    animation: paralex 1s;
}

.home .golferCards .box:hover {
    transition: .5s;
    border: 1px solid #b4b4b4;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.home .infoIcon {
    position: absolute;
    color: #9B9A9A;
    font-size: 1.3em;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.golfer-info-tooltip {
    font-size: 12px;
    margin-right: 0.4rem;
}

.home .stars i {
    color: #FFC700;
    font-size: 1.3em;
}

.home .golferCards .box h3 {

    font-weight: 800;
    font-size: 22px;
}

.home .golferCards .box h3:nth-child(2) {
    color: var(--primary-color);
    /* color: green; */
}

.home .golferCards .box:hover h3:nth-child(2) {
    /* color: var(--primary-color); */
    color: green;
}

.home .productHeading1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.home .productHeading2 {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.stars {
    display: flex;
    gap: 5px;
    font-size: 12px
}

.productHeading3 {
    display: flex;
    gap: 5px;
    align-items: center;
}

.productHeading3 p {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.productHeading4 {

    display: flex;
    align-items: center;
    gap: 5px;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.productHeading4 i {
    font-size: 1.2em;
    color: #09B051;
}

.productTags {
    display: flex;
    gap: 5px;
    width: 100%;

}

.locationUpdate {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.2rem;
}

.locationUpdate:hover {
    cursor: pointer;
    font-size: 1.3rem;
    transition: 400ms;
}

.tag {
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    width: 100%;
    height: 35px;
    background: rgba(152, 152, 152, 0.2);
    border-radius: 300px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
}

.home .cardButton {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    gap: 20px;
    background: var(--primary-color);
    border-radius: 10px;
    cursor: pointer;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.home .cardButton:hover {
    background-color: var(--secondary-color);
    transition: .3s;
}

.home .profilePic-golfer-info {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.home .paginate-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 20px;
    gap: 10px;
}

.home .paginate-buttons img {
    height: 40px;
    cursor: pointer;
}

.home .paginate-buttons img.carrot-left:hover {
    cursor: pointer;
    content: url("../Assets/Images/carrot-left-fill.png")
}

.home .paginate-buttons img.carrot-right:hover {
    cursor: pointer;
    content: url("../Assets/Images/carrot-right-fill.png")
}

.home .paginate-buttons .page-nums {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.home .paginate-buttons .page-nums .page-num {
    display: flex;
    align-items: center;
    border: 2px solid var(--primary-color);
    width: 30px;
    font-size: 24px;
    justify-content: center;
    cursor: pointer;
}

.home .paginate-buttons .page-nums .page-num.active-page {
    color: white;
    background-color: var(--secondary-color);
}


.home .paginate-buttons .page-nums .page-num:hover {
    background-color: #c9c9c9;
}

/* Testimonials */

.home .testimonialSection {
    width: 100%;
    height: fit-content;
    padding: 50px 0;
    animation: paralex 1s;
    text-align: center;
    margin-top: 40px;
}

.home .testimonial h3 {


    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 53px;
    text-align: center;

}

.home .testimonial {
    height: fit-content;
}

.home .testimonialCard {
    margin: 35px 0;
    width: 100%;
    display: none;
    border: 1px solid var(--primary-color);
    text-align: left;
    padding: 40px;
}

.home .testimonialCard.show {
    display: block;
}

.home .testimonialCard p {
    text-align: left;

    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    margin: 50px 0;
}

.home .testimonialBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home .testimonialBottomLeft {
    display: flex;
}

.home .testimonialBottomLeft img {
    width: 63.9px;
    height: 63.9px;
    border: 4px solid var(--secondary-color);
    border-radius: 3000px;
    object-fit: cover;
}

.home .testimonialBottomLeftDetails {
    margin-left: 20px;

}

.home .testimonialBottomLeftDetails h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 159.12%;

}

.home .testimonialBottomLeftDetails p {

    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    line-height: 159.12%;
    margin: 0;
}


.home .sliderButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 60px;
    text-align: center;

}

.home .sliderButtons img {
    cursor: pointer;
}

.home .dots {
    gap: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
    transform: translateY(50%);
}

.home .dot {
    width: 10px;
    height: 2px;
    background-color: #474747;
    cursor: pointer;

}

.home .activeDot {
    width: 10px;
    height: 4px;
    background-color: var(--secondary-color);

}

.placeholder-nav-line-1 {
    height: 3px;
    width: 100%;
    background-color: var(--secondary-color);
}

.placeholder-nav-line-2 {
    height: 3px;
    width: 100%;
    background-color: white;
}

.placeholder-nav-line-1,
.placeholder-nav-line-2 {
    display: none;
}

.on-scroll .placeholder-nav-line-1,
.on-scroll .placeholder-nav-line-2 {
    display: block;
}

/* Footer */

.footerSection {
    width: 100%;
    background-color: var(--primary-color);
    color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    margin-top: auto;
    position: relative;
}

.wrap-footer-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.wrap-footer-main .logo-tm {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.footer {
    padding: 10px 0;
    position: relative;
}

.footerLogo {
    display: flex;
    width: 60px;
}

.footer .wrap-icons .poweredBy {
    width: auto;
    width: 90px;
    z-index: 1000;
    bottom: 0;
    right: 0;
}

.footer ul a li {
    color: black;
    border: none;
}

.footer .wrap-foot-questions span {
    font-size: 18px;
}

.footer .wrap-icons {
    padding: 10px;
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: center;
}

.footer .wrap-icons img {
    width: 30px;
}

.horizLine {
    width: 90%;
    height: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

/* Locations */
.golfer-locations .set-location {
    font-weight: bold;
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
}

.search-container {
    flex-direction: column;
    display: flex;
    width: 90%;
}

.search-container .use-current-loc {
    padding-bottom: 15px;
    font-size: 16px;
    color: #446ee7;
    text-decoration: underline;
    cursor: pointer;
}

.search-container .use-current-loc:hover {
    opacity: 0.8;
}

.search-container .dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    width: 83%;
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
    position: absolute;
    top: 165px;
    max-width: 500px;
    z-index: 1000;
}

.golfer-locations .search-container .dropdown {
    top: 150px;
}

.search-container .dropdown:empty {
    border: none;
}

.search-container .dropdown-row {
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-weight: 400;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    font-size: 1.3rem;
    line-height: 1.25;
}

.search-container .dropdown-row:hover {
    background-color: var(--secondary-color);
    color: white;
}

.golfer-locations .search-container .search-inner input {
    width: 220px;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
}

.search-container .search-img {
    width: 15px;
}

.search-container .search-img.search {
    width: 20px;
}

/* FAQs Section*/

.home .faqsSection {
    width: 100%;
    height: fit-content;
    padding: 50px 0;
    animation: paralex 1s;
}

.home .faq_wrapper {
    width: 100%;
}

.home .faqs h3 {

    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    margin-bottom: 50px;
}

.home .faqbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 28px;
    gap: 10px;
    border: 1px solid #D9D9D9;
    height: fit-content;
}

.home .faqHeading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.home .faqHeading h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.home .faqHeading img {
    height: 10px;
    width: 15px
}

.home .faqbox p {

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: #858585;
}

.home .faqbox .inVisibleAns {
    display: none;
}

.home .faqbox .visibleAns {
    display: block;
}

.home .faq_wrapper {
    display: grid;
    --auto-grid-min-size: 400px;
    grid-gap: 20px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

.wrap-golfer-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.popupCardWrapProfilePicture {
    text-align: center;
    justify-content: center;
}


/* location popup */
.locationupdatepopup {
    display: none;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);

}

.locationupdatepopup.show {
    display: block;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
}

/*card details popup*/
.cardDetailsPopup {
    display: none;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);

}

.cardDetailsPopup.show {
    display: block;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
}

.popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    background: #FFFFFF;
    border-radius: 0.5rem;
    max-height: 200px;
}

.popupHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    background-color: var(--primary-color);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: white;
}

.popupHeading img {
    cursor: pointer;
}

.popupHeading h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
}

.popupBody {
    border-bottom-left-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
}

.inputFieldLocation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 67.75px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.inputFieldLocation::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;

}

.inputFieldLocation:-ms-input-placeholder {
    /* Internet Explorer 10-11 */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.inputFieldLocation::-ms-input-placeholder {
    /* Microsoft Edge */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.popupbuttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    gap: 12px;
}

.disableBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 56px;
    background: #CFCFCF;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;

}

.enableBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 56px;
    background: #09B051;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
}

/* Padding for inner page navigation to help with fixed navbar */
.anchor {
    position: absolute;
    top: -15vh;
    left: 0px;
}

/* Card Detials Popup*/
.popupCardDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 2%;
    justify-content: space-between;
    gap: 42px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 622px;
    height: fit-content;
    width: 80%;
    background: #FFFFFF;
    border-radius: 20px;
}

#closecarddetailspopup {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
}

.popBasicCardInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;

    width: 100%;

}

.productDetailCardHeading1 {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-style: normal;
    font-weight: 700;
    font-size: 1.6em;
    line-height: 1.7em;
    color: #000000;

}

.productDetailCardHeading1 h3 {
    color: var(--primary-color);
}

.golferCardstarsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 104px;
    width: 100%;
}

.productCardRating {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
}

.popCardBasicTags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    width: 100%;
}

.popupcardbasicinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    height: 110px;
    width: 100%;
}

.popupcardbasicinfo .fa-crosshairs {
    font-size: 1.5em;
}

.popupcardbasicinfo .productHeading4 {
    font-size: 1.5em;
}

.popupcardbasicinfo .golfer-location {
    font-size: 1.3em;
}

.popupCardAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.popupCardAbout h4 {

    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
}

.popupCardAbout p {

    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: justify;
    color: #000000;
    overflow-wrap: anywhere;
}


.popupCardTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 20px;
    margin-top: 30px;
}

.home .hrLine {
    width: 0px;
    /* height: 230px; */

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    height: auto;
}

.popupCardBigTags {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
}

.popupCardBigTags h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
}

.BigTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    width: 100%;
    height: 50px;
    background: rgba(9, 176, 81, 0.2);
    border-radius: 300px;
    text-align: center;
    background-color: var(--secondary-color);
    color: white;
}

.footerSection {
    margin: unset;
    margin-top: auto;
    bottom: 0;
}

.golfer-handicap {
    color: #FFC700;
    font-weight: 500;
}

/* Swal */
.swal2-actions {
    -webkit-tap-highlight-color: red;
}

.swal2-loader {
    border-color: var(--primary-color) rgba(0, 0, 0, 0) var(--primary-color) rgba(0, 0, 0, 0) !important;
}

.swal2-styled.swal2-confirm {
    background-color: black !important;
}

.swal2-styled.swal2-confirm:hover {
    background-color: #404145 !important;
}

.color-green {
    color: var(--primary-color);
}

.swal2-confirm {
    box-shadow: none !important;
}

.swal2-container {
    z-index: 100000;
}

.swal2-title {
    color: var(--secondary-color) !important;
}


.google-search-div input {
    width: 25rem;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: 700;
    line-height: 1.5;
    outline: none;
    padding: 0.75rem 1rem;
    color: var(--primary-color);
    height: 50px;
}

.google-search-div ul {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-top: none;
    list-style-type: none;
    margin: 0;
    max-height: 60%;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    gap: 0px;
    display: block;
    z-index: 1200;
}

.google-search-div ul>li {
    cursor: pointer;
    padding: 0.75rem 1rem;
    width: 25rem;
    background-color: white;
}

.google-search-div ul>li:hover {
    background-color: var(--secondary-color);
    color: white;
}

/* Price Slider */

.priceSlider {
    max-width: 85%;
    display: block;
    top: 10px;
}

.priceSlider-track {
    top: 11px;
    height: 10px;
    background: var(--track-color);
}

.priceSlider-track.priceSlider-track-0 {
    background: var(--primary-color);
}

.priceSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--primary-color);
    /* shape of the thumb: circle */
    width: 36px;
    height: 36px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
}

.priceSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
}

.priceSlider-mark {
    cursor: pointer;
    top: 6px;
    width: 4px;
    height: 20px;
    background-color: var(--mark-color);
}

.priceSlider-mark.priceSlider-mark-before {
    background-color: var(--secondary-color);
}

.priceSlider-mark.priceSlider-mark-active {
    display: none;
}

.priceslider-value {
    font-size: 1.6rem;
    color: var(--primary-color);
    position: relative;
    top: 6px;
    margin-left: 90%;
}

.wrap-slider {
    padding-bottom: 1.5rem;
}

/* Travel Slider */

.travelSlider {
    max-width: 85%;
    display: block;
    top: 10px;
}

.travelSlider-track {
    top: 11px;
    height: 10px;
    background: var(--track-color);
}

.travelSlider-track.travelSlider-track-0 {
    background: var(--primary-color);
}

.travelSlider-thumb {
    cursor: pointer;
    /*color for the thumb */
    background: var(--primary-color);
    /* shape of the thumb: circle */
    width: 36px;
    height: 36px;
    border-radius: 100%;
    /* remove default outline when selected */
    outline: none;
}

.travelSlider-thumb:hover {
    box-shadow: 0 0 0 8px var(--main-color-hover);
}

.travelSlider-mark {
    cursor: pointer;
    top: 6px;
    width: 4px;
    height: 20px;
    background-color: var(--mark-color);
}

.travelSlider-mark.travelSlider-mark-before {
    background-color: var(--secondary-color);
}

.travelSlider-mark.travelSlider-mark-active {
    display: none;
}

.travelslider-value {
    font-size: 1.6rem;
    color: var(--primary-color);
    position: relative;
    top: 6px;
    margin-left: 90%;
}

/*  carousel */

.carousel {
    --carousel-height: 50px;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 36px;
    line-height: var(--carousel-height);
    height: var(--carousel-height);
    display: flex;
    justify-content: center;
}

.carousel .pre {
    top: 0;
    right: 38%;
    height: var(--carousel-height);
    text-shadow: 1px 1px 1px #3c2254;
    font-weight: 300;
    display: inline-block;
}

.carousel .pre i {
    font-family: 'Brush Script MT', cursive;
    font-size: 48px;
}

.carousel .change_outer {
    text-align: left;
    height: var(--carousel-height);
    overflow: hidden;
    display: inline-block;
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 10px;
    border-radius: 8px;
}

.carousel .change_outer .change_inner {
    position: relative;
    /* -webkit-animation: rotate 12s ease-in-out infinite;
    animation: rotate 12s ease-in-out infinite; */
}

.carousel .change_outer .element {
    display: block;
    font-size: 42px;
}

.carousel .change_inner {
    overflow: hidden;
    height: var(--carousel-height);
}

/*  Warning Modal */
.username-alert-modal .popupCardDetails {
    padding: 20px;
}

.username-alert-modal .alert-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.username-alert-modal .alert-header img {
    width: 50px;
}

.username-alert-modal .alert-body {
    padding-bottom: 20px;
}

.username-alert-modal .popupcardbasicinfo {
    height: auto;
}

.username-alert-modal .go-profile {
    padding: 10px;
    background-color: var(--primary-color);
    color: white;
    margin: 0 auto;
    border-radius: 8px;
    font-size: 24px;
}


.highlight {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .8);
    position: relative;
    z-index: 9999;
    pointer-events: auto;
    transition: all 0.5s ease;
    background: none;
    padding: 10px;
}

body.highlight-is-active {
    pointer-events: none;
}

.collapse-button,
.cover {
    display: none;
}

/* Color settings */
.wrap-color-setter {
    width: 300px;
    margin: 0 auto;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.wrap-color-setter .color-setting {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.wrap-color-setter .color-setting .save-color {
    padding: 3px;
    background-color: black;
    color: white;
    cursor: pointer;
    border-radius: 8px;
}

.wrap-color-setter .color-setting .save-color:hover {
    background-color: rgb(0, 0, 0, .8)
}


@-webkit-keyframes rotate {

    10%,
    15% {
        opacity: 1;
        transform: translateY(0);
    }

    25%,
    36% {
        transform: translateY(calc(-1*var(--carousel-height)));
    }

    46%,
    57% {
        transform: translateY(calc(-1*var(--carousel-height)*2));
    }

    67%,
    99% {
        transform: translateY(calc(-1*var(--carousel-height)*3));
    }

}

@keyframes rotate {

    10%,
    15% {
        transform: translateY(0);
    }

    25%,
    36% {
        transform: translateY(calc(-1*var(--carousel-height)));
    }

    46%,
    57% {
        transform: translateY(calc(-1*var(--carousel-height)*2));
    }

    67%,
    99% {
        transform: translateY(calc(-1*var(--carousel-height)*3));
    }

    100% {
        transform: translateY(100%);
    }
}

.hdcp {
    color: var(--primary-color);
    font-size: 20px;
}

.golferCardstarsDiv .productHeading4 .hdcp {
    font-size: 24px;
}

.home .mobile-hero {
    display: none;
}

/* ProductCard */

/*!
 *
 * variables
 *
 */
/*!
 * do NOT modify breakpoints
 * @see `~~/store/ui/window.js`
 */
/*! green */
.purify_W-1OS4O9uEWK4580iP6BBA\=\= {
    position: relative
}

.purify_e\+DJa0jFwximOEN2rVNvhg\=\= {
    position: absolute;
    z-index: 2;
    top: 28px;
    left: 0;
    background-color: #fff;
    opacity: .9;
    max-height: 0;
    min-width: 70px;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08)
}

.purify_e\+DJa0jFwximOEN2rVNvhg\=\=.purify_I0IoxSojEP7n6q3xQuy7dA\=\= {
    box-shadow: 0 -15px 30px 0 rgba(0, 0, 0, .11), 0 -5px 15px 0 rgba(0, 0, 0, .08)
}

.purify_e\+DJa0jFwximOEN2rVNvhg\=\=.purify_UbM2TOWhdcdSBuJ7leBGZw\=\= {
    max-height: 260px;
    visibility: visible;
    animation: purify_ReJQpaxXzpE5ojb5qDz6Iw\=\= 60ms ease-in
}

.purify_F\+jmK5JQ3d12dhjDG76T0w\=\= {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: normal;
    color: #000;
    border-bottom: 1px solid #ebebeb;
    padding: 15px 10px;
    display: flex;
    cursor: pointer
}

.purify_F\+jmK5JQ3d12dhjDG76T0w\=\=>div {
    margin-right: 5px
}

.purify_F\+jmK5JQ3d12dhjDG76T0w\=\=:hover {
    background-color: #f4f4f4
}

.purify_F\+jmK5JQ3d12dhjDG76T0w\=\=:last-child {
    border: 0
}

@keyframes purify_ReJQpaxXzpE5ojb5qDz6Iw\=\= {
    0% {
        max-height: 0;
        opacity: 0
    }

    to {
        max-height: 900px;
        opacity: 1
    }
}

.purify_trih\+CyunkYh7sl7YfpK4Q\=\= {
    display: flex;
    max-width: 75px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #484848;
    cursor: pointer;
    line-height: 20px
}

.purify_trih\+CyunkYh7sl7YfpK4Q\=\=>p {
    font-size: 14px;
    color: #484848;
    margin: 0 5px
}

.purify_VCYptOmWNzRA\+JD0ruCBvg\=\= {
    margin-top: -5px;
    color: #c2c2c2
}

.purify_u\+1YM373LYtGB91qGBizDw\=\= {
    font-size: 21px;
    height: auto
}

.purify_u\+1YM373LYtGB91qGBizDw\=\= svg {
    border-radius: 3px
}

/*!
 *
 * variables
 *
 */
/*!
 * do NOT modify breakpoints
 * @see `~~/store/ui/window.js`
 */
/*! green */
.purify_mUkgbR5wPhv0YD-FzVCMXw\=\= {
    display: block;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 6px;
    padding: 10px 10px 10px 17px
}

@media only screen and (max-width: 959px) {
    .purify_mUkgbR5wPhv0YD-FzVCMXw\=\= {
        font-size: .5rem
    }
}

.purify_f3VZibu61lZ8dOO2\+3NMYw\=\= {
    background-color: #fafafa;
    width: 100%
}

.purify_f3VZibu61lZ8dOO2\+3NMYw\=\= input {
    text-overflow: ellipsis;
    background-color: hsla(0, 0%, 100%, 0)
}

@media only screen and (min-width: 960px) {
    .purify_f3VZibu61lZ8dOO2\+3NMYw\=\= input {
        border-radius: 6px
    }
}

.purify_UP58v5LgD\+Z1-UdmmrcgaA\=\= {
    text-overflow: ellipsis;
    text-transform: uppercase;
    font-size: 14px;
    width: 60%
}

@media only screen and (max-width: 959px) {
    .purify_UP58v5LgD\+Z1-UdmmrcgaA\=\= {
        font-size: .8rem
    }
}

.purify_L\+gzT9WaUaxlXQ-cwCDoMQ\=\= {
    display: block;
    color: red
}

.purify_Nyfl6znxIzibi6fgMDqJSg\=\= {
    margin-top: 3px;
    margin-right: 5px
}

/*!
 *
 * variables
 *
 */
/*!
 * do NOT modify breakpoints
 * @see `~~/store/ui/window.js`
 */
/*! green */
.purify_cmTcNdmK0kI5aOb3laN2ww\=\= {
    position: relative
}

.purify_cmTcNdmK0kI5aOb3laN2ww\=\= img {
    max-width: 100%
}

.purify_dJfHaOwilFdXvonOp3-YTQ\=\= {
    overflow: hidden
}

.purify_dJfHaOwilFdXvonOp3-YTQ\=\= img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

/*!
 *
 * variables
 *
 */
/*!
 * do NOT modify breakpoints
 * @see `~~/store/ui/window.js`
 */
/*! green */

.wrap-product-cards {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    gap: 150px;
    justify-content: center;
}

.product-card {
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.product-card .title {
    font-size: 30px;
}

/* End Product Card */

.swal2-image {
    width: 200px !important;
    height: 200px !important;
    border-radius: 50%;
}

.MuiDialog-root {
    z-index: 100000 !important;
}

input {
    -webkit-text-size-adjust: 100%;
    /* Disable text zooming on iOS */
}

/* Responsive */

@keyframes paralex {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 100;
    }
}

@media only screen and (min-width: 1174px) {
    .burger {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1074px) {

    #box-tablet {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 723px) {
    .home .mobile-hero {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .home .productHeader {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .home .productHeader .sideText {
        margin-top: 30px;
        height: auto;
    }

    .carousel {
        font-size: 18px;
        --carousel-height: 30px;
        flex-direction: column;
        height: auto;
    }

    .carousel .pre i {
        font-family: 'Brush Script MT', cursive;
        font-size: 24px;
    }

    .carousel .change_outer {
        justify-content: center;
    }

    .carousel .change_outer .element {
        display: block;
        font-size: 18px;
        text-shadow: 1px 1px 1px #3c2254;
        text-align: center;
    }

    .home .productHeader .leftsideText {
        height: auto;
    }

    .home .header h1 {
        font-size: 5em;
    }

    .home #box-mobile {
        display: none;
        visibility: hidden;
    }

    .home .poweredBy {
        width: auto;
        height: 100px;
        z-index: 1000;
        position: static;
    }

    .navheader {
        background-color: white;
        padding-top: 10px !important;
    }

    .navheader,
    .on-scroll.navheader {
        border-bottom: 2px solid var(--primary-color);
    }

    .placeholder-nav-line-1,
    .placeholder-nav-line-2 {
        height: 2px;
        display: block;
    }

    .navBtns {
        color: var(--primary-color);
    }

    .navbar .logo {
        width: 50px;
        height: 50px;
    }

    .product-title-mobile {
        display: flex;
        color: var(--primary-color);
    }

    .home .hero {
        display: none;
    }

    .nav-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-item:first-child>span {
        margin-right: auto;
    }

    div.container {
        width: 100%;
        padding: 0 1rem;
    }

    .home .header {
        padding-top: 100px;
        height: auto;
        background-image: url('../Assets/Images/home-image.png');
        background-position: 0px 60px;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: none;
        height: 270px;
    }

    .home .headerBtn1 {
        padding: 6px 10px;
        gap: 10px;
        font-size: 16px;
        margin-top: 20px;
    }

    .home .headerBtn1 img {
        display: none;
    }

    .home .header h2 {
        font-size: 24px;
        padding-top: 20px;
        line-height: 30px
    }

    .home .headerBtns {
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .home .HowItWorksInner .box {
        gap: 4px;
        min-height: 100px;
    }

    .home .HowItWorksInner .box img {
        height: 60px;
        width: 60px;
    }

    .golferCards img {
        width: 120px;
        height: 120px;
    }

    .home .golferCardstarsDiv {
        gap: 10px;
    }

    .home .popupcardbasicinfo .golfer-location {
        font-size: 14px;
    }

    .home .popupcardbasicinfo .fa-crosshairs {
        font-size: 1em;
    }

    .home .popupcardbasicinfo .productHeading4 p {
        font-size: 0.8em;
    }

    .home .popupcardbasicinfo .productHeading4 span {
        font-size: 0.8em;
    }

    .home .popupCardAbout {
        max-height: 140px;
        overflow: hidden;
        padding-bottom: 10px;
    }

    .home .popupCardAbout.show {
        max-height: unset;
        overflow: hidden;
    }

    .horizLine {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .home .cardButton {
        padding: 5px 20px;
    }

    .testimonialCard img {
        width: 30px;
    }

    .home .testimonialCard p {
        margin: 10px 0;
        font-size: 16px;
        line-height: 24px;
    }

    .home .testimonialBottomLeft img {
        height: 80px;
        width: 80px;
        border: 2px solid var(--primary-color);
    }

    .home .sideText .sortby img {
        height: 15px;
        padding-left: 5px;
    }

    .home .popBasicCardInfo img {
        width: 50px !important;
    }

    .home .productDetailCardHeading1 {
        font-size: 16px !important
    }

    .home .popupCardAbout p {
        font-size: 0.8rem !important;
        line-height: 1rem !important;
    }

    .home .popupCardDetails {
        gap: 15px !important;
    }

    .home .popupCardAbout {
        gap: 5px !important;
    }

    .home .popupCardBigTags {
        gap: 8px !important;
    }

    .home .popupCardAbout h4,
    .home .popupCardBigTags h4 {
        font-size: 1rem !important;
    }

    .home .BigTag {
        padding: 2px 10px !important;
        font-size: 0.7rem !important;
        min-height: 30px !important;
        height: auto !important;
    }

    .home .popupcardbasicinfo {
        gap: 10px;
        height: 80px;
    }

    .home .golfer-info-stars {
        font-size: 14px;
    }

    .home .productCardRating {
        gap: 5px;
    }

    .home .popupCardTags {
        margin-top: 15px;
    }

    .golfer-handicap {
        font-size: 1.2rem;
    }

    .golfer-locations .dropdown-row {
        font-size: 1rem;
    }

    .home .sideText .sortby {
        gap: 5px;
    }

    .home .wrap-sort-by {
        top: 145px;
    }

    .home .testimonialSection {
        padding: 30px 0;
    }

    .home .testimonialCard {
        margin: 10px 0;
        padding: 20px;
    }

    .home #closecarddetailspopup {
        width: 20px;
        height: 20px;
    }

    .hdcp-lbl {
        font-size: 18px;
    }

    .hdcp {
        font-size: 18px;
    }
}

@media only screen and (max-width: 481px) {
    .home .testimonialBottom {
        display: block;
    }

    .footer ul {
        display: flex;
        flex-direction: column;

    }

    .home .testimonialBottom .stars {
        margin-top: 20px;
    }

    .home .header h1 {
        font-size: 3em;
    }

    .home .headerBtns {
        flex-direction: column;
    }

    .home .faq_wrapper {
        display: grid;
        --auto-grid-min-size: 200px;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }

    .footerLogo {
        width: 40px;
    }

    .footer ul {
        gap: 10px;
    }

    .footer .wrap-icons .poweredBy {
        width: auto;
        width: 50px;
        bottom: 0;
        right: 0;
    }

    .wrap-footer-main .logo-tm {
        gap: 5px;
    }

    .wrap-footer-main .logo-tm p {
        font-size: 10px;
    }

    .footer .wrap-icons {
        padding: 3px;
        gap: 5px;
    }

}

@media only screen and (max-width: 621px) {

    .home .HowItWorksInner {
        display: flex;
        flex-direction: column;
    }

    .home .HowItWorksInner .box {
        height: fit-content;
    }

    .form-header img {
        width: 100px;
        padding: 0px;
    }

    .emailpass-input {
        padding: 4px 8px;
    }

    .submit-email {
        padding: 12px 16px;
    }

    .forgot-password {
        justify-content: space-between;
        font-size: 12px;
    }

    .text-body-2 {
        font-size: 12px;
    }

    .forgot-password .text-body-2 {
        font-size: 12px;
    }

    .signing-footer,
    .link-button {
        font-size: 14px;
    }

    .icon-button {
        padding: 6px 15px 6px 15px;
    }

    .icon-button p {
        font-size: 16px;
    }

    .sign-up-form {
        width: 100%;
        min-width: unset;
        padding: 10px;
    }

    .form-separator {
        margin: 10px 0 20px;
    }

    .swal2-image {
        width: 100px !important;
        height: 100px !important;
    }

    body {
        padding-bottom: env(safe-area-inset-bottom);
    }

    .MuiButton-root {
        height: 0;
        padding: 0 !important;
    }
}

@media only screen and (max-width: 1174px) {
    .burger {
        display: block;
        visibility: visible;
    }

    .on-scroll li {
        cursor: pointer;
        transition: 1s;
        color: black;

    }

    .navbar.is-active {
        background-color: white;
        color: black !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .navBtns2 {
        display: none;
        visibility: hidden;
        max-width: 200px;
    }

    .navbar.is-active .navBtns2 {
        display: flex;
        visibility: visible;
        color: black;
        font-size: 20px;
        border: 1px solid black;
        align-items: center;
    }

    .navbar.is-active .navBtns1 {
        display: flex;
        visibility: visible;
    }

    .footer ul {
        visibility: visible;
        display: flex;
        gap: 20px;
    }

    .navbar.is-active ul {
        display: flex;
        flex-direction: column;
        visibility: visible;
        text-align: center;
        margin: auto;
        width: 100vw;
        gap: 20px;
        align-items: center;
    }

    .navbar.is-active .logo {
        position: absolute;
        top: 10px;
        left: 20px;
        background-image: url("../Assets/Images/LogoBlack.svg");
        width: 40px;
        height: 40px;
    }

    .navbar.is-active .burger {
        position: absolute;
        top: 53px;
        right: 40px;
    }

    .navbar.is-active .navbar {
        display: flex;
    }

    .navbar.is-active .navBtns1 {
        display: none;
    }

    .navBtns1 {
        display: none;
        visibility: hidden;
    }

    .navbar ul {
        display: none;
        visibility: hidden;
    }

    .navBtns {
        margin-left: 0px;
        flex-direction: column;
        gap: 10px;
    }

    .navBtns2 .arrowInBtn,
    .on-scroll .navBtns2 .arrowInBtn {
        background-image: url("../Assets/Images/ArrowRightBlack.png");
    }

}

/* Keyframes */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDelay {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
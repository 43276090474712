.content {
  align-self: stretch;
  flex: 1;
  background-color: var(--gray-25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-900);
  font-family: var(--text-md-semibold);
  min-width: 500px;
  height: 100%;
}

.backButton {
  display: none;
}

@media screen and (max-width: 600px) {
  .backButton {
    display: flex;
    align-self: flex-start;
    margin-left: 20px;
    gap: 5px;
    background-color: var(--secondary-color);
    border-radius: 4px;
    padding: 5px 10px;
    color: white;
    font-size: 16px;
  }

  .content {
    width: 100%;
    min-width: unset;
  }
}
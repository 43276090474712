.listingImage {
    object-fit: fill;
    max-height: 100%;
    max-width: 100%;
}

.featuredIcon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.text {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge {
    position: absolute;
    bottom: 8px;
    left: 8px;
    border-radius: var(--br-base);
    background-color: var(--gray-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
}

.heartIcon {
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    overflow: hidden;
}

.favoriteButton {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 36px;
    height: 36px;
    border-radius: var(--br-5xs);
    /* background-color: var(--color-gray-400); */
    background: url("/public/flag-grey.svg");
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid var(--color-gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-4xs);
    align-items: center;
    justify-content: center;
    transition: 200ms;
}

.favoriteButton:hover {
    background: url("/public/flag-red.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.image,
.text1 {
    align-self: stretch;
    position: relative;
}

.image {
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: 1px solid #dfdfdf;
}

.text1 {
    line-height: 30px;
    font-weight: 600;
    color: var(--secondary-color);
}

.textAndButton,
.textAndCategory {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndCategory {
    flex: 1;
    flex-direction: column;
}

.textAndButton {
    align-self: stretch;
    flex-direction: row;
    font-size: var(--text-xl-semibold-size);
}

.groupChild {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.51%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.87%;
}

.avatarInner,
.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarInner,
.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.avatarInner {
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.avatar,
.text2 {
    position: relative;
}

.avatar {
    border-radius: var(--br-181xl);
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.avatar img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
}

.text2 {
    line-height: 20px;
    font-weight: 500;
}

.textAndSubtext {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.supportingText {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content1,
.textAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    align-self: stretch;
}

.content1 {
    z-index: 1;
}

.dotIcon {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 2;
}

.wrapAvatar {
    align-items: center;
    position: relative;
    gap: var(--gap-xs);
    color: var(--gray-700);
    align-items: flex-start;
}

.badge1,
.badges,
.wrapAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.userName:hover {
    text-decoration: underline;
}

.badge1 {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-darkslategray-100);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    gap: var(--gap-9xs);
}

.badges {
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.text5 {
    position: relative;
    line-height: 30px;
    font-weight: 600;
    color: var(--primary-color);
}

.badgesParent,
.textAndSupportingText1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.badgesParent {
    align-self: stretch;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--color-darkslategray-100);
}

.text6 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    color: var(--gray-600);
}

.textAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.detailsWrap {
    align-items: flex-start;
    gap: var(--gap-24);
}

.details,
.detailsWrap,
.detailsWrap1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.detailsWrap1 {
    flex: 1;
    align-items: flex-start;
}

.details {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-24);
    color: var(--gray-600);
}

.spotsTargetChart,
.slotsIndicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 100%;
    height: 8px;
}

.slotsIndicator {
    background-color: var(--color-firebrick-100);
}

.chart {
    flex: 1;
    position: relative;
    height: 8px;
}

.spotsTarget,
.spotsFilled {
    position: relative;
    line-height: 140%;
}

.spotsFilled {
    font-weight: 600;
}

.spotsTarget {
    font-family: var(--text-md-regular);
    color: var(--gray-600);
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    color: var(--primary-color);
}

.container,
.detail,
.spots {
    display: flex;
    flex-direction: row;
}

.detail {
    padding-left: 20px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.container {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
}

.content,
.eventCard,
.textAndButton1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndButton1 {
    flex-direction: row;
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content,
.eventCard {
    flex-direction: column;
}

.content {
    padding: var(--padding-xl) var(--padding-base) var(--padding-base);
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.eventCard {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--error-700);
    font-family: var(--text-md-regular);
    min-width: 410px;
    max-width: 410px;
    transition: 200ms;
}

.eventCard.clickable {
    cursor: pointer;
}

.eventCard.clickable:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.rating {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
    align-items: flex-start;
}

.rating .supportingText span {
    font-weight: 700;
}

.supportingText {
    gap: 3px;
}

.starsWrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.star {
    position: relative;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    overflow: hidden;
}

.ratingWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.userName {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

.heartIcon {
    width: 15px;
    height: 15px;
}

@media screen and (max-width: 600px) {
    .eventCard {
        max-width: 100%;
        min-width: 100%;
    }

    .image {
        height: 200px;
    }

    .content {
        padding: var(--padding-xs) var(--padding-5xs) var(--padding-5xs);
        gap: var(--gap-xs);
    }

    .text1 {
        font-size: 18px;
    }

    .text6 {
        font-size: 14px;
    }

    .heartIcon {
        width: 20px;
        height: 20px;
    }

    .userName {
        font-size: 16px;
    }

    .avatar img {
        width: 50px;
        height: 50px;
    }

    .spotsTarget,
    .spotsFilled {
        font-size: 14px;
    }

    .text2 {
        font-size: 12px;
    }

    .text5 {
        font-size: 18px;
    }

    .starsWrap {
        gap: 1px;
    }

    .ratingWrap,
    .supportingText4 {
        font-size: 12px;
    }

    .heartIcon {
        width: 20px;
        height: 20px;
    }

}
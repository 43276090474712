.shareModal {
    max-width: 600px;
}

.dividerIcon,
.modalHeader {
    align-self: stretch;
    position: relative;
}

.modalHeader {
    background-color: var(--base-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.content {
    align-self: stretch;
    background-color: var(--base-white);
    flex-direction: row;
    padding: var(--padding-5xl) var(--padding-5xl) 0;
    gap: var(--gap-base);
    z-index: 0;
}

.content,
.textAndSupportingText {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-9xs);
}

.featuredIcon {
    position: relative;
    border-radius: var(--br-3xs);
    width: 52px;
    height: 52px;
}

.supportingText,
.text {
    align-self: stretch;
    position: relative;
}

.text {
    line-height: 28px;
    font-weight: 600;
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-900);
}

.supportingText {
    font-size: var(--text-sm-regular-size);
    line-height: 20px;
    color: var(--gray-600);
}


.buttonCloseX {
    margin: 0 !important;
    position: absolute;
    top: 12px;
    right: 12px;
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs);
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.dividerIcon {
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
    z-index: 3;
}

.xCloseIcon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.paddingBottom {
    align-self: stretch;
    height: 20px;
    z-index: 2;
}

.shareButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.shareButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    color: #333333;
    cursor: pointer;
    flex-direction: column;
    background: none;
}

.shareButton:hover {
    background-color: #dfdfdf;
}

.shareIcon {
    width: 50px;
    height: 50px;
}

@media screen and (max-width: 600px) {
    .content {
        padding: var(--padding-xs) var(--padding-xs) 0;
    }

    .featuredIcon {
        width: 40px;
        height: 40px;
    }

    .buttonCloseX {
        top: 5px;
        right: 5px;
    }

    .shareButtons {
        margin-top: 0px;
    }
}
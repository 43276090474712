.profileBg1Icon {
    width: calc(100% + 223px);
    top: 0;
    right: -335px;
    left: 112px;
    max-width: 100%;
    object-fit: cover;
}

.image,
.imageWrap,
.profileBg1Icon {
    height: 300px;
    overflow: hidden;
}

.image {
    width: 100%;
}

.imageWrap {
    align-self: stretch;
    position: relative;
    flex-shrink: 0;
}

.contentIcon {
    height: 100%;
    width: 100%;
    border-radius: var(--br-181xl);
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarProfilePhoto {
    position: relative;
    border-radius: var(--br-181xl);
    width: 160px;
    height: 160px;
    border: 2px solid white;
}

.homeLineIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.breadcrumbButtonBase {
    border-radius: var(--br-7xs);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.chevronRightIcon {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
}

.text {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.breadcrumbButtonBase1,
.breadcrumbButtonBase3 {
    border-radius: var(--br-7xs);
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
}

.breadcrumbButtonBase1 {
    display: flex;
}

.breadcrumbButtonBase3 {
    display: none;
}

.chevronRightIcon3 {
    position: relative;
    width: 16px;
    height: 16px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text3 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.breadcrumbButtonBase4 {
    border-radius: var(--br-7xs);
    background-color: var(--gray-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    color: var(--gray-700);
}

.breadcrumbs,
.tabs {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.tabs {
    display: flex;
    gap: var(--gap-5xs);
}

.breadcrumbs {
    display: none;
}

.text4 {
    position: relative;
    line-height: 26px;
    font-weight: 600;
    font-size: 20px;
}

.groupChild {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.56%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26.01%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.56%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.5%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.34%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.88%;
}

.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.groupWrapper {
    position: relative;
    width: 51.04px;
    height: 29.73px;
}

.textParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
}

.supportingText {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-600);
    align-items: center;
    gap: 3px;
}

.supportingText span {
    font-weight: 700;
}

.supportingText img {
    height: 15px;
    width: 15px;
}

.textAndSupportingText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--display-sm-semibold-size);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button,
.button1 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    padding: var(--padding-3xs) var(--padding-base);
    justify-content: center;
    gap: var(--gap-5xs);
}

.button {
    display: none;
    flex-direction: row;
    align-items: center;
}

.button1 {
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
}

.actions,
.button1,
.button2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button2 {
    border-radius: var(--br-5xs);
    background-color: var(--error-700);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--error-700);
    overflow: hidden;
    padding: var(--padding-3xs) var(--padding-base);
    justify-content: center;
    gap: var(--gap-5xs);
    color: var(--base-white);
    cursor: pointer;
}

.button2:hover {
    opacity: 0.8;
}

.actions {
    justify-content: flex-start;
    gap: var(--gap-xs);
    color: var(--gray-600);
}

.label,
.text8 {
    position: relative;
}

.label {
    line-height: 20px;
    font-weight: 500;
    display: none;
}

.text8 {
    line-height: 24px;
}

.content1,
.input,
.inputWithLabel {
    display: flex;
    justify-content: flex-start;
}

.content1 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-5xs);
}

.input,
.inputWithLabel {
    align-self: stretch;
}

.input {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
    width: 320px;
}

.inputDropdown {
    width: 320px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.heading {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-base);
    color: var(--gray-700);
}

.avatarAndContent,
.content,
.heading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content {
    flex: 1;
    flex-direction: column;
    padding: var(--padding-45xl) 0 0;
    gap: var(--gap-xl);
}

.avatarAndContent {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-5xl);
}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.container,
.pageHeader {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container {
    padding: 0 var(--padding-32xl);
    align-items: flex-start;
    gap: var(--gap-5xl);
    margin-top: -40px;
}

.pageHeader {
    align-items: center;
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
    font-family: var(--text-md-regular);
}

@media screen and (max-width: 600px) {
    .imageWrap {
        height: 150px;
    }

    .image,
    .profileBg1Icon {
        height: 100%;
    }

    .profileBg1Icon {
        object-fit: cover;
    }

    .container {
        padding: 0 10px;
        margin-top: 0px;
        gap: 10px;
    }

    .avatarAndContent {
        gap: 10px;
        flex-direction: column;
    }

    .avatarProfilePhoto {
        width: 100px;
        height: 100px;
        margin-top: -50px;
    }

    .content {
        padding: 5px 0px 0px;
        width: 100%;
    }

    .text4 {
        line-height: unset;
    }

    .supportingText img {
        width: 15px;
        height: 15px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;

    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */

    cursor: pointer;
}

html {
    font-family: 'Nunito Sans', sans-serif;
}

.signup {
    min-height: 1220px;
}

/* Nav Bar */
.signup .navheader {
    padding-top: 10px;
    padding-bottom: 0px;
    height: 143px;
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transition: all 0.35s ease;
    border-bottom: 1px solid rgba(57, 57, 57, 0.033);
}

.signup .navbar {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

}

.signup .navbar.is-active {
    color: black;

}

.signup .navbar .on-scroll {
    color: black;
}

.signup .on-scroll {
    background-color: black;
}

.signup .logo {
    width: 90px;
    height: 90px;
    background-image: url('../Assets/Images/Logo.png');
    background-position: center;
    background-repeat: no-repeat;
}

.signup .navbar.is-active .logo {

    background-image: url('../Assets/Images/navlogoBlack.png');
}

.signup .on-scroll .logo {
    background-image: url('../Assets/Images/Logo.png');
}

.signup ul {
    list-style: none;
    display: flex;
    justify-content: center;


}

.signup .navbar ul {
    line-height: 50px;
}

.signup .active {
    font-weight: 700;
    border-bottom: 2px solid white;

}

.signup .navBtns1 {
    font-family: Nunito Sans;
    font-size: 1em;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    transition: 1s;
    height: 50px;


}

.signup .navBtns2 .arrowInBtn {
    width: 30px;
    height: 8px;
    background-image: url('../Assets/Images/buttonArrowWhite.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signup .on-scroll .navBtns2 .arrowInBtn {
    width: 30px;
    height: 8px;
    background-image: url('../Assets/Images/buttonArrowWhite.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.signup .on-scroll .navBtns1 {
    color: white;
}

.signup .navBtns1:hover {

    letter-spacing: 2px;
}

.signup .navBtns2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 5px;

    font-weight: 400;
    color: #FFFFFF;
    height: 50px;
    padding: 0px 20px;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    transition: 1s;

    cursor: pointer;
}

.signup .on-scroll .navBtns2 {
    color: white;
    border: 1px solid white;
}

.signup .navBtns2:hover {
    gap: 40px;
    transition: 1s;
}

/* hero section*/
.signup .header {
    width: 100%;
    height: 350px;
    background-image: url('../Assets/Images/moneyball.jpg');
    background-position: 50% 20%;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 160px;
    box-shadow: inset 0px -19px 39px rgba(0, 0, 0, 0.7);
}


/* Profile Picture Section*/
.signup .ProfilePictureSection {
    height: fit-content;
    width: 100%;
}

.signup .ProfilePictureMain {
    align-items: center;
    gap: 15px;
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
    width: 200px;
    height: 200px;
}

.signup .profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.signup .chooseProfileBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 36px;
    margin-bottom: 0px;

}

.signup .chooseProfileBtn button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 150px;
    height: 35px;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    background: #F2F2F2;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
}

.signup .chooseProfileBtn button:hover {
    transition: .5s linear;
    background: #d6d6d6;
}

.signup .chooseProfileBtn p {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #000000;

}

.wrap-signup-golfer {
    width: 70vw;
}

.signup form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 40px;
    gap: 20px;
    width: 100%;
}

.signup .inputRowGroup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.signup .inputFieldComponent {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    width: 100%;
}

.signup .inputFieldComponent.shortText {
    max-width: 300px;
}

.signup .inputFieldComponent.longText {
    max-width: 700px;
}


.signup .inputFieldComponent h3 {

    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.signup .searchField {
    display: flex;
    flex-direction: row;
    width: calc((50%) - 10px);
}

.signup .searchField input {
    border-radius: 10px 0 0 10px;
}

.signup .Tags {
    margin-top: 6px;
    margin-bottom: 10px;
    width: 100%;
    display: grid;
    --auto-grid-min-size: 200px;
    grid-gap: 10px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

.signup .close {
    cursor: pointer;
}

.signup .Tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 15px;
    gap: 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #000000;
    height: 50px;
    background: rgba(9, 176, 81, 0.2);
    border-radius: 300px;
    justify-content: space-between;
}

.signup .Tag span {
    display: flex;
    gap: 10px;
    align-items: center;
}

.signup .inputField {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #000000;
}

.signup .inputField.textarea {
    height: 150px;
    width: 500px;
    font-size: 14px;
    font-weight: unset;
}

.signup .inputField::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);

}

.signup .inputField:-ms-input-placeholder {
    /* Internet Explorer 10-11 */

    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);
}

.signup .inputField::-ms-input-placeholder {
    /* Microsoft Edge */

    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.4);
}

.signup textarea.inputField {
    height: 80px;
}

.signup .sliderLine {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 10px 0;
    background: rgba(46, 40, 54, 0.1);
    border-radius: 4px;
}

.signup .sliderProgressLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 400px;
    padding: 3px;
    height: 40px;

    position: absolute;
    width: 90%;

    background: linear-gradient(0deg, #2E2836, #2E2836), linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(270deg, #58C5F4 2.48%, #297CC9 100%);
    border-radius: 4px;
}

.signup .sliderDot {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    border: 3px solid black;

    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F5E9BD;
    border-radius: 4px;
    cursor: pointer;
}

.signup .sliderDot::before {
    content: "$250";
    position: absolute;
    top: -40px;
    left: -25%;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 44px;
    /* identical to box height */


    color: #008000;
}

.signup .awards-btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 200px;
    align-items: center;
    margin: auto;
    height: 50px;
    background: var(--primary-color);
    border-radius: 10px;
    color: white;

}

.signup .greenBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    width: 400px;
    align-items: center;
    margin: auto;
    height: 50px;
    background: var(--secondary-color);
    border-radius: 10px;
    color: white;
    cursor: pointer;
}

/* Footer */

.signup .footerSection {
    width: 100%;
    background-image: url('../Assets/Images/footerBackground.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.signup .poweredBy {
    width: auto;
    height: 100px;
    z-index: 1000;
    position: absolute;
    bottom: 0;
    right: 0;
}

.signup .footer {
    padding: 10px 0;
    position: relative;
}

.signup .footer img {
    margin: auto;
    margin-bottom: 50px;
    margin-top: 20px;
}

.signup .footer p {
    margin-top: 50px;
}


.signup .footer ul a li {
    color: black;
    border: none;
}

.signup .profile-pic-input,
.signup .awards-input {
    display: none;
}

.signup .profile-pic-edit {
    padding-top: 10%;
    padding-right: 10%;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
}

.signup .ProfilePictureMain:hover .profile-pic-edit {
    display: inline-block;
}

.signup .ProfilePictureMain:hover .profilePicture {
    box-shadow: 0 0 8px #95979d;
}

.wrap-user-info .info {
    display: flex;
    flex-direction: column;
}

.wrap-user-info .info .username {
    font-size: 32px;
}

.swal2-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Awards */
.signup-form-fields .field-row div.files {
    background: #eee;
    padding: 1rem;
    border-radius: 10px;
    min-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    width: 500px;
}

div.files ul {
    list-style: none;
    padding: 0;
    max-height: 150px;
    overflow: auto;
    display: block;
}

div.files ul li {
    padding: 0.5rem 0;
    padding-right: 2rem;
    position: relative;
}

div.files ul li i {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translatey(-50%);
}

div.container {
    width: 100%;
    padding: 0 2rem;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

.position-found {
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.position-found .img-loc {
    width: 20px;
    margin-right: 5px;
}

.position-found .img-edit {
    margin-left: 10px;
    width: 22px;
    cursor: pointer;
}

.position-found .img-edit:hover {
    opacity: 0.8;
}

.ghin-input {
    max-width: 200px;
}

.signup .search-container {
    max-width: 500px;
    flex-direction: row;
}

.signup-form-wrapper {
    padding: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    background-color: #ffffff;
    border-radius: 8px;
    flex-direction: column;
    gap: 40px;
    display: flex;
    width: 900px;
    align-items: center;
    margin: 0 auto;
}

.signup-form-wrapper .signup-title {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.signup-form-wrapper p {
    width: 600px;
    color: #787879;
    font-size: 16px;
}

.signup-form-fields {
    flex-direction: column;
    gap: 20px;
    display: flex;
}

.signup-form-fields .field-row {
    display: flex;
    flex-direction: row;
}

.signup-form-fields .field-row p {
    display: flex;
    width: 150px;
    justify-content: left;
    font-size: 18px;
}

.signup-form-fields .field-row div {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.signup-form-fields h2 {
    font-size: 1.5rem;
}

.signup-form-fields input {
    max-width: 400px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: 700;
    line-height: 1.5;
    outline: none;
    padding: 0.75rem 1rem;
    color: var(--primary-color);
}

.signup-form-fields input:focus {
    outline: none;
    border-color: #007bff;
}

.signup-form-fields button {
    padding: 0.5rem;
    margin: 1rem
}

.ProfilePictureSection .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* STEPS */

.step-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 50px;
}

.step-wrapper nav {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
}

.step-wrapper nav:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ccc;
    z-index: -1;
}

.step-wrapper button {
    font-size: 14px;
    padding: 10px 20px;
    background-color: #dfdfdf;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    text-align: left;
    z-index: 1;
}

.step-wrapper button.active,
.step-wrapper button.completed {
    background-color: var(--primary-color);
    color: white;
}

.step-wrapper button.disabled {
    cursor: not-allowed;
}

.step-wrapper button span {
    font-weight: bold;
    margin-right: 5px;
}

.signup-form-fields .field-row.accreditations .award-wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.signup-form-fields .field-row div .wrap-license-upload {
    display: flex;
    flex-direction: column;
}

.signup-form-fields .field-row div .wrap-license-upload img {
    width: 100%;
    max-width: 400px
}

.signup .google-search-div ul {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-top: none;
    list-style-type: none;
    margin: 0;
    max-height: 60%;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    text-align: left;
    gap: 0px;
    display: block;
    z-index: 1200;
    margin-top: 50px;
}

/* Responsive */

@keyframes paralex {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 100;
    }
}

@media only screen and (min-width: 1174px) {
    .signup .burger {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1074px) {
    .signup body {
        overflow-x: hidden;
    }

    .signup .header {
        height: 200px;
        background-position: 50% -90%;
    }

    .signup .checkOutSection .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 80px;
        width: 100%;
    }

    .signup #showcarddetailspopup {
        order: 2;
    }

    .signup #b {
        order: 1;
    }

    .signup .greenBtn {
        width: 150px;
    }

    .signup .priceSlider {
        max-width: 75%
    }

    .signup .priceSlider-track {
        height: 7px;
        top: 9px;
    }

    .signup .priceSlider-mark {
        top: 6px;
        height: 14px;
    }

    .signup .priceSlider-thumb {
        width: 24px;
        height: 24px;
    }

    .signup .priceslider-value {
        margin-left: 80%
    }

    .signup .travelSlider {
        max-width: 75%
    }

    .signup .travelSlider-track {
        height: 7px;
        top: 9px;
    }

    .signup .travelSlider-mark {
        top: 6px;
        height: 14px;
    }

    .signup .travelSlider-thumb {
        width: 24px;
        height: 24px;
    }

    .signup .travelslider-value {
        margin-left: 80%
    }

    .signup .ProfilePictureMain {
        width: 100px;
        height: 100px;
        margin-top: -50px;
    }

    .wrap-user-info {
        display: inline-block;
        position: absolute;
        margin-top: 8px;
        margin-left: 10px;
    }

    .wrap-user-info .info .username {
        font-size: 24px;
    }

    .wrap-user-info .info .email {
        font-size: 12px;
    }

    .signup .inputRowGroup {
        gap: 18px;
        margin-top: 10px;
    }

    .signup,
    .accreditations .inputFieldComponent h3 {
        text-align: center;
    }

    .signup .accreditations .inputFieldComponent .searchField {
        margin: 0 auto;
        justify-content: center;
    }

    .signup .awards-btn {
        background: rgb(129, 129, 129);
        margin-top: 5px;
        width: 150px;
        height: 40px;
    }

    .signup .greenBtn {
        margin-top: 40px;
        width: 200px;
        font-size: 24px;
    }

}

@media only screen and (max-width: 723px) {
    .signup .inputRowGroup {
        flex-direction: column;
    }

    .signup .searchField {
        width: 100%;
    }

    .signup .inputFieldComponent {
        flex-direction: column;
    }

    .signup .productHeader {
        display: block;
        align-items: center;
    }

    .signup .clientStories {
        height: 750px;
    }

    .signup .header h1 {
        font-size: 5em;
    }

    .signup #box-mobile {
        display: none;
        visibility: hidden;
    }

    .signup .poweredBy {
        width: auto;
        height: 100px;
        z-index: 1000;
        position: static;
    }
}

@media only screen and (max-width: 481px) {
    .signup .testimonialBottom {
        display: block;
    }

    .signup .stars {
        font-size: .55em;
    }

    .signup .popupCardAbout p {
        font-size: 1.3em;
    }

    .signup .popupCardBigTags h4 {
        font-size: 1em;
    }

    .signup .tag {
        font-size: 10px;
    }

    .signup .footer ul {
        display: flex;
        flex-direction: column;

    }

    .signup .testimonialBottom .stars {
        margin-top: 20px;
    }

    .signup .header h1 {
        font-size: 3em;
    }

    .signup .headerBtns {
        flex-direction: column;
    }

    .signup .faq_wrapper {
        display: grid;
        --auto-grid-min-size: 200px;
        grid-gap: 20px;
        grid-template-columns:
            repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }
}

@media only screen and (max-width: 621px) {
    .signup .HowItWorksInnerStatistic {
        display: flex;
        flex-direction: column;
    }

    .signup .clientStoriesWrapper {

        flex-direction: column;
    }

    .signup .clientStories {
        height: 1200px;
    }

    .signup .clientStoriesSection {
        padding: 0;
    }

    .signup .HowItWorksInner {
        display: flex;
        flex-direction: column;
    }

    .signup .HowItWorksInner .box {
        height: fit-content;
    }
}

@media only screen and (max-width: 1174px) {
    .signup .burger {
        display: block;
        visibility: visible;
    }

    .signup .on-scroll li {
        cursor: pointer;
        transition: 1s;
        color: black;

    }

    .signup .navbar.is-active {
        background-color: white;

        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding-left: 20px;
        padding-right: 40px;
    }

    .signup .navbar.is-active .navBtns2 {
        display: flex;
        visibility: visible;
    }

    .signup .navbar.is-active .navBtns1 {
        display: flex;
        visibility: visible;
    }

    .signup .footer ul {
        visibility: visible;
        display: flex;
        gap: 40px;
    }

    .signup .navbar.is-active ul {
        display: flex;
        flex-direction: column;
        visibility: visible;
        text-align: center;
        margin: auto;
        width: 100vw;
    }

    .signup .navbar.is-active .logo {
        position: absolute;
        top: 10px;
        left: 20px;
    }

    .signup .navbar.is-active .burger {
        position: absolute;
        top: 53px;
        right: 40px;
    }

    .signup .navbar.is-active .navbar {
        display: flex;
    }

    .signup .navbar.is-active .navBtns1 {
        display: none;
    }

    .signup .navbar.is-active .navBtns2 {
        display: none;
    }

    .signup .navBtns2 {
        display: none;
        visibility: hidden;
    }

    .signup .navBtns1 {
        display: none;
        visibility: hidden;
    }

    .signup ul {
        display: none;
        visibility: hidden;
    }
}
.badgeIcon {
    position: relative;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    object-fit: cover;
}

.heading {
    position: relative;
    line-height: 32px;
    font-weight: 600;
    color: var(--primary-color);
}

.flag05Icon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.text {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.badge,
.headingAndSubheading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.seeAll {
    cursor: pointer;
}

.seeAll:hover {
    opacity: 0.9;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    gap: var(--gap-9xs);
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--color-darkslategray-100);
}

.headingAndSubheading {
    align-self: stretch;
    gap: var(--gap-xl);
}

.headingAndSupportingText {
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-base);
}

.subheading {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    background-color: var(--secondary-color);
    color: white;
    border-radius: 8px;
    padding: 6px 10px;
}

.arrowLeftIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.arrowWrapper,
.frameParent,
.subheadingParent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.arrowWrapper {
    border-radius: var(--br-29xl);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    padding: var(--padding-3xs);
    cursor: pointer;
}

.arrowWrapper:hover {
    background-color: #dfdfdf;
    transition: 200ms;
}

.frameParent,
.subheadingParent {
    gap: var(--gap-3xs);
}

.subheadingParent {
    align-items: center;
    gap: var(--gap-24);
    font-size: var(--text-md-regular-size);
    color: var(--error-700);
}

.content,
.title {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.title {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.image5Icon,
.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
}

.image5Icon {
    width: 739px;
    height: 258px;
    object-fit: cover;
}

.imageOverlay {
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
}

.award02Icon {
    position: relative;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    overflow: hidden;
}

.text1 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge1,
.button {
    position: absolute;
    bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badge1 {
    left: 8px;
    border-radius: var(--br-base);
    background-color: var(--error-50);
    padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
}

.button {
    right: 8px;
    border-radius: var(--br-5xs);
    background-color: var(--color-gray-400);
    backdrop-filter: var(--background-blur-md);
    border: 1px solid var(--color-gray-300);
    overflow: hidden;
    padding: var(--padding-xs);
    justify-content: center;
}

.image,
.text2 {
    align-self: stretch;
    position: relative;
}

.image {
    height: 208px;
    flex-shrink: 0;
    overflow: hidden;
}

.text2 {
    line-height: 30px;
    font-weight: 600;
}

.textAndButton,
.textAndCategory {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndCategory {
    flex: 1;
    flex-direction: column;
}

.textAndButton {
    align-self: stretch;
    flex-direction: row;
    font-size: var(--text-xl-semibold-size);
}

.groupChild {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.51%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.87%;
}

.avatarInner,
.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarInner,
.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.avatarInner {
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.avatar {
    position: relative;
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    background-image: url(/public/avatar1@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.textAndSubtext {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.supportingText {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.content2,
.textAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    align-self: stretch;
}

.content2 {
    z-index: 1;
}

.dotIcon {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 2;
}

.wrapAvatar {
    align-items: center;
    position: relative;
    gap: var(--gap-xs);
    color: var(--gray-700);
}

.badge2,
.badges,
.wrapAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.badge2 {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-darkslategray-100);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    gap: var(--gap-9xs);
}

.badges {
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.text6 {
    position: relative;
    line-height: 30px;
    font-weight: 600;
}

.badgesParent,
.textAndSupportingText1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.badgesParent {
    align-self: stretch;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--color-darkslategray-100);
}

.text7 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}

.textAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.detailsWrap {
    align-items: flex-start;
    gap: var(--gap-24);
}

.details,
.detailsWrap,
.detailsWrap1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.detailsWrap1 {
    flex: 1;
    align-items: flex-start;
}

.details {
    align-self: stretch;
    align-items: flex-end;
    gap: var(--gap-24);
    color: var(--gray-600);
}

.spotsTargetChart,
.slotsIndicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 228px;
    height: 8px;
}

.slotsIndicator {
    background-color: var(--color-firebrick-100);
    width: 136.8px;
}

.chart {
    flex: 1;
    position: relative;
    height: 8px;
}

.spotsTarget,
.spotsFilled {
    position: relative;
    line-height: 140%;
}

.spotsFilled {
    font-weight: 600;
}

.spotsTarget {
    font-family: var(--text-md-regular);
    color: var(--gray-600);
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.container,
.detail,
.spots {
    display: flex;
    flex-direction: row;
}

.detail {
    width: 117px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.container {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
}

.content1,
.listingSearchResult,
.textAndButton1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndButton1 {
    flex-direction: row;
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content1,
.listingSearchResult {
    flex-direction: column;
}

.content1 {
    padding: var(--padding-xl) var(--padding-base) var(--padding-base);
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.listingSearchResult {
    flex: 1;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    overflow: hidden;
}

.image6Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 507px;
    height: 267px;
    object-fit: cover;
}

.eventArrow {
    cursor: pointer;
}

.eventsNearYou,
.featuredRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.featuredRow {
    align-self: stretch;
    flex-direction: row;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--error-700);
    padding: 10px;
}

.eventsNearYou {
    overflow: hidden;
    flex-direction: column;
    text-align: left;
    font-size: var(--display-xs-medium-size);
    color: var(--gray-900);
    font-family: var(--text-md-regular);
    width: 100%;
}

.noListings {
    color: var(--primary-color);
}

@media screen and (max-width: 600px) {

    .eventsNearYou {
        gap: var(--gap-5xs);
    }

    .title {
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 10px;
    }

    .headingAndSupportingText {
        flex-direction: column;
        align-items: flex-start;
        gap: var(--gap-6);
    }

    .headingAndSubheading {
        gap: var(--gap-5xs);
        width: 100%;
    }

    .badgeIcon {
        width: 40px;
        height: 40px;
    }

    .heading {
        font-size: 18px;
    }

    .text {
        font-size: 12px;
    }

    .frameParent {
        display: none;
    }

    .featuredRow {
        flex-direction: column;
    }

    .subheadingParent {
        padding-right: 10px;
    }

    .subheading {
        background-color: unset;
        color: var(--secondary-color);
    }

    .noListings {
        font-size: 14px;
        font-weight: 600;
    }
}
.illust1 {
    width: 156px;
    height: 104px;
    overflow: hidden;
}

.star1Icon {
    width: 96px;
    height: 96px;
    object-fit: cover;
}

.illust1Parent,
.success {
    position: relative;
    display: flex;
    align-self: center;
}

.success {
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    height: 20px;
    flex-shrink: 0;
}

.orderConfirmed {
    position: relative;
    font-size: var(--display-xs-medium-size);
    font-weight: 600;
}

.dolorEnimEu,
.orderNumber12345676 {
    align-self: stretch;
    position: relative;
}

.orderNumber12345676 {
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    height: 20px;
    flex-shrink: 0;
}

.dolorEnimEu {
    line-height: 24px;
    color: var(--gray-600);
}

.grid01Icon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.button {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    cursor: pointer;
}

.socialIcon {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
}

.socialButton {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: none;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
}

.cancel,
.socialShare {
    display: flex;
    align-items: center;
}

.cancel {
    flex-direction: row;
    justify-content: center;
    gap: var(--gap-xs);
}

.socialShare {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    color: var(--gray-700);
}

.successParent {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-40);
}

.frameParent,
.section,
.successParent {
    display: flex;
    flex-direction: column;
}

.frameParent {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.section {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: var(--text-md-semibold-size);
    color: var(--base-black);
    font-family: var(--text-sm-regular);
}

@media screen and (max-width: 600px) {
    .star1Icon {
        height: 60px;
        width: 60px;
    }

    .successParent {
        gap: var(--gap-base);
    }

    .frameParent {
        width: 100%;
    }
}
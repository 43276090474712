.container,
.section1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.section1 {
    flex: 1;
    flex-direction: column;
}

.container {
    flex-direction: row;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-self: flex-start;
    width: 100%;
}

.section {
    align-self: stretch;
    align-items: center;
    max-width: 1320px;
}

.main,
.profile,
.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.main {
    align-self: stretch;
    background-color: var(--gray-25);
    padding: 0 0 var(--padding-sm);
    align-items: center;
    gap: var(--gap-xs);
    margin-top: var(--header-height);
}

.profile {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    background-color: var(--base-white);
    width: 100%;
    align-items: flex-start;
}

.section {
    justify-content: center;
}

.wrapSummary {
    display: flex;
    flex-direction: column;
    padding: 0 0 100px 0;
    align-items: center;
    justify-content: center;
}

.wrapSummary,
.section1,
.content2 {
    width: 100%;
}

@media screen and (max-width: 600px) {

    .wrapSummary,
    .section1 {
        width: 100%;
    }

    .container {
        width: 100%;
        padding: 10px;
    }

    .sectionHeader {
        padding: 10px;
    }

    .section1 {
        gap: 20px;
    }


}
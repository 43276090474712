/* @import url('..https://fonts.googleapis.com/css2?family=Forum&display=swap'); */
/* @import url('..https://fonts.googleapis.com/css2?family=Forum&family=Nunito+Sans:wght@400;800&display=swap'); */

/* hero section*/
.checkoutSuccess .header {
    width: 100%;
    height: 100vh;
    background-image: url('../Assets/Images/home-image.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    justify-content: center;
    text-align: center;
    padding-top: 30vh;
    box-shadow: inset 0px -19px 39px rgba(0, 0, 0, 0.7);
    font-family: "Macan,Helvetica Neue,Helvetica,Arial,sans-serif";
}

.checkoutSuccess .header h1 {
    font-family: 'Brush Script MT', cursive;
    font-size: 7em;
    font-weight: 400;
    animation: paralex 1s;
}

.checkoutSuccess .header h2 {
    font-family: Nunito Sans;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 10px;
    animation: paralex 1s;
}

.checkoutSuccess .headerBtns {
    margin-top: 7vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 67px;
    justify-content: center;
    animation: paralex 1s;
}

.checkoutSuccess .headerBtn1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background: #FFFFFF;
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: black;
    padding: 18px 40px;
    cursor: pointer;
    transition: 1s;

}

.checkoutSuccess .headerBtn1:hover {
    transition: 1s;
    gap: 40px;
}

.checkoutSuccess .headerBtn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 20px;
    border-radius: 5px;

    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding: 18px 0px;
    cursor: pointer;
    transition: 1s;

}

.checkoutSuccess .headerBtn2:hover {
    transition: 1s;
    letter-spacing: 2px;
}

.checkoutSuccess .downBtn {
    margin: auto;
    width: 97px;
    height: 97px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 40px 0;
    margin-top: 10vh;
    cursor: pointer;
    animation: paralex 1s;
}

.checkoutSuccess .downBtn:hover {
    transition: 1s;

    background-color: rgba(255, 255, 255, 0.093);
}

.checkoutSuccess .HowItWorksInner {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    padding: 40px 0px;
    animation: paralex 1s;
}

.checkoutSuccess .HowItWorksInner .box {
    width: 100%;
    min-height: 230px;
    background: #FFFFFF;
    border: 1px solid #B9B9B9;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1% 1%;
    gap: 20px;

}

.checkoutSuccess .HowItWorksInner .box p {
    font-size: 1.2em;
}


.checkoutSuccess .HowItWorksInner .box img {
    height: 6vw;
    max-height: 80px;
    max-width: 80px;
    width: 6vw;
}

.checkoutSuccess .productSection {
    width: 100%;
    animation: paralex 1s;
}

#info-tooltip {
    background-color: #585757;
    color: white;
    border-radius: 4px;
    padding: 0.6rem;
}

.checkoutSuccess .productHeader {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    align-items: center;
}

.checkoutSuccess .productHeader .leftsideText {
    height: 70px;
}

.checkoutSuccess .productHeader .leftsideText {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.checkoutSuccess .sideText h2 {
    opacity: 0;
    cursor: default;
}

.checkoutSuccess .productHeader .sideText {
    height: 70px;
}

.checkoutSuccess .sideText .sortby {
    display: flex;
    align-items: flex-end;
    height: 100%;
    gap: 20px;
}

.checkoutSuccess .sideText .sortby div {
    font-weight: bold;
    justify-content: center;
}

.checkoutSuccess .wrap-sort-by {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 75px;
    background-color: #fff;
    border: 1px solid #a8a8a8;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(28, 28, 28, 0.2);
    z-index: 1000;
}

.checkoutSuccess .wrap-sort-by span {
    border-bottom: 1px solid #a8a8a8;
    padding: 5px;
    font-size: 18px;
    font-weight: 300;
}

.checkoutSuccess .wrap-sort-by span:hover {
    background-color: #f0f0f0;
}


.checkoutSuccess .sideText .sortby div:hover {
    cursor: pointer;
}

.checkoutSuccess .sideText .sortby img {
    height: 15px;
    padding-left: 5px;
}

.close-golfer-info {
    width: 10px;
}

.profilePic-golfer-info {
    max-width: 80% !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.checkoutSuccess .productHeader a {
    font-weight: bold;
    text-decoration: none;
    color: var(--primary-color);
}

.golferCards img {
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 50%
}

.checkoutSuccess .golferCards {
    padding-top: 30px;
    display: grid;
    --auto-grid-min-size: 264px;
    grid-gap: 20px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

.checkoutSuccess .golferCards .box {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: auto;
    border: 1px solid #D9D9D9;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 12px;
    animation: paralex 1s;
}

.checkoutSuccess .golferCards .box:hover {
    transition: .5s;
    border: 1px solid #b4b4b4;

}

.checkoutSuccess .infoIcon {
    position: absolute;
    color: #9B9A9A;
    font-size: 1.3em;
    right: 10px;
    top: 10px;
    cursor: pointer;
}

.golfer-info-tooltip {
    font-size: 12px;
    margin-right: 0.4rem;
}

.checkoutSuccess .stars i {
    color: #FFC700;
    font-size: 1.3em;
}

.checkoutSuccess .golferCards .box h3 {

    font-weight: 800;
    font-size: 22px;
}

.checkoutSuccess .golferCards .box h3:nth-child(2) {
    color: var(--primary-color);
}

.checkoutSuccess .productHeading1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.checkoutSuccess .productHeading2 {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.checkoutSuccess .stars {
    display: flex;
    gap: 5px;
    font-size: 12px
}

.checkoutSuccess .productHeading3 {
    display: flex;
    gap: 5px;
    align-items: center;
}

.checkoutSuccess .productHeading3 p {

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}

.checkoutSuccess .productHeading4 {

    display: flex;
    align-items: center;
    gap: 5px;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
}

.checkoutSuccess .productHeading4 i {
    font-size: 1.2em;
    color: #09B051;
}

.checkoutSuccess .productTags {
    display: flex;
    gap: 5px;
    width: 100%;

}

.checkoutSuccess .locationUpdate {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 1.2rem;
}

.checkoutSuccess .locationUpdate:hover {
    cursor: pointer;
    font-size: 1.3rem;
    transition: 400ms;
}

.checkoutSuccess .tag {
    justify-content: center;
    align-items: center;
    padding: 0px 5px;
    width: 100%;
    height: 35px;
    background: rgba(152, 152, 152, 0.2);
    border-radius: 300px;

    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 35px;
    text-align: center;
}

.checkoutSuccess .cardButton {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    gap: 20px;
    background: var(--primary-color);
    border-radius: 10px;
    cursor: pointer;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.checkoutSuccess .cardButton:hover {
    background-color: var(--secondary-color);
    transition: .3s;
}

.checkoutSuccess .profilePic-golfer-info {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Testimonials */

.checkoutSuccess .testimonialSection {
    width: 100%;
    height: fit-content;
    background-color: black;
    color: #fff;
    padding: 50px 0;
    animation: paralex 1s;
    text-align: center;
    margin-top: 40px;

}

.checkoutSuccess .testimonial h3 {


    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 53px;
    text-align: center;

}

.checkoutSuccess .testimonial {
    height: fit-content;
}

.checkoutSuccess .testimonial p {

    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    max-width: 890px;
    text-align: center;
    margin: auto;
    margin-top: 25px;
}

.checkoutSuccess .testimonialCard {
    margin: 35px 0;
    width: 100%;
    display: none;
    border: 1px solid #FFFFFF;
    text-align: left;
    padding: 40px;
}

.checkoutSuccess .testimonialCard.show {
    display: block;
}

.checkoutSuccess .testimonialCard p {
    text-align: left;

    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
    margin: 50px 0;
}

.checkoutSuccess .testimonialBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkoutSuccess .testimonialBottomLeft {
    display: flex;
}

.checkoutSuccess .testimonialBottomLeft img {
    width: 63.9px;
    height: 63.9px;
    border: 4px solid #09B051;
    border-radius: 3000px;
}

.checkoutSuccess .testimonialBottomLeftDetails {
    margin-left: 20px;

}

.checkoutSuccess .testimonialBottomLeftDetails h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 159.12%;

}

.checkoutSuccess .testimonialBottomLeftDetails p {

    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    line-height: 159.12%;
    margin: 0;
}


.checkoutSuccess .sliderButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 60px;
    text-align: center;

}

.checkoutSuccess .sliderButtons img {
    cursor: pointer;
}

.checkoutSuccess .dots {
    gap: 15px;
    display: flex;
    text-align: center;
    justify-content: center;
    transform: translateY(50%);
}

.checkoutSuccess .dot {
    width: 10px;
    height: 2px;
    background-color: white;
    cursor: pointer;

}

.checkoutSuccess .activeDot {
    width: 10px;
    height: 4px;
    background-color: #FFC700;

}

.horizLine {
    width: 90%;
    height: 1px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

/* Locations */
.golfer-locations .set-location {
    font-weight: bold;
    text-decoration: underline;
    color: var(--secondary-color);
    cursor: pointer;
}

.search-container {
    flex-direction: column;
    display: flex;
    width: 90%;
}

.search-container .use-current-loc {
    padding-bottom: 15px;
    font-size: 16px;
    color: #446ee7;
    text-decoration: underline;
    cursor: pointer;
}

.search-container .use-current-loc:hover {
    opacity: 0.8;
}

.search-container .dropdown {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    width: 83%;
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .5) !important;
    position: absolute;
    top: 165px;
    max-width: 500px;
    z-index: 1000;
}

.golfer-locations .search-container .dropdown {
    top: 150px;
}

.search-container .dropdown:empty {
    border: none;
}

.search-container .dropdown-row {
    border-color: #ccc;
    border-image: none;
    border-style: solid;
    border-width: 0 1px;
    display: block;
    font-weight: 400;
    padding: 8px 12px;
    width: 100%;
    cursor: pointer;
    border-top: 1px solid rgb(240, 240, 240);
    border-bottom: 1px solid rgb(240, 240, 240);
    font-size: 1.3rem;
    line-height: 1.25;
}

.golfer-locations .search-container .search-inner input {
    width: 220px;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 90%;
}

.search-container .search-img {
    width: 15px;
}

.search-container .search-img.search {
    width: 20px;
}

/* FAQs Section*/

.checkoutSuccess .faqsSection {
    width: 100%;
    height: fit-content;
    background-color: black;
    color: #fff;
    padding: 50px 0;
    animation: paralex 1s;
}

.checkoutSuccess .faq_wrapper {
    width: 100%;
}

.checkoutSuccess .faqs h3 {

    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 53px;
    text-align: center;
    margin-bottom: 50px;
}

.checkoutSuccess .faqbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 28px;
    gap: 10px;
    background: #000000;
    border: 1px solid #D9D9D9;
    height: fit-content;
}

.checkoutSuccess .faqHeading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.checkoutSuccess .faqHeading h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.checkoutSuccess .faqHeading img {
    height: 10px;
    width: 15px
}

.checkoutSuccess .faqbox p {

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;

    color: #FFFFFF;
}

.checkoutSuccess .faqbox .inVisibleAns {
    display: none;
}

.checkoutSuccess .faqbox .visibleAns {
    display: block;
}

.checkoutSuccess .faq_wrapper {
    display: grid;
    --auto-grid-min-size: 400px;
    grid-gap: 20px;
    grid-template-columns:
        repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}

.checkoutSuccess .wrap-golfer-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.checkoutSuccess .popupCardWrapProfilePicture {
    text-align: center;
    justify-content: center;
}


/* location popup */
.checkoutSuccess .bookSuccessModal {
    display: none;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);

}

.checkoutSuccess .bookSuccessModal.show {
    display: block;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

/*card details popup*/
.checkoutSuccess .cardDetailsPopup {
    display: none;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);

}

.checkoutSuccess .cardDetailsPopup.show {
    display: block;
    right: 0;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
}

.checkoutSuccess .popup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    background: #FFFFFF;
    border-radius: 0.5rem;
    max-height: unset;
}

.checkoutSuccess .popupHeading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 100%;
    background-color: var(--primary-color);
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    color: white;
}

.checkoutSuccess .popupHeading img {
    cursor: pointer;
}

.checkoutSuccess .popupHeading h2 {

    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
}

.checkoutSuccess .popupBody {
    border-bottom-left-radius: 0.5rem;
    padding: 1rem;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.checkoutSuccess .popupBody .intro-text {
    align-items: center;
    text-align: center;
    padding-bottom: 20px;
    font-size: 22px;
}

.checkoutSuccess .wrap-confirmation {
    border-radius: 25px;
}

.checkoutSuccess .wrap-golfer-highlight {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkoutSuccess .wrap-golfer-highlight p {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
}

.checkoutSuccess .inputFieldLocation {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
    height: 67.75px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
}

.checkoutSuccess .inputFieldLocation::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;

}

.checkoutSuccess .inputFieldLocation:-ms-input-placeholder {
    /* Internet Explorer 10-11 */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.checkoutSuccess .inputFieldLocation::-ms-input-placeholder {
    /* Microsoft Edge */

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
}

.checkoutSuccess .popupbuttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    gap: 12px;
}

.checkoutSuccess .disableBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 56px;
    background: #CFCFCF;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;

}

.checkoutSuccess .enableBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    height: 56px;
    background: #09B051;
    border-radius: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    cursor: pointer;
}

/* Padding for inner page navigation to help with fixed navbar */
.anchor {
    position: absolute;
    top: -15vh;
    left: 0px;
}

/* Card Detials Popup*/
.checkoutSuccess .popupCardDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 2%;
    justify-content: space-between;
    gap: 42px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 622px;
    height: fit-content;
    width: 80%;
    background: #FFFFFF;
    border-radius: 20px;
}

.checkoutSuccess #closecarddetailspopup {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 20px;
}

.checkoutSuccess .popBasicCardInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 20px;

    width: 100%;

}

.checkoutSuccess .productDetailCardHeading1 {
    width: 100%;
    display: flex;
    justify-content: space-between;

    font-style: normal;
    font-weight: 700;
    font-size: 1.6em;
    line-height: 1.7em;
    color: #000000;

}

.checkoutSuccess .productDetailCardHeading1 h3 {
    color: var(--primary-color);
}

.checkoutSuccess .golferCardstarsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
    gap: 104px;
    width: 100%;
}

.checkoutSuccess .productCardRating {
    display: flex;
    gap: 15px;
    align-items: center;
    width: 100%;
}

.checkoutSuccess .popCardBasicTags {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    width: 100%;
}

.checkoutSuccess .popupcardbasicinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    height: 110px;
    width: 100%;
}

.checkoutSuccess .popupcardbasicinfo .fa-crosshairs {
    font-size: 1.5em;
}

.checkoutSuccess .popupcardbasicinfo .productHeading4 {
    font-size: 1.5em;
}

.checkoutSuccess .popupcardbasicinfo .golfer-location {
    font-size: 1.3em;
}

.checkoutSuccess .popupCardAbout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.checkoutSuccess .popupCardAbout h4 {

    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 34px;
    color: #000000;
}

.checkoutSuccess .popupCardAbout p {

    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-align: justify;
    color: #000000;
    overflow-wrap: anywhere;
}

.checkoutSuccess .popupCardTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    gap: 20px;
    margin-top: 30px;
}

.checkoutSuccess .hrLine {
    width: 0px;
    height: 230px;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.checkoutSuccess .popupCardBigTags {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
}

.checkoutSuccess .popupCardBigTags h4 {

    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 34px;
}

.checkoutSuccess .BigTag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
    gap: 10px;
    width: 100%;
    height: 50px;
    background: rgba(9, 176, 81, 0.2);
    border-radius: 300px;
    text-align: center;
}

.footerSection {
    margin: unset;
    margin-top: auto;
    bottom: 0;
}

.golfer-handicap {
    color: #FFC700;
    font-weight: 500;
}

/* Swal */
.swal2-loader {
    border-color: var(--primary-color) rgba(0, 0, 0, 0) var(--primary-color) rgba(0, 0, 0, 0) !important;
}

.swal2-styled.swal2-confirm {
    background-color: black !important;
}

.swal2-styled.swal2-confirm:hover {
    background-color: #404145 !important;
}

.color-green {
    color: var(--primary-color);
}

.checkoutSuccess .popupBody img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.checkoutSuccess .popupBody .wrap-confirmations {
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 35%;
}

.checkoutSuccess .popupBody .confirmation-div {
    display: flex;
    text-align: left;
    padding-top: 20px;
    flex-direction: column;
}

.checkoutSuccess .popupBody .wrap-buttons {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 10px;
}

.checkoutSuccess .popupBody .wrap-buttons .footer-button {
    display: flex;
    flex-direction: column;
}

.checkoutSuccess .popupBody .wrap-buttons p {
    font-size: 20px;
    margin-bottom: 5px;
}

.checkoutSuccess .popupBody .wrap-buttons .contact {
    background-color: var(--primary-color);
    padding: 15px;
    color: white;
    font-size: 18px;
    border-radius: 8px;
    min-width: 200px;
    max-width: 300px;
    margin-top: 10px;
    margin: 0 auto;
    cursor: pointer;
}

.checkoutSuccess .popupBody .wrap-buttons .contact:hover {
    background-color: var(--secondary-color);
    transition: 0.5s;
}

/* Responsive */

@keyframes paralex {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 100;
    }
}

@media only screen and (min-width: 1174px) {
    .burger {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 1074px) {

    #box-tablet {
        display: none;
        visibility: hidden;
    }
}

@media only screen and (max-width: 723px) {
    .checkoutSuccess .productHeader {
        display: block;
        align-items: center;
    }

    .checkoutSuccess .header h1 {
        font-size: 5em;
    }

    .checkoutSuccess #box-mobile {
        display: none;
        visibility: hidden;
    }

    .checkoutSuccess .poweredBy {
        width: auto;
        height: 100px;
        z-index: 1000;
        position: static;
    }

    .product-title-mobile {
        display: flex;
    }

    .checkoutSuccess .hero {
        display: none;
    }

    .checkoutSuccess .header {
        padding-top: 100px;
        height: auto;
        background-image: url('../Assets/Images/home-image.png');
        background-position: 0px 60px;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: none;
        height: 270px;
    }

    .checkoutSuccess .headerBtn1 {
        padding: 12px 20px;
        gap: 10px;
    }

    .checkoutSuccess .header h2 {
        font-size: 24px;
        padding-top: 20px;
        line-height: 30px
    }

    .checkoutSuccess .headerBtns {
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .checkoutSuccess .HowItWorksInner .box {
        gap: 4px;
        min-height: 100px;
    }

    .checkoutSuccess .HowItWorksInner .box img {
        height: 60px;
        width: 60px;
    }

    .golferCards img {
        width: 120px;
        height: 120px;
    }

    .checkoutSuccess .golferCardstarsDiv {
        gap: 10px;
    }

    .checkoutSuccess .popupcardbasicinfo .golfer-location {
        font-size: 14px;
    }

    .checkoutSuccess .popupcardbasicinfo .fa-crosshairs {
        font-size: 1em;
    }

    .checkoutSuccess .popupcardbasicinfo .productHeading4 p {
        font-size: 0.8em;
    }

    .horizLine {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .checkoutSuccess .cardButton {
        padding: 5px 20px;
    }

    .testimonialCard img {
        width: 30px;
    }

    .checkoutSuccess .testimonialCard p {
        margin: 10px 0;
        font-size: 16px;
        line-height: 24px;
    }

    .checkoutSuccess .testimonialBottomLeft img {
        height: 80px;
        width: 80px;
        border: 2px solid var(--primary-color);
    }

    .checkoutSuccess .sideText .sortby img {
        height: 15px;
        padding-left: 5px;
    }

    .checkoutSuccess .popBasicCardInfo img {
        width: 50px !important;
    }

    .checkoutSuccess .productDetailCardHeading1 {
        font-size: 16px !important
    }

    .checkoutSuccess .popupCardAbout p {
        font-size: 0.8rem !important;
        line-height: 1rem !important;
    }

    .checkoutSuccess .popupCardDetails {
        gap: 15px !important;
    }

    .checkoutSuccess .popupCardAbout {
        gap: 5px !important;
    }

    .checkoutSuccess .popupCardBigTags {
        gap: 8px !important;
    }

    .checkoutSuccess .popupCardAbout h4,
    .checkoutSuccess .popupCardBigTags h4 {
        font-size: 1rem !important;
    }

    .checkoutSuccess .BigTag {
        padding: 2px 10px !important;
        font-size: 0.7rem !important;
        min-height: 30px !important;
        height: auto !important;
    }

    .checkoutSuccess .popupcardbasicinfo {
        gap: 10px;
        height: 80px;
    }

    .checkoutSuccess .golfer-info-stars {
        font-size: 14px;
    }

    .checkoutSuccess .productCardRating {
        gap: 5px;
    }

    .checkoutSuccess .hrLine {
        height: 180px;
    }

    .checkoutSuccess .popupCardTags {
        margin-top: 15px;
    }

    .golfer-handicap {
        font-size: 1.2rem;
    }

    .golfer-locations .dropdown-row {
        font-size: 1rem;
    }

    .checkoutSuccess .sideText .sortby {
        gap: 5px;
    }

    .checkoutSuccess .wrap-sort-by {
        top: 145px;
    }

    .checkoutSuccess .testimonialSection {
        padding: 30px 0;
    }

    .checkoutSuccess .testimonialCard {
        margin: 10px 0;
        padding: 20px;
    }

    .checkoutSuccess #closecarddetailspopup {
        width: 20px;
        height: 20px;
    }

    .checkoutSuccess .popup {
        max-width: 360px;
    }

    .checkoutSuccess .popupHeading h3 {
        font-size: 16px;
    }

    .checkoutSuccess .popupBody img {
        width: 150px;
        height: 150px;
    }

    .checkoutSuccess .popupBody .wrap-confirmations {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        padding-left: 25%;
    }

    .checkoutSuccess .popupBody .confirmation-div {
        padding-top: 20px;
        padding-right: 10px;
    }

    .checkoutSuccess .popupBody .wrap-buttons {
        gap: 10px;
    }

    .checkoutSuccess .popupBody .wrap-buttons p {
        font-size: 16px;
    }

    .checkoutSuccess .popupBody .wrap-buttons .contact {
        background-color: var(--primary-color);
        padding: 3px;
        color: white;
        font-size: 18px;
        border-radius: 8px;
        min-width: 150px;
        max-width: 200px;
        margin-top: 10px;
        margin: 0 auto;
        cursor: pointer;
        align-items: center;
        vertical-align: middle;
        padding: 20px 0px 20px 0px;
    }
}

@media only screen and (max-width: 481px) {
    .checkoutSuccess .testimonialBottom {
        display: block;
    }

    .checkoutSuccess .testimonialBottom .stars {
        margin-top: 20px;
    }

    .checkoutSuccess .header h1 {
        font-size: 3em;
    }

    .checkoutSuccess .headerBtns {
        flex-direction: column;
    }

    .checkoutSuccess .faq_wrapper {
        display: grid;
        --auto-grid-min-size: 200px;
        grid-gap: 20px;
        grid-template-columns:
            repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }

}

@media only screen and (max-width: 621px) {

    .checkoutSuccess .HowItWorksInner {
        display: flex;
        flex-direction: column;
    }

    .checkoutSuccess .HowItWorksInner .box {
        height: fit-content;
    }
}

/* Keyframes */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInDelay {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
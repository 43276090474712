.footerText {
  flex: 1;
  position: relative;
  line-height: 24px;
}

.socialIcon {
  overflow: hidden;
}

.socialIcon,
.socialIcon5 {
  position: relative;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.socialIcons {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-24);
}

.container,
.content,
.socialIcons {
  display: flex;
  justify-content: flex-start;
}

.content {
  align-self: stretch;
  border-top: 1px solid var(--gray-600);
  flex-direction: row;
  padding: var(--padding-32xl) 0 0;
  align-items: center;
  gap: var(--gap-32);
}

.container {
  flex-direction: column;
  padding: 0 var(--padding-32xl);
  align-items: flex-start;
  text-align: left;
  font-size: var(--text-md-regular-size);
  color: var(--gray-500);
  font-family: var(--text-md-regular);
}

@media screen and (max-width: 600px) {
  .content {
    flex-direction: column;
    padding: 10px 0 0 0;
    gap: 10px;
  }

}
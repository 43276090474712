.subHeading {
  position: relative;
  line-height: 32px;
  font-weight: 500;
  display: inline-block;
  width: 665px;
}

.interestedIn {
  margin: 0;
}

.text {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-27xl);
  line-height: 50px;
  font-weight: 600;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.p1 {
  position: relative;
  font-size: var(--text-lg-semibold-size);
  line-height: 28px;
  font-weight: 500;
  display: inline-block;
  width: 580px;
}

.headingContent {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}

.dotIcon,
.text1 {
  position: relative;
}

.dotIcon {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text1 {
  font-size: var(--text-lg-semibold-size);
  line-height: 28px;
  font-weight: 600;
  font-family: var(--text-md-regular);
  color: var(--base-black);
  text-align: left;
}

.button2xlprimarydotLeading {
  cursor: pointer;
  border: 1px solid var(--gray-50);
  padding: var(--padding-base) var(--padding-9xl);
  background-color: var(--gray-50);
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}

.text2 {
  position: relative;
  font-size: var(--text-lg-semibold-size);
  line-height: 28px;
  font-weight: 600;
  font-family: var(--text-md-regular);
  color: var(--base-white);
  text-align: left;
}

.button2xlprimarydotLeading1,
.buttonGroups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-xs);
}

.button2xlprimarydotLeading1 {
  cursor: pointer;
  border: 1px solid var(--error-700);
  padding: var(--padding-base) var(--padding-9xl);
  background-color: var(--error-700);
  border-radius: var(--br-5xs);
  box-shadow: var(--shadow-xs);
  overflow: hidden;
}

.buttonGroups {
  left: 0;
  justify-content: flex-start;
}

.contentContainer {
  position: relative;
  flex-shrink: 0;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
}

.cta {
  align-self: stretch;
  height: 440px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-76xl) var(--padding-5xl) var(--padding-61xl) var(--padding-178xl);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-image: url(/public/cta@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  text-align: left;
  font-size: var(--display-xs-medium-size);
  color: var(--base-white);
  font-family: var(--text-md-regular);
}

@media screen and (max-width: 600px) {
  .cta {
    padding: 50px 20px 50px 20px;
    height: auto;
  }

  .contentContainer {
    width: 100%;
  }

  .text {
    font-size: 32px;
    line-height: 40px;
  }

  .p1 {
    width: 100%;
    gap: 20px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .p1 p:nth-child(2) {
    display: none;
  }

  .button2xlprimarydotLeading1,
  .button2xlprimarydotLeading {
    padding: 6px 8px;
  }

  .text2,
  .text1 {
    font-size: 16px;
  }

  .buttonGroups {
    justify-content: center;
  }

  .interestedIn {
    font-size: 20px;
    line-height: 26px;
  }

  .headingContent {
    gap: 10px;
  }
}
.heading,
.subheading {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.subheading {
    font-size: 20px;
}

.heading {
    font-size: var(--display-md-semibold-size);
    letter-spacing: -0.02em;
    line-height: 44px;
    color: var(--gray-900);
}

.headingAndSubheading {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
}

.supportingText {
    align-self: stretch;
    position: relative;
    font-size: var(--text-xl-semibold-size);
    line-height: 30px;
    color: var(--gray-600);
}

.headingAndSupportingText {
    width: 768px;
    gap: var(--gap-xl);
}

.container,
.content,
.headingAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content {
    align-self: stretch;
}

.container {
    width: 1280px;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
}

.supportingText1,
.step {
    align-self: stretch;
    position: relative;
}

.step {
    line-height: 30px;
    font-weight: 600;
}

.supportingText1 {
    font-size: var(--text-md-semibold-size);
    line-height: 24px;
    color: var(--gray-600);
}

.stepAndSupportingText {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.step1 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.arrowRightIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.featureTab {
    align-self: stretch;
    border-left: 4px solid var(--gray-100);
    display: flex;
    flex-direction: column;
    padding: var(--padding-base) 0 var(--padding-base) var(--padding-5xl);
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
}

.featureTab,
.featureTabSelected {
    cursor: pointer;
}

.button {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-semibold-size);
    color: var(--error-700);
}

.content1,
.featureTabSelected {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.featureTabSelected {
    align-self: stretch;
    border-left: 4px solid var(--error-700);
    padding: var(--padding-base) 0 var(--padding-base) var(--padding-5xl);
    gap: var(--gap-xl);
}

.content1 {
    flex: 1;
}

.mockupShadow {
    height: calc(100% - 16px);
    width: calc(100% - 32px);
    top: 0;
    right: 24px;
    bottom: 16px;
    left: 8px;
    background-color: var(--gray-900);
    box-shadow: var(--shadow-3xl);
    display: none;
}

.mockupShadow,
.screenMockup,
.screenMockupReplaceFill {
    position: absolute;
    border-radius: var(--br-xs);
}

.screenMockupReplaceFill {
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    top: -3px;
    left: -2px;
    right: 16px;
    bottom: 16px;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.screenMockup {
    top: -8px;
    left: -8px;
    border: 8px solid var(--gray-900);
    box-sizing: border-box;
    width: 930px;
    height: 650px;
}

.wrapFeatures {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    overflow: hidden;
    height: 700px;
}

.wrapImage {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapImage img {
    border-radius: 20px;
}

.content2 {
    flex: 1;
    position: relative;
    height: 512px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 576px;
}

.container1,
.featuresSection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-45xl);
}

.container1 {
    width: 1280px;
    flex-direction: row;
    padding: 0 var(--padding-13xl);
    box-sizing: border-box;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.featuresSection {
    align-self: stretch;
    background-color: var(--base-white);
    overflow: hidden;
    flex-direction: column;
    padding: var(--padding-45xl) 0 var(--padding-77xl);
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--error-700);
    font-family: var(--text-md-semibold);
}


@media screen and (max-width: 600px) {
    .wrapFeatures {
        width: 100%;
        padding: 10px;
        height: unset;
        gap: 20px;
    }

    .featuresSection {
        padding: var(--padding-16) 0 var(--padding-32xl);
    }

    .container {
        padding: 0;
        width: 100%;
    }

    .headingAndSupportingText {
        width: 100%;
        gap: 10px;
    }

    .heading {
        font-size: var(--display-sm-semibold-size);
    }

    .supportingText {
        font-size: 18px;
    }

    .container1 {
        width: 100%;
        flex-direction: column;
        padding: 0 10px;
    }

    .featureTab,
    .featureTabSelected {
        min-height: 120px;
        padding: 8px 0 8px 12px;
        justify-content: center;
    }

    .supportingText1 {
        font-size: 14px;
    }

    .step {
        font-size: 18px;
    }

    .content2 {
        display: none;
    }
}
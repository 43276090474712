.text,
.text1 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.text1 {
  line-height: 18px;
  font-weight: 500;
}

.badge,
.textAndBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.badge {
  border-radius: var(--br-base);
  border: 1.5px solid var(--error-700);
  padding: var(--padding-11xs) var(--padding-5xs);
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--error-700);
}

.textAndBadge {
  align-self: stretch;
  gap: var(--gap-5xs);
}

.supportingText {
  align-self: stretch;
  position: relative;
  font-size: var(--text-sm-regular-size);
  line-height: 20px;
  color: var(--gray-600);
  display: none;
}

.textAndSupportingText {
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-9xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.text2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.button,
.button1 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.button1 {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-50);
  color: var(--primary-700);
}

.edit05Icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.actions,
.button2,
.button3 {
  flex-direction: row;
  align-items: center;
}

.button2 {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  display: flex;
  padding: var(--padding-3xs);
  justify-content: center;
}

.actions,
.button3 {
  display: none;
}

.button3 {
  border-radius: var(--br-5xs);
  background-color: var(--primary-600);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--primary-600);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--base-white);
}

.actions {
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--text-sm-regular-size);
  color: var(--gray-600);
}

.content,
.dropdown {
  align-items: flex-start;
  justify-content: flex-start;
}

.dropdown {
  display: none;
  flex-direction: column;
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl);
  gap: var(--gap-base);
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.cardHeader {
  align-self: stretch;
  background-color: var(--base-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--text-lg-semibold-size);
  color: var(--gray-900);
}

.label,
.text5 {
  position: relative;
}

.label {
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.text5 {
  line-height: 24px;
}

.content1,
.input,
.inputWithLabel {
  display: flex;
  justify-content: flex-start;
}

.content1 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
}

.input,
.inputWithLabel {
  align-self: stretch;
}

.input {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-sm);
  align-items: center;
  font-size: var(--text-md-semibold-size);
  color: var(--gray-500);
}

.inputWithLabel {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-6);
}

.hintText {
  position: relative;
  line-height: 20px;
  color: var(--gray-600);
  display: none;
  width: 320px;
}

.inputDropdown,
.searchWrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.inputDropdown {
  gap: var(--gap-5xs);
}

.searchWrap {
  padding: 0 var(--padding-base) var(--padding-xs);
}

.avatarOnlineIndicator,
.dot {
  border-radius: var(--br-8xs);
  overflow: hidden;
}

.dot {
  position: relative;
  background-color: var(--error-700);
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  opacity: 0;
}

.avatarOnlineIndicator {
  position: absolute;
  right: -1.5px;
  bottom: -1.5px;
  background-color: var(--success-500);
  border: 1.5px solid var(--base-white);
  box-sizing: border-box;
  width: 13px;
  height: 13px;
}

.avatar {
  position: relative;
  border-radius: var(--br-9xl);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.supportingText1 {
  position: relative;
  line-height: 20px;
  color: var(--gray-600);
}

.textAndSupportingText1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.avatarLabelGroup {
  align-items: center;
  gap: var(--gap-xs);
}

.avatarAndDot,
.avatarAndTime,
.avatarLabelGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.avatarAndDot {
  flex: 1;
  align-items: center;
  gap: var(--gap-xs);
}

.avatarAndTime {
  align-self: stretch;
  align-items: flex-start;
  gap: var(--gap-base);
}

.messagePreview {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.message,
.messagePreviewWrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.messagePreviewWrap {
  padding: 0 0 0 var(--padding-xl);
  color: var(--gray-600);
}

.message {
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  padding: var(--padding-base);
  gap: var(--gap-base);
}

.avatar1,
.dot1 {
  position: relative;
}

.dot1 {
  border-radius: var(--br-8xs);
  background-color: var(--error-700);
  width: 8px;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.avatar1 {
  border-radius: var(--br-181xl);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.avatarAndDot1,
.message1 {
  display: flex;
  justify-content: flex-start;
}

.avatarAndDot1 {
  flex: 1;
  flex-direction: row;
  padding: 0 0 0 var(--padding-xl);
  align-items: center;
  gap: var(--gap-xs);
}

.message1 {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  flex-direction: column;
  padding: var(--padding-base);
  align-items: flex-start;
  gap: var(--gap-base);
}

.avatarOnlineIndicator2 {
  position: absolute;
  right: -1.5px;
  bottom: -1.5px;
  border-radius: var(--br-8xs);
  background-color: var(--gray-300);
  border: 1.5px solid var(--base-white);
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  overflow: hidden;
}

.avatar2,
.avatar3,
.avatar4,
.avatar5 {
  position: relative;
  border-radius: var(--br-181xl);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.sidePanel {
  align-self: stretch;
  background-color: var(--base-white);
  border-right: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 360px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-700);
  font-family: var(--text-md-semibold);
}

.noMessages {
  font-size: 20px;
  padding: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
}

.wrapCard {
  display: flex;
  width: 100%;
}

.wrapCard.selected {
  background-color: #dfdfdf;
}

.sidePanel {
  height: 100%;
}
@media screen and (max-width: 600px) {

  .cardHeader {
    display: none;
  }

  .message {
    width: 100%;
  }

  .sidePanel {
    width: 100%;
  }
}
.weAreProud,
.weAreProud1 {
    position: relative;
    line-height: 24px;
}

.weAreProud1 {
    font-size: var(--text-lg-semibold-size);
    line-height: 28px;
    font-weight: 600;
    color: var(--gray-800);
    text-align: right;
}

.title {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.weAreProud6,
.weAreProud7 {
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.weAreProud7 {
    text-align: right;
}

.expand,
.title3 {
    align-self: stretch;
}

.title3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: var(--display-xs-semibold-size);
    color: var(--gray-800);
}

.expand {
    align-items: flex-start;
    gap: var(--gap-xs);
}

.expand,
.price,
.summary {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.price {
    align-self: stretch;
    align-items: flex-end;
}

.summary {
    width: 100%;
    align-items: flex-start;
    text-align: left;
    font-size: var(--text-md-semibold-size);
    color: var(--neutral-dark-grey);
    font-family: var(--text-sm-regular);
}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.dividerIcon1 {
    align-self: stretch;
    flex-shrink: 0;
}


.dividerIcon1 {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 2px;
    background-color: #f1f1f1;
}

@media screen and (max-width: 600px) {

    .weAreProud1,
    .weAreProud6,
    .weAreProud7 {
        font-size: 18px;
    }

    .weAreProud1 {
        font-size: 16px;
    }
}
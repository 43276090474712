.markerPin02Icon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 500;
}

.textAndIcon {
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.button,
.details,
.detailsWrap,
.textAndIcon {
    display: flex;
    flex-direction: row;
}

.eventsNearYou,
.featuredRow {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.detailsWrap {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--gray-600);
}

.button,
.details {
    align-items: center;
}

.button {
    border-radius: var(--br-5xs);
    border: 2px solid var(--gray-200);
    overflow: hidden;
    padding: var(--padding-7xs);
    justify-content: center;
}

.details {
    align-self: stretch;
    justify-content: flex-start;
    gap: var(--gap-5xl);
}

.text1 {
    flex: 1;
    position: relative;
    line-height: 32px;
    font-weight: 600;
    text-align: left;
}

.textAndButton {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.groupChild {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.51%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.87%;
}

.avatarInner,
.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarInner,
.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.avatarInner {
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.avatar,
.text2 {
    position: relative;
}

.avatar {
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.avatar img {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
}

.text2 {
    line-height: 20px;
    font-weight: 500;
}

.stars,
.textAndSubtext {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.textAndSubtext {
    align-self: stretch;
    align-items: center;
}

.stars {
    align-items: flex-start;
    gap: var(--gap-9xs);
}

.supportingText {
    position: relative;
    line-height: 24px;
    color: var(--gray-600);
}

.rating,
.textAndSupportingText1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.rating {
    color: var(--gray-900);
}

.content1,
.textAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    align-self: stretch;
}

.content1 {
    z-index: 1;
}

.dotIcon {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: -80px;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 2;
}

.wrapAvatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-xs);
}

.text4 {
    position: relative;
    line-height: 32px;
    font-weight: 600;
}

.textAndSupportingText2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
}

.details1,
.textAndCategory {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.details1 {
    flex: 1;
    justify-content: flex-end;
    font-size: var(--display-xs-medium-size);
    color: var(--gray-900);
}

.textAndCategory {
    justify-content: flex-start;
    gap: var(--gap-9xs);
    color: var(--gray-700);
}

.spotsTargetChart,
.slotsIndicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 429px;
    height: 8px;
}

.slotsIndicator {
    background-color: var(--color-firebrick-100);
    width: 257.4px;
}

.chart {
    flex: 1;
    position: relative;
    height: 8px;
}

.spotsTarget,
.spotsFilled {
    position: relative;
    line-height: 140%;
}

.spotsFilled {
    font-weight: 600;
}

.spotsTarget {
    font-family: var(--text-md-regular);
    color: var(--gray-600);
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.container,
.detail,
.spots {
    display: flex;
    flex-direction: row;
}

.detail {
    width: 117px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.container {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    flex-direction: column;
    gap: var(--gap-base);
    padding: var(--padding-xl) var(--padding-base) var(--padding-base);
    font-size: var(--text-md-regular-size);
}

.favoriteButton {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 36px;
    height: 36px;
    border-radius: var(--br-5xs);
    /* background-color: var(--color-gray-400); */
    background: url("/public/flag-grey.svg");
    background-size: contain;
    background-repeat: no-repeat;
    border: 1px solid var(--color-gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-4xs);
    align-items: center;
    justify-content: center;
    transition: 200ms;
}

.favoriteButton:hover {
    background: url("/public/flag-red.svg");
    background-size: contain;
    background-repeat: no-repeat;
}


.eventCard {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--error-700);
    font-family: var(--text-md-regular);
    transition: 200ms;
    max-width: 615px;
    width: 615px;
}

.eventCard.clickable {
    cursor: pointer;
}

.eventCard.clickable:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.rating {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: var(--gap-9xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
    align-items: center;
}

.starsWrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.star {
    position: relative;
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    overflow: hidden;
}

.ratingWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.userName {
    font-size: 20px;
}
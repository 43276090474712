@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
    margin: 0;
    line-height: normal;
}

:root {
    /* fonts */
    --text-md-regular: Inter;
    --text-md-semibold: Inter;
    /* font sizes */
    --text-md-regular-size: 16px;
    --text-sm-semibold-size: 14px;
    --text-xs-medium-size: 12px;
    --text-xl-semibold-size: 20px;
    --text-lg-semibold-size: 18px;
    --display-xs-semibold-size: 24px;
    --display-sm-semibold-size: 30px;
    --display-lg-semibold-size: 48px;
    --font-size-27xl: 46px;
    --display-xs-medium-size: 24px;
    --font-size-17xl: 36px;
    --heading-h1-desktop-size: 64px;
    --text-tiny-very-small-bold-desktop-size: 10px;
    --font-size-53xl: 72px;
    --text-sm-regular-size: 14px;
    --text-md-semibold-size: 16px;
    --font-size-31xl: 50px;
    --font-size-6xl: 25px;
    --display-md-semibold-size: 36px;

    /* Colors */
    --primary-color: #274368;
    --secondary-color: #BA3526;
    --gray-900: #101828;
    --color-gray-100: rgba(255, 255, 255, 0.3);
    --color-gray-200: rgba(255, 255, 255, 0.1);
    --color-gray-400: #f7f7f7;
    --gray-600: #475467;
    --gray-700: #344054;
    --gray-cool-800: #30374f;
    --gray-500: #667085;
    --gray-50: #f9fafb;
    --gray-100: #dadada;
    --gray-200: #eaecf0;
    --gray-300: #d0d5dd;
    --base-white: #fff;
    --error-700: #b42318;
    --base-black: #000;
    --nutral-10: #e8e8e9;
    --color-seashell: #fff0ed;
    --color-whitesmoke-100: #f5f5f5;
    --color-whitesmoke-200: #f1f0f0;
    --color-darkslategray-100: #1e3356;
    --color-firebrick-100: #a6211c;
    --white-color: #fef3f2;
    --error-50: #fef3f2;

    /* Gaps */
    --gap-45xl: 64px;
    --gap-5xl: 24px;
    --gap-32: 32px;
    --gap-36: 36px;
    --gap-24: 24px;
    --gap-base: 16px;
    --gap-xs: 12px;
    --gap-5xs: 8px;
    --gap-mini: 8px;
    --gap-6: 6px;
    --gap-lgi: 19px;
    --gap-57xl: 76px;
    --gap-2xl: 21px;
    --gap-44xl: 63px;
    --gap-46xl: 65px;
    --gap-smi-4: 12.4px;
    --gap-56xl-2: 75.2px;
    --gap-xl: 20px;
    --gap-29xl: 48px;
    --gap-40: 40px;
    --gap-9xs: 4px;
    --gap-77xl: 96px;
    --gap-13xl: 32px;

    /* Paddings */
    --padding-11xs: 2px;
    --padding-32xl: 32px;
    --padding-178xl: 197px;
    --padding-29xl: 48px;
    --padding-30xl: 49px;
    --padding-43xl: 62px;
    --padding-45xl: 64px;
    --padding-4xs: 4px;
    --padding-5xl: 24px;
    --padding-16: 16px;
    --padding-5xs: 8px;
    --padding-61xl: 80px;
    --padding-76xl: 95px;
    --padding-77xl: 96px;
    --padding-81xl: 100px;
    --padding-9xl: 28px;
    --padding-9xs: 4px;
    --padding-base: 16px;
    --padding-lg: 18px;
    --padding-sm: 14px;
    --padding-xl: 20px;
    --padding-xs: 12px;
    --padding-3xs: 10px;
    --padding-7xs: 6px;
    --padding-13xl: 32px;

    /* border radiuses */
    --br-base: 16px;
    --br-5xs: 8px;
    --br-xs-6: 11.6px;
    --br-7xs: 6px;
    --br-xs: 12px;
    --br-3xs: 10px;
    --br-29xl: 48px;
    --br-181xl: 200px;
    --br-9xs: 4px;
    --br-9xl: 28px;
    --br-11xs: 2px;
    --br-69xl: 88px;

    /* fixed content */
    --header-height: 80px;

    /* Effects */
    --shadow-sm: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.06);
    --shadow-xs: 0px 1px 2px rgba(16, 24, 40, 0.05);
    --background-blur-md: blur(16px);
    --shadow-xl: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
        0px 8px 8px -4px rgba(16, 24, 40, 0.03);

}

.content-div {
    width: 1200px;
    display: flex;
    flex-direction: row;
    gap: 50px;
}

.rdrDefinedRangesWrapper {
    display: none;
}

.rdrStartEdge,
.rdrEndEdge {
    color: var(--secondary-color) !important;
}

.rdrInRange {
    background-color: #ba352685 !important;
}

.rdrDayToday .rdrDayNumber span:after {
    background: var(--primary-color) !important;
}

.rdrDayHovered .rdrDayNumber span:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: var(--primary-color) !important;
}

.rdrDateDisplayItemActive {
    border-color: var(--primary-color) !important;
}

@media screen and (max-width: 600px) {
    .content-div {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }

    :root {
        --header-height: 60px;
    }
}
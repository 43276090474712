.supportingText,
.text {
    align-self: stretch;
    position: relative;
}

.text {
    line-height: 32px;
    font-weight: 600;
    color: var(--primary-color);
}

.text,
.text1 {
    font-size: 20px;
}

.supportingText {
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    color: var(--gray-600);
}

.frameParent {
    display: flex;
    gap: 10px;
}

.content2 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
}

.pageIndicator {
    font-size: 18px;
    display: flex;
    align-items: center;
}

.arrowWrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.button {
    flex-direction: row;
    align-items: center;
}

.button,
.button1,
.button2 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    padding: var(--padding-3xs) var(--padding-base);
    justify-content: center;
    gap: var(--gap-5xs);
}

.button1 {
    background-color: var(--primary-50);
    border: 1px solid var(--primary-50);
    flex-direction: row;
    align-items: center;
    color: var(--primary-700);
}

.button2 {
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    color: var(--gray-700);
}

.actions,
.button2,
.button3 {
    flex-direction: row;
    align-items: center;
}

.button3 {
    border-radius: var(--br-5xs);
    background-color: var(--primary-600);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--primary-600);
    overflow: hidden;
    display: flex;
    padding: var(--padding-3xs) var(--padding-base);
    justify-content: center;
    gap: var(--gap-5xs);
    color: var(--base-white);
}

.actions {
    display: none;
    justify-content: flex-start;
    gap: var(--gap-xs);
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
}

.dotsVerticalIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.content1,
.dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dropdown {
    flex-direction: column;
    color: var(--error-700);
}

.content1 {
    align-self: stretch;
    flex-direction: row;
    gap: var(--gap-base);

}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
    display: none;
}

.sectionHeader {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xl);
    padding: var(--padding-5xl);
}

.imageChild,
.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
}

.imageChild {
    border-radius: var(--br-9xs);
    width: 406px;
    height: 249px;
    object-fit: cover;
}

.imageOverlay {
    height: 100%;
    width: calc(100% - 0.33px);
    right: 0.33px;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
}

.award02Icon {
    position: relative;
    width: 12px;
    height: 12px;
    overflow: hidden;
    flex-shrink: 0;
}

.text5 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge,
.button4 {
    position: absolute;
    bottom: 8px;
    flex-direction: row;
    align-items: center;
}

.badge {
    left: 8px;
    border-radius: var(--br-base);
    background-color: var(--error-50);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
}

.button4 {
    right: 8.33px;
    border-radius: var(--br-5xs);
    background-color: var(--color-gray-400);
    backdrop-filter: var(--background-blur-md);
    border: 1px solid var(--color-gray-300);
    overflow: hidden;
    display: flex;
    padding: var(--padding-xs);
    justify-content: center;
}

.image,
.text6 {
    align-self: stretch;
    position: relative;
}

.image {
    height: 208px;
    overflow: hidden;
    flex-shrink: 0;
}

.text6 {
    line-height: 30px;
    font-weight: 600;
}

.textAndButton,
.textAndCategory {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndCategory {
    flex: 1;
    flex-direction: column;
}

.textAndButton {
    align-self: stretch;
    flex-direction: row;
    font-size: var(--text-xl-semibold-size);
}

.groupChild {
    position: absolute;
    height: 99.9%;
    width: 58.2%;
    top: 0.05%;
    right: 20.24%;
    bottom: 0.05%;
    left: 21.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
    position: absolute;
    height: 80.18%;
    width: 46.65%;
    top: 9.81%;
    right: 26%;
    bottom: 10.01%;
    left: 27.34%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
}

.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
    height: 100%;
    width: 58.2%;
    top: 0;
    right: 20.24%;
    bottom: 0;
    left: 21.57%;
    object-fit: cover;
}

.rectangleIcon1,
.rectangleIcon2 {
    height: 8.72%;
    width: 14.55%;
    top: 45.49%;
    right: 57.51%;
    bottom: 45.79%;
    left: 27.94%;
}

.rectangleIcon2 {
    height: 30.43%;
    width: 96.28%;
    top: 39.35%;
    right: 1.85%;
    bottom: 30.23%;
    left: 1.87%;
}

.avatarInner,
.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    height: 36.82%;
    top: 36.17%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarInner,
.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.avatarInner {
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.avatar,
.text7 {
    position: relative;
}

.avatar {
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.text7 {
    line-height: 20px;
    font-weight: 500;
}

.textAndSubtext {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.supportingText1 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.content5,
.textAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText {
    align-self: stretch;
}

.content5 {
    z-index: 1;
}

.dotIcon {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    z-index: 2;
}

.wrapAvatar {
    align-items: center;
    position: relative;
    gap: var(--gap-xs);
    color: var(--gray-700);
}

.badge1,
.badges,
.wrapAvatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.badge1 {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-darkslategray-100);
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    gap: var(--gap-9xs);
}

.badges {
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.text10 {
    position: relative;
    line-height: 30px;
    font-weight: 600;
}

.badgesParent,
.textAndSupportingText1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.badgesParent {
    align-self: stretch;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    font-size: var(--text-sm-semibold-size);
    color: var(--color-darkslategray-100);
}

.donationTargetChart,
.raisedIndicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-29xl);
    background-color: var(--color-whitesmoke-200);
    width: 225.33px;
    height: 8px;
}

.raisedIndicator {
    background-color: var(--color-firebrick-100);
    width: 135.2px;
}

.chart {
    flex: 1;
    position: relative;
    height: 8px;
}

.donationTarget,
.dontionRaised {
    position: relative;
    line-height: 140%;
}

.dontionRaised {
    font-weight: 600;
}

.donationTarget {
    font-family: var(--text-md-regular);
    color: var(--gray-600);
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.containre,
.detila,
.spots {
    display: flex;
    flex-direction: row;
}

.detila {
    width: 117px;
    align-items: flex-start;
    justify-content: flex-start;
}

.containre {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
}

.content4,
.listingSearchResult,
.textAndButton1 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndButton1 {
    flex-direction: row;
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.content4,
.listingSearchResult {
    flex-direction: column;
}

.content4 {
    padding: var(--padding-xl) var(--padding-base) var(--padding-base);
    gap: var(--gap-base);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.listingSearchResult {
    flex: 1;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    overflow: hidden;
}

.avatar1 {
    position: relative;
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
}

.content,
.experience {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.section {
    width: 100%;
    display: flex;
    justify-content: center;
}

.content3 {
    flex-direction: row;
    gap: var(--gap-24);
}

.content3 {
    justify-content: center;
    align-items: center;
    display: flex;
}

.content,
.experience {
    flex-direction: column;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--error-700);
}

.content {
    gap: var(--gap-32xl);
    text-align: left;
    font-size: var(--display-xs-semibold-size);
    color: var(--gray-900);
    font-family: var(--text-md-regular);
}

.noListings {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    justify-content: center;
    background-color: var(--primary-color);
    padding: 30px 20px;
    color: white;
    border-radius: 1rem;
    gap: 40px;
}

.noListings span {
    text-align: center;
}

.promoteGrowth {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.promoteGrowth button {
    cursor: pointer;
    border: 1px solid var(--error-700);
    padding: var(--padding-base) var(--padding-9xl);
    background-color: var(--error-700);
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    color: white;
    width: 200px;
    font-size: 20px;
}

@media screen and (max-width: 600px) {

    .sectionHeader {
        padding: 10px;
    }

    .wrapCard {
        display: flex;
        width: 100%;
    }

    .content3 {
        flex-direction: column;
        max-width: 100%;
    }

    .noListings {
        font-size: 18px;
        padding: 20px 30px 20px 30px;
        margin: 20px 0 20px 0;
        gap: 20px;
        width: 90%
    }

    .promoteGrowth {
        gap: 20px;
    }

    .promoteGrowth button {
        font-size: 18px;
        width: auto;
    }

    .noListings .footerSection {
        gap: 10px;
        padding: 32px 0 32px;
    }
}
.frameChild,
.trophyIcon {
  position: absolute;
  height: 77.25%;
  width: 81.95%;
  top: 18.34%;
  right: 4.13%;
  bottom: 4.4%;
  left: 13.92%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.trophyIcon {
  height: 92.11%;
  width: 86.21%;
  top: 7.89%;
  right: 2.98%;
  bottom: 0;
  left: 10.81%;
  object-fit: cover;
}

.vectorParent {
  position: relative;
  width: 116px;
  height: 108px;
  flex-shrink: 0;
}

.supportingText,
.text {
  align-self: stretch;
  position: relative;
}

.text {
  line-height: 30px;
  font-weight: 600;
}

.supportingText {
  font-size: var(--text-md-regular-size);
  line-height: 24px;
  color: var(--gray-600);
}

.textAndSupportingText {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text1 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.arrowRightIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
}

.button,
.featureText {
  display: flex;
  align-items: center;
}

.button {
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--text-md-regular-size);
  color: var(--error-700);
  cursor: pointer;
}

.featureText {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
}

.featureTextChild {
  position: relative;
  height: 100%;
  flex-shrink: 0;
}

.wrapHighlightImage {
  height: 120px;
  display: flex;
}

.categoryIcon,
.frameItem {
  position: absolute;
  height: 73.19%;
  width: 79.71%;
  top: 26.11%;
  right: 7.78%;
  bottom: 0.69%;
  left: 12.51%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.categoryIcon {
  height: 96.3%;
  width: 89.66%;
  top: 3.7%;
  right: 0;
  bottom: 0;
  left: 10.34%;
  object-fit: cover;
}

.container,
.content,
.featuresSection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  width: 1216px;
  flex-direction: row;
  gap: var(--gap-32);
}

.container,
.featuresSection {
  flex-direction: column;
  padding: 0 var(--padding-32xl);
  box-sizing: border-box;
}

.featuresSection {
  background-color: var(--gray-50);
  overflow: hidden;
  padding: var(--padding-77xl) 0;
  align-items: center;
  text-align: center;
  font-size: var(--text-xl-semibold-size);
  color: var(--gray-900);
  font-family: var(--text-md-regular);
  width: 100%;
}

@media screen and (max-width: 600px) {
  .featuresSection {
    padding: var(--padding-32xl) 0;
  }

  .container {
    flex-direction: column;
    padding: 0 var(--padding-16);
  }

  .content {
    width: 100%;
    flex-direction: column;
    gap: var(--gap-40);
  }

  .featureText {
    flex-direction: row;
  }

  .vectorParent {
    width: auto;
  }

  .wrapHighlightImage {
    height: auto;
    min-width: 80px;
    width: 80px;
  }

  .featureTextChild {
    width: 100%;
    height: auto;
  }

  .text,
  .supportingText,
  .textAndSupportingText {
    align-items: flex-start;
    align-self: flex-start;
    text-align: left;
  }

  .supportingText {
    font-size: 14px;
  }

}
.text {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  font-size: 16px;
}

.tableHeader,
.tableHeaderCell {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tableHeaderCell {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 44px;
  padding: var(--padding-xs) var(--padding-5xl);
}

.buttonIcon {
  position: relative;
  width: 21px;
  height: 20px;
}

.eventRow td,
.eventRow tr {
  border-collapse: collapse;
}

.eventsTable {
  border-collapse: collapse;
}

.selected td {
  background-color: var(--gray-200);
  border: var(--gray-200)
}

.toggleBase {
  border-radius: var(--br-xs);
  background-color: var(--error-700);
  width: 36px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: row;
  padding: var(--padding-11xs);
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
}

.toggle {
  flex-direction: column;
  align-items: flex-start;
}

.column,
.tableCell,
.tableHeaderCell1,
.toggle {
  justify-content: flex-start;
}

.tableCell {
  align-self: stretch;
  background-color: var(--base-white);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl);
  align-items: center;
}

.column,
.tableHeaderCell1 {
  flex-direction: column;
  align-items: flex-start;
  font-size: var(--text-xs-medium-size);
}

.tableHeaderCell1 {
  align-self: stretch;
  background-color: var(--base-white);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 44px;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  align-items: center;
  color: var(--gray-600);
}

.avatarIcon {
  border-radius: var(--br-5xl);
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: none;
}

.eventTitle {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.text3 {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 20px);
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  width: 40px;
}

.avatar,
.text4 {
  position: relative;
}

.avatar {
  border-radius: var(--br-181xl);
  background-color: var(--blue-dark-50);
  width: 40px;
  height: 40px;
  display: none;
}

.text4 {
  font-size: var(--text-sm-regular-size);
  line-height: 20px;
  font-weight: 500;
  color: var(--gray-900);
  text-align: left;
}

.tableCell5 {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl);
  align-items: center;
  gap: var(--gap-xs);
  text-align: center;
  font-size: var(--text-md-semibold-size);
  color: var(--gray-800);
}

.column1,
.tableCell5,
.tableHeaderCell2 {
  justify-content: flex-start;
}

.column1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  color: var(--gray-900);
}

.tableHeaderCell2 {
  align-self: stretch;
  background-color: var(--base-white);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 44px;
  flex-direction: row;
  padding: var(--padding-xs) var(--padding-5xl);
  align-items: center;
  font-size: var(--text-xs-medium-size);
}

.text8 {
  position: relative;
  line-height: 20px;
  font-size: 14px;
}

.eventInfoCell {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-base) var(--padding-5xl);
  align-items: center;
  justify-content: flex-start;
}

.background,
.progress {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--gray-200);
  height: 8px;
}

.progress {
  width: 50.36%;
  right: 49.64%;
  background-color: var(--error-700);
}

.progressBar1 {
  flex: 1;
  position: relative;
  border-radius: var(--br-5xs);
  height: 8px;
}

.percentage {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  font-size: 16px;
}

.progressBar {
  align-self: stretch;
  width: 147px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}

.progress1,
.progress2,
.progress3 {
  position: absolute;
  width: 30.22%;
  top: 0;
  right: 69.78%;
  left: 0;
  border-radius: var(--br-9xs);
  background-color: var(--error-700);
  height: 8px;
}

.progress2,
.progress3 {
  width: 100%;
  right: 0;
}

.progress3 {
  width: 10.07%;
  right: 89.93%;
}

.column4 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--gray-700);
}

.tableHeaderCell5 {
  align-self: stretch;
  background-color: var(--base-white);
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 44px;
}

.messageTextSquare01Icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.button {
  display: none;
}

.button,
.button1,
.button3 {
  border-radius: var(--br-5xs);
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: center;
  justify-content: center;
}

.button1 {
  display: flex;
  cursor: pointer;
}

.button3 {
  background-color: var(--error-50);
  display: none;
}

.content,
.tableCell20 {
  flex-direction: row;
  justify-content: flex-start;
}

.tableCell20 {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 72px;
  padding: var(--padding-base);
  align-items: center;
  gap: var(--gap-9xs);
  display: flex;
  flex-direction: row;
}

.content {
  background-color: var(--base-white);
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-600);
  font-family: var(--text-md-semibold);
  flex-direction: column;
}

.eventsTable {
  width: 100%;
}

.eventsTable tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 600px) {
  .tableHeaderCell1 {
    padding: 0px;
    text-align: center;
    vertical-align: center;
  }

  .tableHeader {
    padding: 5px;
    vertical-align: center;
  }

  .eventTitle,
  .eventInfoCell {
    padding: 5px;
    max-height: 50px;
  }

  .divider {
    align-self: stretch;
    flex-shrink: 0;
  }


  .divider {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 2px;
    background-color: var(--gray-200);
  }

  .text8 {
    white-space: nowrap;
  }

  .eventTitle {
    white-space: normal;
    min-width: 200px;
  }

  .percentage {
    font-size: 14px;
  }

  .toggle,
  .column4 {
    display: none;
  }

}
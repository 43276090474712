.container3 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}

.container2,
.container3,
.main,
.section {
    display: flex;
    justify-content: flex-start;
}

.content18,
.verticalTabs {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content18 {
    align-self: stretch;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-md);
    border: 1px solid var(--gray-200);
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.wrapSidePanel {
    height: 100%;
}

.wrapMessageWindow {
    height: 100%;
}

@media screen and (max-width: 600px) {
    .wrapSidePanel {
        display: flex;
        width: 100%;
    }

    .wrapMessageWindow {
        display: flex;
        width: 100%;
        height: 100%;
        padding-bottom: 100px;
    }

    .wrapSidePanel.hideMobile {
        display: none;
    }

    .content18 {
        border: none;
    }
}
.label {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.starIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}

.stars {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.text {
    font-weight: 500;
}

.supportingText,
.text {
    position: relative;
    line-height: 24px;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.content1,
.labelAndText,
.scratchmakerReview,
.textAndSupportingText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content1,
.labelAndText,
.scratchmakerReview {
    flex-direction: row;
}

.labelAndText,
.scratchmakerReview {
    flex-direction: column;
}

.scratchmakerReview {
    padding: var(--padding-9xs) 0;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.labelAndText {
    gap: var(--gap-5xs);
}

.badgeIcon {
    position: relative;
    width: 39px;
    height: 27px;
    object-fit: cover;
}

.badgeParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
}

.labelAndText1,
.scratchmakerReview1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.scratchmakerReview1 {
    padding: var(--padding-9xs) 0;
}

.labelAndText1 {
    gap: var(--gap-5xs);
}

.placeholderIcon {
    position: relative;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.text1 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.button,
.labelAndText2 {
    display: flex;
    gap: var(--gap-5xs);
}

.button {
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-800);
}

.labelAndText2 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.text2 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--blue-dark-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-7xs);
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: multiply;
}

.button1,
.labelAndText3 {
    display: flex;
    gap: var(--gap-5xs);
}

.button1 {
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--gray-700);
}

.labelAndText3 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.text3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    flex-shrink: 0;
}

.labelAndText4,
.textAndIcon {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.textAndIcon {
    flex-direction: row;
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-700);
}

.labelAndText4 {
    flex-direction: column;
    align-items: flex-start;
}

.details,
.text4 {
    align-self: stretch;
}

.details {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    border: 1px solid var(--gray-200);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-32xl) var(--padding-5xl) var(--padding-5xl);
    align-items: stretch;
    justify-content: space-between;
    gap: var(--gap-32);
}

.text4 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    color: var(--primary-color);

}

.dolorEnimEu {
    margin-block-start: 0;
    margin-block-end: 16px;
}

.description {
    margin: 0;
}

.supportingText1 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-600);
}

.textAndSupportingText2 {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-9xs);
}

.text5 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.button2,
.button3,
.button4,
.button5 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button3,
.button4,
.button5 {
    background-color: var(--primary-50);
    border: 1px solid var(--primary-50);
    color: var(--primary-700);
}

.button4,
.button5 {
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    color: var(--gray-700);
}

.button5 {
    background-color: var(--primary-600);
    border: 1px solid var(--primary-600);
    color: var(--base-white);
}

.actions,
.content2 {
    flex-direction: row;
    justify-content: flex-start;
}

.actions {
    display: none;
    align-items: center;
    gap: var(--gap-xs);
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
}

.content2 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    gap: var(--gap-base);
}

.text9 {
    position: relative;
    font-size: var(--text-sm-semibold-size);
    line-height: 20px;
    font-weight: 600;
    color: var(--error-700);
}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.content,
.leftColumn,
.sectionHeader {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sectionHeader {
    gap: var(--gap-xl);
}

.content,
.leftColumn {
    font-size: var(--text-lg-semibold-size);
    color: var(--gray-900);
}

.content {
    padding: var(--padding-5xl) var(--padding-5xl) 0 var(--padding-5xl);
    gap: var(--gap-12xl);
    text-align: left;
    font-size: var(--text-sm-semibold-size);
    color: var(--gray-600);
    font-family: var(--text-md-regular);
}

.textAndSupportingText2 {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .content {
        padding: 10px;
    }

    .text4 {
        font-size: 20px;
        color: var(--primary-color);
    }
}
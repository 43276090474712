.fileNameIcon {
    position: relative;
    width: 320px;
    height: 51px;
    flex-shrink: 0;
}

.supportingText {
    align-self: stretch;
    position: relative;
    line-height: 24px;
}

.logoAndSupportingText {
    width: 320px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-32xl);
}

.heading,
.placeholderIcon {
    position: relative;
    display: none;
}

.heading {
    align-self: stretch;
    line-height: 20px;
    font-weight: 600;
}

.placeholderIcon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
}

.bookButton .text {
    color: white;
}

.button,
.footerLink {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    overflow: hidden;
    justify-content: center;
    gap: var(--gap-5xs);
}

.footerLink {
    justify-content: flex-start;
}

.text3 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.badge {
    border-radius: var(--br-base);
    background-color: var(--color-gray-200);
    border: 1px solid var(--color-gray-100);
    display: flex;
    flex-direction: row;
    padding: var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: flex-start;
    mix-blend-mode: normal;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--base-white);
}

.footerLinks {
    align-self: stretch;
    flex-direction: column;
    gap: var(--gap-xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-200);
}

.footerLinks,
.footerLinksColumn,
.links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.footerLinksColumn {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-base);
}

.links {
    width: 320px;
    flex-shrink: 0;
    flex-direction: row;
    gap: var(--gap-32xl);
}

.heading2,
.label {
    position: relative;
    line-height: 20px;
}

.heading2 {
    align-self: stretch;
    font-weight: 600;
}

.label {
    font-weight: 500;
    display: none;
}

.content1 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.helpIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: none;
}

.input,
.inputWithLabel {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input {
    border-radius: var(--br-9xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.inputWithLabel {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-6);
}

.hintText {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
}

.inputField {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-6);
}

.button16 {
    border-radius: var(--br-9xs);
    background-color: var(--color-firebrick-100);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--color-firebrick-100);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--base-white);
}

.emailCapture,
.newsletter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
}

.emailCapture {
    align-self: stretch;
    flex-direction: row;
    color: var(--gray-900);
}

.newsletter {
    width: 360px;
    flex-shrink: 0;
    flex-direction: column;
    color: var(--base-white);
}

.linksAndNewsletter {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-32xl);
    font-size: var(--text-sm-medium-size);
    color: var(--gray-300);
}

.container,
.content,
.linksAndNewsletter {
    display: flex;
    align-items: flex-start;
}

.content {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
}

.container {
    width: 1440px;
    flex-direction: column;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    justify-content: flex-start;
}

.footerText {
    flex: 1;
}

.footerLink16,
.footerText {
    position: relative;
    line-height: 24px;
}

.footerLinks2 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-base);
}

.container1,
.content2,
.footer,
.footerLinks2 {
    display: flex;
    justify-content: flex-start;
}

.content2 {
    align-self: stretch;
    border-top: 1px solid var(--gray-600);
    flex-direction: row;
    padding: var(--padding-32xl) 0 0;
    align-items: center;
    gap: var(--gap-32xl);
}

.container1,
.footer {
    width: 1440px;
    flex-direction: column;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-items: flex-start;
    color: var(--gray-300);
}

.footer {
    position: absolute;
    top: 2691px;
    left: 0;
    background-color: var(--color-darkslategray-100);
    width: 1440px;
    padding: var(--padding-45xl) 0 var(--padding-29xl);
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-45xl);
    color: var(--gray-200);
}

.homeLineIcon {
    position: relative;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    overflow: hidden;
}

.breadcrumbButtonBase {
    border-radius: var(--br-7xs);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs);
    align-items: flex-start;
    justify-content: flex-start;
}

.chevronRightIcon {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    overflow: hidden;
}

.breadcrumbButtonBase1,
.breadcrumbButtonBase3 {
    border-radius: var(--br-7xs);
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
}

.breadcrumbButtonBase1 {
    display: flex;
}

.breadcrumbButtonBase3 {
    display: none;
}

.chevronRightIcon3 {
    position: relative;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
}

.getDirections {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    cursor: pointer;
}

.getDirections:hover {
    text-decoration: underline;
}

.breadcrumbButtonBase4 {
    border-radius: var(--br-7xs);
    background-color: var(--gray-50);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    color: var(--gray-700);
}

.breadcrumbs,
.tabs {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.tabs {
    display: flex;
    gap: var(--gap-5xs);
}

.breadcrumbs {
    display: none;
}

.supportingText1,
.text23 {
    align-self: stretch;
    position: relative;
}

.text23 {
    line-height: 38px;
    font-weight: 600;
}

.supportingText1 {
    font-size: var(--text-md-regular-size);
    line-height: 24px;
    color: var(--gray-600);
}

.textAndSupportingText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    font-size: var(--text-md-regular-size);
}

.button17 {
    display: none;
}

.button17,
.button18,
.button19,
.button20 {
    border-radius: var(--br-5xs);
    overflow: hidden;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button18 {
    background-color: var(--primary-50);
    border: 1px solid var(--primary-50);
    display: none;
    color: var(--primary-700);
}

.button19,
.button20 {
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    color: var(--gray-700);
}

.button20 {
    background-color: var(--color-darkslategray-100);
    border: 1px solid var(--color-darkslategray-100);
    color: var(--base-white);
}

.actions {
    gap: var(--gap-xs);
    color: var(--gray-600);
}

.actions,
.content4,
.input1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.content4 {
    flex: 1;
    gap: var(--gap-5xs);
}

.input1 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    padding: var(--padding-3xs) var(--padding-sm);
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.hintText1 {
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
    display: none;
    width: 320px;
}

.inputDropdown {
    width: 320px;
    flex-shrink: 0;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    color: var(--gray-700);
}

.dividerIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 1px;
    flex-shrink: 0;
}

.pageHeader,
.section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pageHeader {
    align-self: stretch;
    align-items: flex-start;
    gap: var(--gap-xl);
}

.section {
    width: 1440px;
    align-items: center;
    color: var(--gray-600);
}

.supportingText2,
.text29 {
    position: relative;
    line-height: 24px;
}

.text29 {
    font-weight: 500;
}

.supportingText2 {
    color: var(--gray-600);
    display: none;
}

.input2 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.inputDropdown1 {
    width: 200px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
}

.input5,
.inputDropdown1,
.inputDropdown3 {
    display: flex;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.inputDropdown3 {
    width: 180px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
}

.input5 {
    align-self: stretch;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.button21,
.dropdowns,
.inputDropdown4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.inputDropdown4 {
    width: 168px;
    flex-shrink: 0;
    flex-direction: column;
    gap: var(--gap-5xs);
}

.button21,
.dropdowns {
    flex-direction: row;
    gap: var(--gap-xs);
}

.button21 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.input6,
.inputDropdown5 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.input6 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-sm);
    align-items: center;
    font-size: var(--text-md-regular-size);
    color: var(--gray-500);
}

.inputDropdown5 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-5xs);
    font-size: var(--text-sm-medium-size);
}

.button22,
.button23 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button23 {
    background-color: var(--color-firebrick-100);
    border: 1px solid var(--color-firebrick-100);
    color: var(--base-white);
}

.content10,
.filtersBar {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.content10 {
    flex-direction: row;
    gap: var(--gap-xs);
    font-size: var(--text-md-regular-size);
}

.filtersBar {
    border-radius: var(--br-5xs);
    flex-direction: column;
    gap: var(--gap-base);
}

.mapIcon {
    position: absolute;
    height: 184%;
    width: 127.78%;
    top: 0;
    right: -27.78%;
    bottom: -84%;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.googleLogoIcon,
.rectangle {
    position: absolute;
    bottom: 2px;
    left: 6px;
    width: 66px;
    height: 26px;
}

.rectangle {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-11xs);
    background-color: var(--base-white);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.pegmanOffscreen2xIcon {
    position: absolute;
    height: 71.43%;
    width: 42.86%;
    top: 14.29%;
    right: 28.57%;
    bottom: 14.29%;
    left: 28.57%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.streetViewButton {
    position: absolute;
    top: 69px;
    right: 8px;
    width: 28px;
    height: 28px;
}

.zoomControlButtons {
    position: absolute;
    top: 6px;
    right: 5px;
    width: 34px;
    height: 59px;
}

.satellite {
    top: 27.59%;
    left: 48.94%;
}

.line,
.map1,
.satellite {
    position: absolute;
}

.line {
    height: 103.45%;
    width: 1.06%;
    top: -1.72%;
    right: 59.04%;
    bottom: -1.72%;
    left: 39.89%;
    border-right: 1px solid var(--color-whitesmoke-300);
    box-sizing: border-box;
}

.map1 {
    top: 27.59%;
    left: 8.51%;
    font-weight: 500;
    color: var(--base-black);
}

.mapTypeButtonGroup {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 94px;
    height: 29px;
}

.pinMarkerIcon {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 12px);
    width: 24px;
    height: 32px;
    overflow: hidden;
    display: none;
}

.googleMapsMockup {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    overflow: hidden;
}

.outerCircle {
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 40px;
    height: 40px;
    opacity: 0.1;
}

.innerCircle,
.middleCircle,
.outerCircle {
    position: absolute;
    border-radius: var(--br-9xl);
    background-color: var(--color-firebrick-100);
}

.middleCircle {
    top: calc(50% - 12px);
    left: calc(50% - 12px);
    width: 24px;
    height: 24px;
    opacity: 0.2;
}

.innerCircle {
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
}

.mapLocationMarker,
.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    position: absolute;
    top: calc(50% - 71px);
    left: calc(50% - 46px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker1,
.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% - 87px);
    left: calc(50% - 116px);
}

.mapLocationMarker2,
.mapLocationMarker3 {
    top: calc(50% + 7px);
    left: calc(50% - 38px);
}

.mapLocationMarker3 {
    top: calc(50% - 97px);
    left: calc(50% + 38px);
}

.mapLocationMarker4,
.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    position: absolute;
    top: calc(50% - 133px);
    left: calc(50% + 14px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker5,
.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 23px);
    left: calc(50% - 112px);
}

.mapLocationMarker6,
.mapLocationMarker7 {
    top: calc(50% + 51px);
    left: calc(50% - 2px);
}

.mapLocationMarker7 {
    top: calc(50% - 178px);
    left: calc(50% - 42px);
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker8,
.mapLocationMarker9 {
    position: absolute;
    top: calc(50% - 145px);
    left: calc(50% + 62px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.mapLocationMarker10,
.mapLocationMarker11,
.mapLocationMarker9 {
    top: calc(50% - 139px);
    left: calc(50% + 156px);
}

.mapLocationMarker10,
.mapLocationMarker11 {
    top: calc(50% - 39px);
    left: calc(50% - 26px);
}

.mapLocationMarker11 {
    top: calc(50% + 71px);
    left: calc(50% - 34px);
}

.map {
    align-self: stretch;
    position: relative;
    border-radius: var(--br-5xs);
    height: 360px;
    flex-shrink: 0;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-2xs);
    color: var(--color-dimgray);
    font-family: var(--font-roboto);
}

.container3,
.section1 {
    width: 1440px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.container3 {
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    align-items: flex-start;
    gap: var(--gap-24);
}

.section1 {
    align-items: center;
}

.buttonGroupBase,
.buttonGroupBase1,
.buttonGroupBase2,
.buttonGroupBase3,
.buttonGroupBase4,
.buttonGroupBase5 {
    border-right: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase {
    background-color: var(--gray-50);
    display: flex;
    z-index: 8;
    color: var(--gray-800);
}

.buttonGroupBase1,
.buttonGroupBase2,
.buttonGroupBase3,
.buttonGroupBase4,
.buttonGroupBase5 {
    background-color: var(--base-white);
    display: none;
    z-index: 7;
}

.buttonGroupBase2,
.buttonGroupBase3,
.buttonGroupBase4,
.buttonGroupBase5 {
    z-index: 6;
}

.buttonGroupBase3,
.buttonGroupBase4,
.buttonGroupBase5 {
    z-index: 5;
}

.buttonGroupBase4,
.buttonGroupBase5 {
    z-index: 4;
}

.buttonGroupBase5 {
    z-index: 3;
}

.buttonGroupBase6 {
    display: none;
    z-index: 2;
}

.buttonGroupBase6,
.buttonGroupBase7,
.buttonGroupBase8 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-base);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase7 {
    display: none;
    z-index: 1;
}

.buttonGroupBase8 {
    display: flex;
    z-index: 0;
}

.buttonGroup {
    border-radius: var(--br-5xs);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttonGroupBase10,
.buttonGroupBase11,
.buttonGroupBase12,
.buttonGroupBase13,
.buttonGroupBase14,
.buttonGroupBase9 {
    border-right: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-xs);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase9 {
    background-color: var(--gray-50);
    display: flex;
    z-index: 8;
}

.buttonGroupBase10,
.buttonGroupBase11,
.buttonGroupBase12,
.buttonGroupBase13,
.buttonGroupBase14 {
    background-color: var(--base-white);
    display: none;
    z-index: 7;
}

.buttonGroupBase11,
.buttonGroupBase12,
.buttonGroupBase13,
.buttonGroupBase14 {
    z-index: 6;
}

.buttonGroupBase12,
.buttonGroupBase13,
.buttonGroupBase14 {
    z-index: 5;
}

.buttonGroupBase13,
.buttonGroupBase14 {
    z-index: 4;
}

.buttonGroupBase14 {
    z-index: 3;
}

.buttonGroupBase15 {
    display: none;
    z-index: 2;
}

.buttonGroupBase15,
.buttonGroupBase16,
.buttonGroupBase17 {
    background-color: var(--base-white);
    border-right: 1px solid var(--gray-300);
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-xs);
    align-items: center;
    justify-content: center;
}

.buttonGroupBase16 {
    display: none;
    z-index: 1;
}

.buttonGroupBase17 {
    display: flex;
    z-index: 0;
}

.text46 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
}

.textAndCategory {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.button24,
.textAndButton {
    display: flex;
    flex-direction: row;
}

.button24 {
    border-radius: var(--br-5xs);
    background-color: var(--error-50);
    border: 2px solid var(--error-50);
    overflow: hidden;
    padding: var(--padding-xs);
    align-items: center;
    justify-content: center;
}

.textAndButton {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
    font-size: var(--text-md-regular-size);
}

.whatsInRoryMcllorysBagIcon {
    position: absolute;
    top: 0;
    left: 0;
    width: 180px;
    height: 203px;
    object-fit: cover;
}

.avatar {
    border-radius: var(--br-181xl);
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
    position: relative;
}

.avatarIcon {
    width: 64px;
    height: 64px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #dfdfdf;
}

.avatarInner,
.layer2Copy,
.rectangleParent {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
}

.layer2Copy {
    top: 0%;
    bottom: 27.01%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
}

.avatarInner,
.rectangleParent {
    height: 100%;
    top: 0;
    bottom: 0;
}

.avatarInner {
    height: 26.4%;
    width: 45.31%;
    top: 73.18%;
    right: -6.25%;
    bottom: 0.42%;
    left: 60.94%;
}

.textAndSubtext {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.supportingText4 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: var(--gray-600);
}

.content14 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
}

.dotIcon {
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    overflow: hidden;
    display: none;
    z-index: 2;
}

.award05Icon,
.wrapAvatar {
    position: relative;
    flex-shrink: 0;
}

.wrapAvatar {
    width: 318px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-xs);
    cursor: pointer;
}

.wrapAvatar:hover .userName {
    text-decoration: underline;
}

.award05Icon {
    width: 12px;
    height: 12px;
    overflow: hidden;
}

.badge1 {
    border-radius: var(--br-base);
    padding: var(--padding-11xs) var(--padding-3xs) var(--padding-11xs) var(--padding-5xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
    mix-blend-mode: normal;
    text-align: center;
    font-size: var(--text-sm-medium-size);
    color: var(--base-white);
    font-family: var(--text-xs-medium);
}

.badge1,
.wrapAvatarParent,
.stars {
    display: flex;
    flex-direction: row;
}

.wrapAvatarParent {
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-700);
    font-family: var(--font-general-sans);
}

.stars {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.supportingText5 {
    position: relative;
    line-height: 24px;
    color: var(--gray-600);
}

.rating1 {
    width: 249px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.text50 {
    position: relative;
    line-height: 24px;
    color: var(--gray-900);
}

.textAndSupportingText3 {
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xs);
    color: var(--gray-600);
}

.detailsWrap,
.rating,
.textAndSupportingText3 {
    display: flex;
    flex-direction: row;
}

.rating {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
    align-items: flex-start;
}

.rating .supportingText span {
    font-weight: 700;
}

.supportingText {
    gap: 3px;
}


.detailsWrap {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.text53 {
    position: relative;
    line-height: 30px;
    font-weight: 600;
}

.textAndSupportingText4,
.textWrap {
    display: flex;
    justify-content: flex-start;
}

.textWrap {
    flex-direction: column;
    padding: 0 0 var(--padding-11xs);
    align-items: flex-start;
    font-size: var(--text-md-regular-size);
    color: var(--gray-600);
}

.textAndSupportingText4 {
    flex-direction: row;
    align-items: flex-end;
    gap: var(--gap-5xs);
    font-size: var(--text-xl-semibold-size);
    color: var(--gray-900);
}

.details {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--gray-600);
}

.spotsTargetChart,
.raisedIndicator {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-whitesmoke-200);
    height: 4px;
}

.raisedIndicator {
    background-color: var(--color-firebrick-100);
    width: 255px;
}

.chart {
    position: relative;
    height: 4px;
    width: 540px;
    flex: 1;
}

.spotsTarget,
.spotsFilled {
    position: relative;
    line-height: 140%;
}

.spotsFilled {
    font-weight: 600;
}

.spotsTarget {
    font-family: var(--text-xs-medium);
    color: var(--gray-600);
}

.detila,
.spots {
    display: flex;
    flex-direction: row;
}

.spots {
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.detila {
    width: 117px;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.containre,
.listingSearchResult {
    align-self: stretch;
    display: flex;
    flex-direction: row;
}

.containre {
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.listingSearchResult {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 3px solid var(--warning-400);
    box-sizing: border-box;
    flex-shrink: 0;
    padding: var(--padding-5xl);
    align-items: flex-start;
    justify-content: flex-start;
}

.button25 {
    border-radius: var(--br-5xs);
    border: 2px solid var(--gray-200);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs);
    align-items: center;
    justify-content: center;
}

.listingRow,
.listingSearchResult1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.listingSearchResult1 {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    width: 605px;
    flex-shrink: 0;
    padding: var(--padding-5xl);
}

.listingRow {
    gap: var(--gap-24);
}

.whatsInRoryMcllorysBagIcon2 {
    position: absolute;
    top: -30px;
    left: -66px;
    width: 180px;
    height: 203px;
    object-fit: cover;
}

.results {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-32xl);
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.tag {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.content33 {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--br-xl);
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: var(--padding-xs);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
}

.paginationNumberBase {
    background-color: var(--gray-50);
    color: var(--gray-800);
}

.paginationNumberBase,
.paginationNumberBase1 {
    position: relative;
    border-radius: var(--br-xl);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    overflow: hidden;
}

.paginationNumbers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-11xs);
    text-align: center;
}

.container4,
.main,
.pagination {
    display: flex;
    align-items: center;
}

.pagination {
    align-self: stretch;
    border-top: 1px solid var(--gray-200);
    flex-direction: row;
    padding: var(--padding-xl) 0 0;
    justify-content: space-between;
    color: var(--gray-600);
}

.container4,
.main {
    width: 1440px;
    flex-direction: column;
    padding: 0 var(--padding-32xl);
    box-sizing: border-box;
    justify-content: flex-start;
    gap: var(--gap-24);
}

.main {
    position: absolute;
    top: 72px;
    left: 0;
    background-color: var(--base-white);
    width: 1440px;
    padding: var(--padding-29xl) 0 var(--padding-77xl);
    gap: var(--gap-32xl);
    font-size: var(--text-sm-medium-size);
}

.barChart01Icon,
.contentChild,
.dotIcon10 {
    position: relative;
    width: 154px;
    height: 41.91px;
    flex-shrink: 0;
}

.barChart01Icon,
.dotIcon10 {
    width: 10px;
    height: 10px;
    overflow: hidden;
    display: none;
}

.barChart01Icon {
    width: 24px;
    height: 24px;
}

.badge11,
.content41 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.content41 {
    display: flex;
    gap: var(--gap-xs);
}

.badge11 {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
}

.navItemBase {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
}

.badge12,
.navItemBase,
.navItemBase1,
.navItemBase5 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.badge12 {
    border-radius: var(--br-base);
    background-color: var(--gray-100);
    display: none;
    padding: var(--padding-11xs) var(--padding-5xs);
    mix-blend-mode: multiply;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--gray-700);
}

.navItemBase1,
.navItemBase5 {
    border-radius: var(--br-7xs);
    background-color: var(--gray-200);
    overflow: hidden;
    display: flex;
    padding: var(--padding-5xs) var(--padding-xs);
    gap: var(--gap-5xs);
    color: var(--gray-900);
}

.navItemBase5 {
    background-color: var(--base-white);
    color: var(--error-800);
}

.navigation {
    align-items: center;
    gap: var(--gap-9xs);
}

.content40,
.navItemButton,
.navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.content40 {
    align-items: center;
    gap: var(--gap-40);
}

.navItemButton {
    border-radius: var(--br-7xs);
    background-color: var(--base-white);
    overflow: hidden;
    padding: var(--padding-3xs);
    align-items: flex-start;
}

.content47,
.dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content47 {
    flex-direction: row;
    align-items: center;
    gap: var(--gap-base);
}

.container5,
.headerNavigation {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.container5 {
    width: 1440px;
    height: 72px;
    flex-shrink: 0;
    flex-direction: row;
    padding: 0 var(--padding-32xl);
    justify-content: space-between;
}

.headerNavigation {
    background-color: var(--base-white);
    border-bottom: 1px solid var(--gray-200);
    width: 1440px;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
}

.golfBallIcon,
.headerNavigation,
.overlay {
    position: absolute;
    top: 0;
    left: 0;
}

.overlay {
    background-color: rgba(10, 17, 29, 0.75);
    width: 1440px;
    height: 959px;
    overflow: hidden;
}

.golfBallIcon {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.golfBallWrapper {
    position: relative;
    border-radius: var(--br-29xl);
    background-color: var(--base-white);
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    overflow: hidden;
}

.text140 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    font-size: 16px;
}

.item10 {
    border-radius: var(--br-69xl);
    background-color: var(--color-whitesmoke-100);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-5xs) var(--padding-base) var(--padding-5xs) var(--padding-5xs);
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.heading3 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-xl-semibold-size);
    line-height: 32px;
    font-weight: 600;
    text-align: left;
}

.item10Parent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-24);
}

.badge18 {
    border-radius: var(--br-base);
    border: 1.5px solid var(--color-darkslategray-100);
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs) var(--padding-xs) var(--padding-9xs) var(--padding-3xs);
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-9xs);
}

.badge17 {
    flex-direction: column;
    justify-content: center;
}

.badge17,
.badge23,
.tagListItems {
    display: flex;
    align-items: flex-start;
}

.badge23 {
    flex-direction: column;
    justify-content: center;
    color: var(--gray-600);
}

.tagListItems {
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-5xs);
}

.container6 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 var(--padding-9xl) 0 0;
}

.tagList,
.container6,
.rightContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.tagList {
    flex: 1;
    border-radius: var(--br-5xs);
    overflow: hidden;
    flex-direction: column;
    gap: 14px;
}

.rightContent {
    border-radius: 13px;
    width: 738px;
    flex-direction: row;
    text-align: center;
    font-size: var(--text-sm-medium-size);
}

.heading4 {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 500;
}

.desc {
    margin-block-start: 0;
    margin-block-end: 16px;
}

.paragraph {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    color: var(--gray-600);
    max-height: 300px;
    overflow: hidden;
}

.paragraph.expanded {
    max-height: unset;
}

.collapseButton {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    margin-top: 10px;
}

.section3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-xs);
    color: var(--gray-900);
}

.label7 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.button36 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-9xs);
    font-size: var(--text-md-regular-size);
    color: var(--gray-700);
}

.content50,
.labelAndText,
.section4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.labelAndText {
    flex-direction: column;
    gap: var(--gap-5xs);
}

.content50,
.section4 {
    align-self: stretch;
}

.content50 {
    flex-direction: row;
    gap: var(--gap-29xl);
}

.section4 {
    flex-direction: column;
    font-size: var(--text-sm-medium-size);
    color: var(--error-700);
}

.spotsTargetChart,
.slotsIndicator {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--color-whitesmoke-200);
    height: 4px;
    width: 100%;
}


.slotsIndicator {
    background-color: var(--color-firebrick-100);
}

.spots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.containre10,
.section5 {
    align-self: stretch;
    display: flex;
}

.containre10 {
    align-self: stretch;
    align-items: center;
    justify-content: center;
    gap: var(--gap-mini);
    flex-direction: row;
}

.section5 {
    flex-direction: column;
    padding: var(--padding-5xs) 0;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--base-black);
    font-family: var(--font-general-sans);
}

.shareButton,
.bookButton,
.bookButtonBooked {
    border-radius: var(--br-5xs);
    background-color: var(--secondary-color);
    box-shadow: var(--shadow-xs);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-3xs) var(--padding-lg);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 200ms;
}

.bookButtonBooked {
    background-color: #b6b6b6;
    cursor: unset;
}

.shareButton {
    background-color: var(--base-white);
    border: 1px solid var(--gray-300);
    gap: var(--gap-5xs);
}

.bookButton:hover {
    opacity: 0.8;
    box-shadow: var(--shadow-xs);
}

.shareButton:hover {
    border: 1px solid var(--gray-600);
}

.socialIcon {
    position: relative;
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    overflow: hidden;
}

.cancel,
.socialButton {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-xs);
}

.socialButton {
    flex: 1;
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    overflow: hidden;
    display: none;
    padding: var(--padding-3xs) var(--padding-base);
}

.cancel {
    display: flex;
    color: var(--gray-700);
}

.buttonGroup2 {
    flex: 1;
    flex-direction: row;
    gap: var(--gap-base);
}

.buttonGroup2,
.buttonGroupWrapper,
.content49,
.leftContent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttonGroupWrapper {
    flex-direction: row;
    color: var(--base-white);
}

.content49,
.leftContent {
    flex: 1;
    flex-direction: column;
}

.content49 {
    gap: 25px;
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--color-darkslategray-100);
}

.leftContent {
    gap: 11px;
}

.wrapAvatar,
.rating20 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.wrapAvatar {
    justify-content: flex-start;
    position: relative;
    gap: var(--gap-xs);
}

.rating20 {
    justify-content: space-between;
    color: var(--gray-900);
    font-family: var(--text-xs-medium);
}

.dropdown1,
.wrapAvatarParent7 {
    flex-direction: column;
    align-items: flex-start;
}

.wrapAvatarParent7 {
    align-self: stretch;
    display: flex;
    justify-content: center;
    gap: var(--gap-xs);
    z-index: 0;
}

.dropdown1 {
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
    display: none;
    justify-content: flex-start;
    z-index: 1;
}

.metricItem,
.metricItemWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.metricItem {
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    padding: var(--padding-xl) var(--padding-base);
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: var(--gap-5xs);
}

.metricItemWrapper {
    align-items: flex-start;
    justify-content: flex-start;
}

.pinMarkerIcon1 {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 11.5px);
    width: 24px;
    height: 32px;
    overflow: hidden;
    display: none;
}

.headingAndIconChild {
    position: relative;
    border-radius: 64px;
    width: 48px;
    height: 48px;
    flex-shrink: 0;
}

.someStreetName {
    margin: 0;
}

.supportingText46 {
    align-self: stretch;
    position: relative;
    font-size: var(--text-sm-medium-size);
    line-height: 20px;
    color: var(--gray-600);
}

.content52,
.headingAndIcon,
.textAndSupportingText43 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.textAndSupportingText43 {
    flex: 1;
    flex-direction: column;
    gap: var(--gap-10xs);
}

.content52,
.headingAndIcon {
    align-self: stretch;
}

.headingAndIcon {
    flex-direction: row;
    gap: var(--gap-xs);
}

.content52 {
    flex-direction: column;
    padding: var(--padding-xs);
}

.button41,
.button43 {
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--gap-5xs);
}

.button43 {
    border-radius: var(--br-5xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-xs);
    border: 1px solid var(--gray-300);
    padding: var(--padding-3xs) var(--padding-base);
    color: var(--gray-700);
}

.actions2,
.button44,
.content53 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button44 {
    overflow: hidden;
    justify-content: center;
    gap: var(--gap-5xs);
    color: var(--error-700);
}

.actions2,
.content53 {
    justify-content: flex-end;
}

.actions2 {
    flex: 1;
    gap: var(--gap-xs);
}

.content53 {
    align-self: stretch;
    padding: 0 var(--padding-5xl);
    gap: var(--gap-base);
}

.locationCard,
.sectionFooter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.sectionFooter {
    align-self: stretch;
    padding: 0 0 var(--padding-base);
    align-items: center;
    font-size: var(--text-sm-medium-size);
    color: var(--gray-600);
}

.locationCard {
    position: absolute;
    bottom: 5px;
    left: 5px;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    box-sizing: border-box;
    min-width: 300px;
    align-items: flex-start;
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
    font-family: var(--text-xs-medium);
}

.mapLocationMarker12 {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 19.5px);
    border-radius: var(--br-9xl);
    width: 40px;
    height: 40px;
}

.heading5,
.listingMap {
    align-self: stretch;
    position: relative;
}

.listingMap {
    border-radius: var(--br-5xs);
    height: 400px;
    width: 100%;
    flex-shrink: 0;
    overflow: hidden;
    text-align: center;
    font-size: var(--font-size-2xs);
    color: var(--color-dimgray);
    font-family: var(--font-roboto);
}

.wrapMap {
    position: relative;
    width: 100%;
}

.heading5 {
    line-height: 20px;
    font-weight: 500;
    z-index: 0;
}

.number7 {
    flex: 1;
    position: relative;
    line-height: 38px;
    font-weight: 600;
}

.avatarIcon1,
.avatarIcon2 {
    border-radius: var(--br-181xl);
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    object-fit: cover;
}

.avatarIcon2 {
    margin-left: -4px;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    /* Add transition for smooth effect */
}

.avatarIcon2:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    /* Add the desired box shadow style */
}

.text166 {
    position: absolute;
    top: calc(50% - 11px);
    left: calc(50% - 14px);
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    width: 24px;
}

.avatar11 {
    position: relative;
    border-radius: var(--br-181xl);
    background-color: var(--gray-100);
    border: 2px solid var(--base-white);
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
    display: none;
    margin-left: -4px;
}

.avatars {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 20px;
}

.avatarAddButton,
.content54 {
    border-radius: var(--br-xs);
    box-sizing: border-box;
}

.content54 {
    position: absolute;
    height: 96%;
    width: 96%;
    top: 0;
    right: 4%;
    bottom: 4%;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: var(--padding-9xs);
    align-items: center;
    justify-content: center;
}

.avatarAddButton {
    position: relative;
    background-color: var(--base-white);
    border: 1px dashed var(--gray-300);
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    display: none;
}

.avatarGroup {
    flex-direction: row;
    align-items: flex-start;
    gap: var(--gap-5xs);
}

.avatarGroup,
.badgeWrap,
.numberAndBadge {
    display: flex;
    justify-content: flex-start;
}

.badgeWrap {
    flex-direction: column;
    padding: 0 0 var(--padding-9xs);
    align-items: flex-start;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--gray-600);
}

.numberAndBadge {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-base);
    z-index: 1;
    font-size: var(--text-md-regular-size);
    color: var(--gray-900);
}

.dropdown2,
.metricItem1 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.dropdown2 {
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
    display: none;
    z-index: 2;
}

.metricItem1 {
    align-self: stretch;
    flex: 1;
    border-radius: var(--br-xs);
    background-color: var(--base-white);
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--gray-200);
    display: flex;
    padding: var(--padding-xl) var(--padding-base);
    position: relative;
    gap: var(--gap-5xs);
}

.metricItemContainer {
    height: 106px;
    flex-shrink: 0;
    flex-direction: column;
    font-size: var(--text-sm-medium-size);
    color: var(--gray-600);
    font-family: var(--text-xs-medium);
}

.wrapLeftRight,
.metricItemContainer,
.rightContent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.rightContent {
    max-width: 450px;
    flex-shrink: 0;
    flex-direction: column;
    gap: var(--gap-24);
    text-align: left;
    font-size: var(--text-md-regular-size);
    color: var(--gray-700);
    font-family: var(--font-general-sans);
}

.wrapLeftRight {
    flex: 1;
    flex-direction: row;
    gap: 40px;
}

.contentWrapper {
    flex-shrink: 0;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
}

.container,
.contentWrapper,
.modalFrame,
.modalClose {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container {
    width: 100%;
    flex-direction: column;
    transform-origin: 0 0;
}

.modalFrame {
    background-color: var(--base-white);
    flex-direction: column;
    padding: var(--padding-81xl) var(--padding-45xl) var(--padding-81xl) 76px;
    box-sizing: border-box;
    transform-origin: 0 0;
    text-align: center;
    font-size: var(--text-xs-medium-size);
    color: var(--gray-900);
}

.modalClose {
    top: 20px;
    right: 10px;
    border-radius: var(--br-7xs);
    flex-direction: row;
    padding: var(--padding-xs);
    position: absolute;
    z-index: 1000;
    cursor: pointer;
}

.listingModalWrapper {
    position: fixed;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.listingModalBackdrop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.listingModal {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    background-color: white;
    z-index: 2;
    transition: all 0.5s ease-in-out;
    border-radius: 40px 40px 0px 0px;
    height: 90%;
}

.listingModalWrapper.open {
    bottom: 0;
}

.listingModal.open {
    bottom: 0;
}

.listingModalContent {
    background-color: white;
    position: relative;
}

.userName {
    line-height: 20px;
    font-weight: 500;
    font-size: 20px;
    color: var(--gray-700);
}

.heartIcon {
    width: 15px;
    height: 15px;
}

.modalActions {
    display: none;
}

@media screen and (max-width: 600px) {
    .modalFrame {
        padding: 0px;
        overflow-y: scroll;
        max-height: 100%;
    }

    .container {
        padding: 30px 20px;
        padding-bottom: 100px;
        overflow-y: auto;
    }

    .item10Parent {
        gap: var(--gap-xs);
    }

    .content49 {
        gap: var(--gap-base);
    }

    .leftContent {
        gap: 0px;
        width: 100%;
    }

    .rightContent {
        max-width: 100%;
    }

    .section3 {
        padding-top: 5px;
    }

    .heading4 {
        font-size: 18px;
    }

    .paragraph {
        max-height: 160px;
    }

    .desc {
        font-size: 12px;
        line-height: 16px;
    }

    .text29 {
        white-space: nowrap;
    }

    .content50 {
        gap: var(--gap-24);
        flex-wrap: wrap;
    }

    .chart {
        width: unset;
    }

    .buttonGroup2 {
        align-items: center;
        justify-content: center;
    }

    .wrapLeftRight {
        flex-direction: column;
    }

    .modalActions {
        display: flex;
        position: absolute;
        bottom: 0;
        background-color: white;
        align-items: center;
        border-top: 1px solid #e1e1e1;
        padding: 10px 10px;
        width: 100%;
        justify-content: center;
        gap: 10px;
        font-size: 17px;
        z-index: 10000;
    }

    .buttonGroupWrapper {
        display: none;
    }
}
.logoIcon {
  position: relative;
  width: 320px;
  height: 87.08px;
  flex-shrink: 0;
}

.supportingText {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.logoAndSupportingText {
  width: 320px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-32);
}

.heading {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  overflow: hidden;
  display: none;
}

.text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.button,
.footerLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.button {
  justify-content: center;
  gap: var(--gap-5xs);
}

.footerLink {
  justify-content: flex-start;
}

.text3 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.badge {
  border-radius: var(--br-base);
  background-color: var(--color-gray-200);
  border: 1px solid var(--color-gray-100);
  display: flex;
  padding: var(--padding-11xs) var(--padding-5xs);
  mix-blend-mode: normal;
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--base-white);
}

.badge,
.footerLink2,
.footerLink6 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.footerLink2 {
  display: flex;
  gap: var(--gap-5xs);
}

.footerLink6 {
  display: none;
}

.footerLinks,
.footerLinksColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.footerLinks {
  align-self: stretch;
  gap: var(--gap-xs);
  font-size: var(--text-md-regular-size);
  color: var(--gray-200);
}

.footerLinksColumn {
  flex: 1;
  gap: var(--gap-base);
}

.links {
  flex: 1;
  flex-direction: row;
  gap: var(--gap-32);
  font-size: var(--text-sm-semibold-size);
  color: var(--gray-300);
}

.container,
.content,
.links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content {
  flex-direction: row;
  gap: var(--gap-45xl);
}

.container {
  width: 1280px;
  flex-direction: column;
  padding: 0 var(--padding-32xl);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--text-md-regular-size);
  color: var(--gray-200);
  font-family: var(--text-md-regular);
  align-items: center;
}

@media screen and (max-width: 600px) {

  .container {
    width: 100%;
  }

  .content {
    flex-direction: column;
    gap: 24px;
  }

  .heading {
    font-size: 18px;
  }
}
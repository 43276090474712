.chatPage {
    padding: var(--header-height) 50px 50px 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    max-width: 1400px;
}

.chatSection {
    padding: 20px;
    height: 100%;
    width: 100%;
}


@media screen and (max-width: 600px) {
    .chatPage {
        padding: 0;
        padding-top: var(--header-height);
    }

    .chatSection {
        width: 100%;
        padding: 0;
        padding-top: 10px;
    }
}
/* .heroHeaderSection {
    width: 100%;
    max-height: 800px;
    min-height: 600px;
    flex-shrink: 0;
    overflow: hidden;
    align-items: center;
    background-image: url(/public/hero-header-section@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
} */

.heroHeaderSection {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative; */

    width: 100%;
    max-height: 800px;
    min-height: 600px;
    flex-shrink: 0;
    align-items: center;
    background-image: url(/public/hero-header-section@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
}

.footer,
.heroHeaderSection,
.indexNoCookie,
.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section {
    align-self: stretch;
    padding: 64px 0 var(--padding-29xl);
    align-items: center;
    gap: var(--gap-45xl);
}

.footer,
.indexNoCookie {
    align-items: flex-start;
}

.footer {
    background-color: var(--primary-color);
    width: 100%;
}

.indexNoCookie {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

@media screen and (max-width: 600px) {
    .heroHeaderSection {
        width: 100%;
        height: 100vh;
        max-height: unset;
        min-height: unset;
        align-items: center;
        background-image: url(/public/hero-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
    }
}
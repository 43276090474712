.groupChild {
  position: absolute;
  height: 99.9%;
  width: 58.2%;
  top: 0.05%;
  right: 20.23%;
  bottom: 0.05%;
  left: 21.57%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2,
.vectorIcon {
  position: absolute;
  height: 80.18%;
  width: 46.65%;
  top: 9.81%;
  right: 26%;
  bottom: 10.01%;
  left: 27.34%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.layer2Copy,
.rectangleIcon,
.rectangleIcon1,
.rectangleIcon2 {
  height: 100%;
  width: 58.2%;
  top: 0;
  right: 20.23%;
  bottom: 0;
  left: 21.57%;
  object-fit: cover;
}

.layer2Copy,
.rectangleIcon1,
.rectangleIcon2 {
  height: 8.72%;
  width: 14.55%;
  top: 45.49%;
  right: 57.5%;
  bottom: 45.79%;
  left: 27.95%;
}

.layer2Copy,
.rectangleIcon2 {
  height: 30.43%;
  width: 96.28%;
  top: 39.35%;
  right: 1.85%;
  bottom: 30.23%;
  left: 1.87%;
}

.layer2Copy {
  height: 36.82%;
  width: 100%;
  top: 36.17%;
  right: 0;
  bottom: 27.01%;
  left: 0;
}

.approvedBadge,
.rectangleParent {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.approvedBadge {
  height: 30.17%;
  width: 51.79%;
  top: 69.35%;
  right: -16.07%;
  bottom: 0.48%;
  left: 64.29%;
}

.avatar {
  position: relative;
  border-radius: var(--br-181xl);
  width: 56px;
  height: 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.avatar img {
  width: 100%;
  height: 100%;
  border-radius: var(--br-181xl);
  object-fit: cover;
}


.groupAvatar {
  position: relative;
  border-radius: var(--br-181xl);
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-left: -10px;
}

.groupAvatar img {
  width: 100%;
  height: 100%;
  border-radius: var(--br-181xl);
  object-fit: cover;
}


.text {
  line-height: 28px;
  font-weight: 600;
}

.dotIcon,
.text,
.text1 {
  position: relative;
}

.dotIcon {
  width: 8px;
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
}

.text1 {
  line-height: 18px;
  font-weight: 500;
}

.badge,
.textAndBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.badge {
  border-radius: var(--br-base);
  border: 1.5px solid var(--success-600);
  padding: var(--padding-11xs) var(--padding-5xs) var(--padding-11xs) var(--padding-7xs);
  gap: var(--gap-9xs);
  text-align: center;
  font-size: var(--text-xs-medium-size);
  color: var(--success-700);
}

.textAndBadge {
  align-self: stretch;
  gap: var(--gap-5xs);
}

.supportingText {
  align-self: stretch;
  position: relative;
  font-size: var(--text-sm-regular-size);
  line-height: 20px;
  color: var(--gray-600);
  cursor: pointer;
}

.supportingText:hover {
  text-decoration: underline;
}

.avatarAndText,
.textAndSupportingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.avatarAndText {
  flex-direction: row;
  gap: var(--gap-base);
  font-size: var(--text-lg-semibold-size);
  cursor: pointer;
}


.avatarAndText:hover .supportingText {
  text-decoration: underline;
}

.placeholderIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.text2 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.button {
  border-radius: var(--br-5xs);
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-base);
  align-items: center;
  justify-content: center;
  gap: var(--gap-5xs);
}

.phoneCall01Icon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
}

.button1 {
  border-radius: var(--br-5xs);
  background-color: var(--primary-50);
  border: 1px solid var(--primary-50);
  overflow: hidden;
  display: none;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--gray-800);
}

.actions,
.button1,
.button2,
.button3 {
  flex-direction: row;
  align-items: center;
}

.button2 {
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--gray-300);
  overflow: hidden;
  display: none;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--gray-700);
}

.actions,
.button3 {
  display: flex;
}

.button3 {
  border-radius: var(--br-5xs);
  background-color: var(--error-700);
  box-shadow: var(--shadow-xs);
  border: 1px solid var(--error-700);
  overflow: hidden;
  padding: var(--padding-3xs) var(--padding-base);
  justify-content: center;
  gap: var(--gap-5xs);
  color: var(--base-white);
}

.actions {
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-600);
}

.dotsVerticalIcon {
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 0;
}

.avatarOnlineIndicator {
  position: absolute;
  right: -1.5px;
  bottom: -1.5px;
  border-radius: var(--br-8xs);
  background-color: var(--success-500);
  border: 1.5px solid var(--base-white);
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  overflow: hidden;
}

.avatar1 {
  position: relative;
  border-radius: var(--br-181xl);
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.supportingText1,
.text6 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.text6 {
  font-weight: 600;
}

.supportingText1 {
  color: var(--gray-600);
}

.avatarLabelGroup,
.dropdownListHeader {
  display: flex;
  justify-content: flex-start;
}

.avatarLabelGroup {
  width: 193px;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-xs);
}

.dropdownListHeader {
  border-bottom: 1px solid var(--gray-200);
  box-sizing: border-box;
  width: 240px;
  flex-direction: column;
  padding: var(--padding-xs) var(--padding-base);
  align-items: flex-start;
}

.user01Icon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.text7 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.iconAndText {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}

.shortcut {
  position: relative;
  font-size: var(--text-xs-medium-size);
  line-height: 18px;
  color: var(--gray-500);
}

.content1,
.dropdownListItem,
.dropdownListItem3 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.content1 {
  flex: 1;
  border-radius: var(--br-7xs);
  display: flex;
  padding: var(--padding-4xs) var(--padding-3xs);
  gap: var(--gap-xs);
}

.dropdownListItem,
.dropdownListItem3 {
  align-self: stretch;
  padding: var(--padding-11xs) var(--padding-7xs);
}

.dropdownListItem {
  display: flex;
}

.dropdownListItem3 {
  display: none;
}

.listItemGroup,
.menuItems {
  display: flex;
  justify-content: flex-start;
}

.listItemGroup {
  align-self: stretch;
  border-bottom: 1px solid var(--gray-200);
  flex-direction: column;
  padding: var(--padding-9xs) 0;
  align-items: flex-start;
}

.menuItems {
  background-color: var(--base-white);
  width: 240px;
  overflow: hidden;
}

.dropdown,
.menu,
.menuItems {
  flex-direction: column;
  align-items: flex-start;
}

.menu {
  margin: 0 !important;
  position: absolute;
  right: 0;
  bottom: -566px;
  border-radius: var(--br-5xs);
  background-color: var(--base-white);
  box-shadow: var(--shadow-lg);
  border: 1px solid var(--gray-200);
  overflow: hidden;
  display: none;
  justify-content: flex-start;
  z-index: 1;
}

.dropdown {
  display: flex;
  justify-content: center;
  position: relative;
  color: var(--gray-700);
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: var(--padding-xl) var(--padding-5xl) 0;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
}

.dividerIcon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 1px;
  flex-shrink: 0;
}

.cardHeader {
  align-self: stretch;
  background-color: var(--base-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  text-align: left;
  font-size: var(--text-sm-regular-size);
  color: var(--gray-900);
  font-family: var(--text-md-semibold);
  border-bottom: 1px solid #dfdfdf;
}

.groupTitle {
  font-size: 24px;
}

.groupTarget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.wrapGroupAvatars {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .cardHeader {

    gap: 10px;
  }

  .wrapGroupAvatars {
    display: none;
  }
}